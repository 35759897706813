
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class easeoftrade extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div>
                            <div id="mainnav">
                                <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                    <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                        <div id="mainscrollcontent">
                                            <div className="contentbox">
                                                <p>Warrants are traded on the SGX allowing investors to buy and sell warrants just like shares. Due to the existence of designated market makers, warrants are typically very liquid meaning there should be sufficient volume
                                                    on the bid and offer for investors to easily enter and exit their trade.</p>
                                            </div>
                                            <div className="imgholder">
                                                <img src="/img/education/easeoftrade_lightmode.png" style={{ maxWidth: 'min-content' }} alt />
                                            </div>
                                            <p className="disclaimer">
                                                <a href="/InvestorAcademy/liquidityvstradedvolume" style={{ color: 'red' }}>Read more about market-making
                                                    at Liquidity vs Traded volume.</a>
                                            </p>
                                            <br />
                                            <br />
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips</h2>
                                                    <ul>
                                                        <li>
                                                            <p>The main difference between warrants and other forms of leveraged products is that your risk is limited to the warrant premium. Meaning, with warrants, you can increase your effective exposure while limiting
                                                                your capital at risk.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="videoplayer-container" style={{
                                                height: '75vh',
                                                maxWidth: '440px',
                                                margin: ' 0 auto'
                                            }}>
                                                <iframe
                                                    title="Market View"
                                                    frameBorder="0"
                                                    allowFullScreen=""
                                                    height="100%"
                                                    width="100%"
                                                    src="https://www.youtube.com/embed/qKvjDm4So4U?autoplay=0&index=0&rel=0&wmode=opaque"
                                                // src={link}
                                                ></iframe>
                                            </div>
                                            <p>
                                                <a href="/InvestorAcademy/WhyTradeWarrantsPlaylist" style={{ color: '#000', fontSize: '16px', fontWeight: 'bold' }}>Warrants Short Video Playlist</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}