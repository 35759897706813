
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class moneyness extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p>Moneyness refers to the relationship between the exercise price and the current share price.
                                                <br /><br />
                                                {/*The call warrant in the previous example has intrinsic value because the exercise price is below

                                                  the current share price (reverse for puts). Warrants with intrinsic value are termed <span class="hltxt">in-the-money

                                                      (or ITM)</span>. When the exercise price is above the current share price for a call, it is termed <span class="hltxt">out-of the

                                                          money (or OTM)</span> and when the exercise price and current share price are equal, it is termed <span class="hltxt">at-

                                                              the-money (or ATM)</span>.<br><br>*/}
                                                The call warrant in the <a href="/InvestorAcademy/intrinsicvalueandtimevalue" style={{ color: 'red' }}>previous example</a> has intrinsic value because the exercise price is below the current share price (reverse for puts). Warrants with
                                                intrinsic value are termed in-the-money warrants (or ITM warrants). When the exercise is above the current share price for a call, it is termed out-of the money (or OTM) and when the exercise price and current share price
                                                are equal, it is termed at-the-money (or ATM).<br /><br /> Changing stock prices mean that the moneyness of a particular warrant can often change several times throughout its life, in-the-money warrants can thus quickly become
                                                at-the-money or out-of-the-money, or vice versa.<br /><br /> There is no particular moneyness that is favoured by all investors, each has their own advantages and disadvantages and are suited to different investors and/or different
                                                trades. The main characteristics are summarised in the below table.There is no particular moneyness that is favoured by all investors, each has their own advantages and disadvantages and are suited to different investors
                                                and/or different trades. The main characteristics are summarised in the below table.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/Table-of-Moneyness_enlarge.jpg" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/Table-of-Moneyness_enlarge.jpg')}>View table</div>
                                            </div>
                                            <br />
                                            <br />
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful Tips</h2>
                                                    <ul>
                                                        <li>
                                                            <p>Warrants can be in either one of the three stages of moneyness: in-the-money (ITM), at-the-money (ATM) or out-of-the-money (OTM).</p>
                                                        </li>
                                                        <li>
                                                            <p>Each has their own advantages and disadvantages and is suited to different investors and trades.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}