import React, { Component } from 'react';
import Footer from "./footer/index.jsx";
import "./investormy.css";
import classNames from "classnames";
import MQSGUtil from "../../components/mqsgUtil";

import Gearingeffect from './gearingeffect'
import Limitedloss from './limitedloss.jsx'
import Porfitfrombullorbearmarket from './porfitfrombullorbearmarket.jsx'
import Nomargincall from './nomargincall.jsx'
import Lowercapitaloutlay from './lowercapitaloutlay.jsx'
import Releasingsharecapital from './releasingsharecapital.jsx'
import Easeoftrade from './easeoftrade.jsx'

import Callvsput from './callvsput'
import Howacallwarrantworks from './howacallwarrantworks'
import Howaputwarrantworks from './howaputwarrantworks'
import Howarewarrantsnamed from './howarewarrantsnamed'

import Shareprice from './shareprice'
import Exerciseprice from './exerciseprice'
import Expirydate from './expirydate'
import Volatility from './volatility'
import Dividends from './dividends'
import Interestrates from './interestrates'

import Intrinsicvalueandtimevalue from './intrinsicvalueandtimevalue'
import Timedecay from './timedecay'
import Moneyness from './moneyness'

import Trackingwarrantspricechangelastprice from './trackingwarrantspricechangelastprice'
import Trackingwarrantspricechangebidprice from './trackingwarrantspricechangebidprice'
import Closingtimestonote from './closingtimestonote'

import Delta from './delta'
import Exerciseratio from './exerciseratio'
import Warrantsensitivity from './warrantsensitivity'
import Breakevenprice from './breakevenprice'
import Premium from './premium'
import Gearing from './gearing'
import Effectivegearing from './effectivegearing'
import Impliedvolatility from './impliedvolatility'

import Fourviewonunderlyingshareorindex from './fourviewonunderlyingshareorindex'
import Qualityofamarketmaker from './qualityofamarketmaker'
import Riskmatrix from './riskmatrix'
import Tipsandtools from './tipsandtools'

import Warrantsatexpirylasttradingday from './warrantsatexpirylasttradingday'
import Cashsettlementamountforsinglesharewarrantsatexpiry from './cashsettlementamountforsinglesharewarrantsatexpiry'
import Singlesharecallwarrantexample from './singlesharecallwarrantexample'
import Formulatocalculatevalueofputwarrantatexpiry from './formulatocalculatevalueofputwarrantatexpiry'
import Singleshareputwarrantexample from './singleshareputwarrantexample'

import Cashsettlementamountforindexcallsandputsatexpiry from './cashsettlementamountforindexcallsandputsatexpiry'
import Indexcallwarrantexample from './indexcallwarrantexample'
import Indexputwarrantexample from './indexputwarrantexample'

import Marketrisk from './marketrisk'
import Riskgearing from './riskgearing'
import Limitedlife from './limitedlife'
import Creditrisk from './creditrisk'
import Takeovers from './takeovers'

import Liquidityvstradedvolume from './liquidityvstradedvolume'
import Howdomarketmakershedge from './howdomarketmakershedge'
function manageUrl(val) {
    window.history.replaceState(
        null,
        null,
        `/InvestorAcademy/${val}`
    );
}
export default class eduhtml extends Component {
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    componentDidMount() {
        var index = null;
        if (this.props.match.params.ticker) {
            var pageNumber = this.state.page.indexOf((this.ticker).toLowerCase());
            if (pageNumber != -1) {
                this.setState({
                    index: pageNumber + 1
                })
                return false;
            }
            for (var i = 0; i < this.state.section.length; i++) {
                var arr = this.state.section[i].findIndex((x) => {
                    return x.toLowerCase() == (this.ticker).toLowerCase();
                });
                if (arr != -1) {
                    index = i + 1;
                    break;
                }
            }
        }
        if (index) {
            this.setState({
                index
            }, () => {
                var el = $('#accordion .panel-collapse:eq(' + arr + ')')
                el.addClass("in")
            })
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            index: 1,
            page: ['whytradewarrants', 'whatarewarrants', 'howarewarrantspriced', 'understandingthewarrantstructure', 'monitoringwarrantpricechanges', 'usefulwarrantterms', 'howtoselectawarrant', 'singlesharecallsandputsatexpiry', 'indexcallsandputsatexpiry', 'risk', 'marketmakers', 'warrantsshortsplaylist'],
            section: [
                [
                    'gearingeffect',
                    'limitedloss',
                    'abilitytogolongshort',
                    'nomargincall',
                    'lowercapitaloutlay',
                    'releasingsharecapital',
                    'easeoftrade',
                    'WhyTradeWarrantsPlaylist'
                ],
                [
                    'callvsput',
                    'howacallwarrantworks',
                    'howaputwarrantworks',
                    'howarewarrantsnamed',
                ],
                [
                    'stockprice',
                    'exerciseprice',
                    'expirydate',
                    'volatility',
                    'dividends',
                    'interestrates',
                ],
                [
                    'intrinsicvalueandtimevalue',
                    'timedecay',
                    'moneyness',
                ],
                [
                    'lasttradedprices',
                    'bidprices',
                    'closingtimes',
                ],
                [
                    'delta',
                    'warrantspershare',
                    'warrantsensitivity',
                    'breakevenprice',
                    'premium',
                    'gearing',
                    'effectivegearing',
                    'impliedvolatility',
                ],
                [
                    'selectingawarrant',
                    'marketmaker',
                    'riskmatrix',
                    'tipsandtools',
                ],
                [
                    'lasttradingday',
                    'callexpiryformula',
                    'callexample',
                    'putexpiryformula',
                    'putexample',
                ],
                [
                    'indexexpiryformula',
                    'callexample',
                    'putexample',
                ],
                [
                    'marketrisk',
                    'gearing',
                    'limitedlife',
                    'creditrisk',
                    'takeovers',
                ],
                [
                    'liquidityvstradedvolume',
                    'howwehedge',
                ],
                [
                    'whatisstructuredwarrants',
                    'whytradewarrants',
                    'howtoselectwarrants',
                    'frequentlyaskedquestions',
                ]
            ]
        };
        this.ticker = null;
        this.initRedirect(props);
    }
    setIndex(index, val) {
        this.setState({ index })
        manageUrl(val)
    }
    initRedirect(props) {
        const match = MQSGUtil.getData(props, "match", null);
        if (match !== null) {
            const params = MQSGUtil.getData(match, "params", null);
            if (params !== null) {
                const ticker = MQSGUtil.getData(params, "ticker", null);
                if (ticker !== null) {
                    this.ticker = ticker.toLowerCase();
                }
            }
        }
    }
    render() {
        return (
            <div className='InvestorAcademy'>
                <div>
                    <div className="mytop InvestorAcademy">
                        <a href="/home">
                            <img style={{ border: 'none', height: 60 }} src="/img/investoracademy/mqlogo_bar.gif" alt="logo" />
                        </a>
                        <div className="top-line" />
                        <span style={{ height: 60, color: '#FFD843', float: 'left', fontSize: 27, letterSpacing: '-2px', fontWeight: 'lighter' }}>Singapore
                            Warrants Education</span>
                    </div>
                    <div className="my-main " style={{ paddingTop: '62px' }} >
                        <div className="container-fluid">
                            <div className="section">
                                <div className="page-header">
                                    <h1>Warrants Guidebook</h1>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className={classNames(
                                            { active: this.state.index == 1 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(1, 'whytradewarrants')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img1.png" />
                                            Why Trade Warrants?
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 2 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(2, 'whatarewarrants')}>
                                            <img style={{ border: 'none', height: '25px', width: '30px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img2.png" />
                                            What are Warrants?
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 3 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(3, 'howarewarrantspriced')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img3.png" />
                                            How are Warrants priced?
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 4 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(4, 'understandingthewarrantstructure')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img4.png" />
                                            <span>Understanding the Warrant Structure</span>
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 5 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(5, 'monitoringwarrantpricechanges')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img5.png" />
                                            Monitoring warrant price changes
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 6 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(6, 'usefulwarrantterms')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img6.png" />
                                            Useful Warrant terms
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 7 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(7, 'howtoselectawarrant')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img7.png" />
                                            How to select a Warrant
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 8 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(8, 'singlesharecallsandputsatexpiry')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img8.png" />
                                            Single share calls and puts at expiry
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 9 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(9, 'indexcallsandputsatexpiry')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img9.png" />
                                            Index calls and puts at expiry
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 10 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(10, 'risk')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img10.png" />
                                            Risk
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 11 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(11, 'marketmakers')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/img11.png" />
                                            Market Makers
                                        </div>
                                        <div className={classNames(
                                            { active: this.state.index == 12 },
                                            "alert alert-info"
                                        )} role="alert" onClick={() => this.setIndex(12, 'warrantsshortsplaylist')}>
                                            <img style={{ border: 'none', height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'sub' }} src="/img/investoracademy/playlist_png.png" />
                                            Warrants Shorts Playlist
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        {this.state.index == 1 && <WhyTradeWarrants />}
                                        {this.state.index == 2 && <WhatAreWarrants />}
                                        {this.state.index == 3 && <HowAreWarrantsPriced />}
                                        {this.state.index == 4 && <Understanding />}
                                        {this.state.index == 5 && <Monitoring />}
                                        {this.state.index == 6 && <Useful />}
                                        {this.state.index == 7 && <HowToSelect />}
                                        {this.state.index == 8 && <SingleShareextends />}
                                        {this.state.index == 9 && <IndexCalls />}
                                        {this.state.index == 10 && <Risk />}
                                        {this.state.index == 11 && <MarketMakers />}
                                        {this.state.index == 12 && <WarrantsShortsPlaylist />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer
                        {...this.props}
                        hrefL=""
                        hrefR=""
                        textL=""
                        textR=""
                        tag="home"
                    />
                </div>
            </div >
        )
    }
}
// Why Trade Warrants?
class WhyTradeWarrants extends Component {
    // componentDidMount() {
    //     window.addEventListener('resize', function () {
    //         var doms = $('.InvestorAcademy .videoplayer-container');
    //         var iframes = $('.InvestorAcademy iframe');
    //         for (var i = 0; i < doms.length; i++) {
    //             iframes[i].height = ((doms[i].offsetWidth / 9) * 16) + 'px'
    //             console.log('宽度:' + doms[i].offsetWidth, '高度:' + ((doms[i].offsetWidth / 9) * 16) + 'px')
    //         }
    //     });
    // }
    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img1.png" />
                        <h2 id="pname2">Why Trade Warrants?</h2>
                    </div>
                </div>
                <div className="showtext2  hidden-xs">Warrants have become increasingly popular because they give investors exposure to a
                    share for a fraction of the price. The movements in the warrant price are also usually much greater than
                    the movement in the price of the underlying share. Which means their potential to deliver a higher
                    percentage return is also increased.</div>
                <div className="showtile2">Please click on any of the tiles below for a written and video summary of the topics.</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('gearingeffect') }}>
                                    Gearing effect<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        {/*  class + in  自动打开*/}
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Gearingeffect />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('limitedloss') }}>
                                    Limited loss<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Limitedloss />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('abilitytogolongshort') }}>
                                    Ability to go long/short<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Porfitfrombullorbearmarket />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => { manageUrl('nomargincall') }}>
                                    No margin call<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div class="panel-body">
                                <Nomargincall />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFive">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive" onClick={() => { manageUrl('lowercapitaloutlay') }}>
                                    Lower capital outlay<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                            <div class="panel-body">
                                <Lowercapitaloutlay />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingSix">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix" onClick={() => { manageUrl('releasingsharecapital') }}>
                                    Releasing share capital<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                            <div class="panel-body">
                                <Releasingsharecapital />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingSeven">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" onClick={() => { manageUrl('easeoftrade') }}>
                                    Ease of trade<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                            <div class="panel-body">
                                <Easeoftrade />
                            </div>
                        </div>
                    </div>

                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingEight">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseSeven" onClick={() => { manageUrl('WhyTradeWarrantsPlaylist') }}>
                                    Why Trade Warrants Playlist<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseEight" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
                            <div class="panel-body">
                                {/* <Easeoftrade /> */}
                                <div className="row" >
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/C4BYMsQMFTE?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/lblLY3RY4JM?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/qKvjDm4So4U?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
class WhatAreWarrants extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '50px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img2.png" />
                        <h2 id="pname2">What are Warrants?</h2>
                    </div>
                </div>
                <div className="showtext2 hidden-xs">In this episode we explore what warrants are, we look at the difference between call and put warrants and we also learn about how warrants are named.</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('callvsput') }}>
                                    Call vs Put<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Callvsput />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('howacallwarrantworks') }}>
                                    How a Call Warrant works<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Howacallwarrantworks />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('howaputwarrantworks') }}>
                                    How a Put Warrant works<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Howaputwarrantworks />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => { manageUrl('howarewarrantsnamed') }}>
                                    How are Warrants named?<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div class="panel-body">
                                <Howarewarrantsnamed />
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        )
    }
}
class HowAreWarrantsPriced extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img3.png" />
                        <h2 id="pname2">How are Warrants priced?</h2>
                    </div>
                </div>
                <div className="showtext2 hidden-xs">In this episode, we’ll learn about how warrants are priced. By the time you have finished this tutorial you’ll understand how different variables affect the value of a warrant. When you know how they are priced, you’ll be able to predict their movements with more accuracy.</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div className="my-h2">Six Variables</div>
                <div className="imgholder">
                    <img style={{ maxWidth: 600 }} src="/img/investoracademy/Six-Varibles_enlarge.jpg" />
                </div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('stockprice') }}>
                                    Stock Price<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Shareprice />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('exerciseprice') }}>
                                    Exercise Price<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Exerciseprice />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('expirydate') }}>
                                    Expiry Date<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Expirydate />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => { manageUrl('volatility') }}>
                                    Volatility<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div class="panel-body">
                                <Volatility />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFive">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive" onClick={() => { manageUrl('dividends') }}>
                                    Dividends<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                            <div class="panel-body">
                                <Dividends />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingSix">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix" onClick={() => { manageUrl('interestrates') }}>
                                    Interest Rates<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                            <div class="panel-body">
                                <Interestrates />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
class Understanding extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img4.png" />
                        <h2 id="pname2">Understanding the Warrant Structure</h2>
                    </div>
                </div>
                <div className="showtext2 hidden-xs">In this tutorial we’ll explore how warrants are structured. By the end of the tutorial you will understand how the structure of a warrant relates to its price and the price of the underlying security.</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Intrinsic & Time Value<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" onClick={() => { manageUrl('intrinsicvalueandtimevalue') }}>
                            <div class="panel-body">
                                <Intrinsicvalueandtimevalue />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('timedecay') }}>
                                    Time Decay<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Timedecay />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('moneyness') }}>
                                    Moneyness<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Moneyness />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}
class Monitoring extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img5.png" />
                        <h2 id="pname2">Monitoring warrant price changes</h2>
                    </div>
                </div>
                <div className="showtext2 hidden-xs">In this fifth episode, we look at how you can track the performance of your warrant investment. When you have finished this episode you’ll understand the most accurate way to track how the price of warrants changes from day to day.</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('lasttradedprices') }}>
                                    Last traded prices<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Trackingwarrantspricechangelastprice />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('bidprices') }}>
                                    Bid prices<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Trackingwarrantspricechangebidprice />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('closingtimes') }}>
                                    Closing times<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Closingtimestonote />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
class Useful extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img6.png" />
                        <h2 id="pname2">Useful Warrant terms</h2>
                    </div>
                </div>
                <div className="showtext2 hidden-xs" />
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('delta') }}>
                                    Delta<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Delta />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('warrantspershare') }}>
                                    Warrants Per Share<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Exerciseratio />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('warrantsensitivity') }}>
                                    Warrant Sensitivity<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Warrantsensitivity />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => { manageUrl('breakevenprice') }}>
                                    Breakeven Price<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div class="panel-body">
                                <Breakevenprice />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFive">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive" onClick={() => { manageUrl('premium') }}>
                                    Premium<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                            <div class="panel-body">
                                <Premium />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingSix">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix" onClick={() => { manageUrl('gearing') }}>
                                    Gearing<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                            <div class="panel-body">
                                <Gearing />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingSeven">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" onClick={() => { manageUrl('effectivegearing') }}>
                                    Effective Gearing<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                            <div class="panel-body">
                                <Effectivegearing />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingEight">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight" onClick={() => { manageUrl('impliedvolatility') }}>
                                    Implied Volatility<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseEight" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
                            <div class="panel-body">
                                <Impliedvolatility />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
class HowToSelect extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img7.png" />
                        <h2 id="pname2">How to select a Warrant</h2>
                    </div>
                </div>
                <div className="showtext2 hidden-xs" >In this episode we will look at how you would go about selecting a warrant. In the previous tutorials we’ve looked at some of the most important concepts investors need to know in order to understand how warrants work. These include delta, warrants per share and implied volatility.</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('selectingawarrant') }}>
                                    Selecting a warrant<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Fourviewonunderlyingshareorindex />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('marketmaker') }}>
                                    Market maker<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Qualityofamarketmaker />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('riskmatrix') }}>
                                    Risk Matrix<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Riskmatrix />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => { manageUrl('tipsandtools') }}>
                                    Tips and tools<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div class="panel-body">
                                <Tipsandtools />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
class SingleShareextends extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img8.png" />
                        <h2 id="pname2">Single share calls and puts at expiry</h2>
                    </div>
                </div>
                <div className="showtext2  hidden-xs">In this episode we will look at what happens to a warrant when it reaches the point of expiry. We’ll also look at how they are settled.</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('lasttradingday') }}>
                                    Last trading day<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Warrantsatexpirylasttradingday />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('callexpiryformula') }}>
                                    Call expiry formula<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Cashsettlementamountforsinglesharewarrantsatexpiry />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('callexample') }}>
                                    Call example<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Singlesharecallwarrantexample />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => { manageUrl('putexpiryformula') }}>
                                    Put expiry formula<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div class="panel-body">
                                <Formulatocalculatevalueofputwarrantatexpiry />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFive">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive" onClick={() => { manageUrl('putexample') }}>
                                    Put example<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                            <div class="panel-body">
                                <Singleshareputwarrantexample />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
class IndexCalls extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img9.png" />
                        <h2 id="pname2">Understanding the Warrant Structure</h2>
                    </div>
                </div>
                <div className="showtext2 hidden-xs">In this tutorial we’ll explore how warrants are structured. By the end of the tutorial you will understand how the structure of a warrant relates to its price and the price of the underlying security.</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('indexexpiryformula') }}>
                                    Index expiry formula<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Cashsettlementamountforindexcallsandputsatexpiry />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('callexample') }}>
                                    Call example<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Indexcallwarrantexample />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('putexample') }}>
                                    Put example<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Indexputwarrantexample />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
class Risk extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img10.png" />
                        <h2 id="pname2">Risk</h2>
                    </div>
                </div>
                <div className="showtext2 hidden-xs">In this episode, we’re going to look at some of the risks you need to take into consideration when investing in warrants. By the end of the tutorial, you’ll have a clear picture of how five key risks can impact your investment. The five risks we will be exploring include market risk, gearing, limited life, credit risk and takeovers.</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('marketrisk') }}>
                                    Market Risk<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Marketrisk />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('gearing') }}>
                                    Gearing<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Riskgearing />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('limitedlife') }}>
                                    Limited Life<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <Limitedlife />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => { manageUrl('creditrisk') }}>
                                    Credit Risk<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div class="panel-body">
                                <Creditrisk />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFive">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive" onClick={() => { manageUrl('takeovers') }}>
                                    Takeovers<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                            <div class="panel-body">
                                <Takeovers />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
class MarketMakers extends Component {

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '40px', width: '40px', marginRight: '5px', verticalAlign: 'text-bottom' }} src="/img/investoracademy/img11.png" />
                        <h2 id="pname2">Market Makers</h2>
                    </div>
                </div>
                <div className="showtext2 hidden-xs">In this final episode we are going to explore market making and liquidity and how market makers such as Macquarie hedge their positions. Market makers have the responsibility of providing continuous buy and sell quotes in the warrants they issue. The idea is that by doing this, they provide liquidity in the market for investors to easily enter and exit their trades.</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('liquidityvstradedvolume') }}>
                                    Liquidity vs Volume<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <Liquidityvstradedvolume />
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('howwehedge') }}>
                                    How We Hedge<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <Howdomarketmakershedge />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

class WarrantsShortsPlaylist extends Component {
    // componentDidMount() {
    //     window.addEventListener('resize', function () {
    //         var doms = $('.InvestorAcademy .videoplayer-container');
    //         var iframes = $('.InvestorAcademy iframe');
    //         for (var i = 0; i < doms.length; i++) {
    //             iframes[i].height = ((doms[i].offsetWidth / 9) * 16) + 'px'
    //             console.log('宽度:' + doms[i].offsetWidth, '高度:' + ((doms[i].offsetWidth / 9) * 16) + 'px')
    //         }
    //     });
    // }
    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <div id="pagetitlediv2">
                        <img style={{ border: 'none', height: '60px', width: '90px', marginRight: '5px', verticalAlign: 'middle' }} src="/img/investoracademy/playlist_png.png" />
                        <h2 id="pname2">Warrants Shorts Playlist</h2>
                    </div>
                </div>
                <div className="showtext2  hidden-xs">Warrants have become increasingly popular because they give investors exposure to a share for a fraction of thprice. The movements in the warrant price are also usually much greater than the movement in the price of theunderlving share. Which means their potential to deliver a higher percentage return is also increased</div>
                <div className="showtile2">Please click on any of the tiles below for a written summary of the topic</div>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h4 class="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => { manageUrl('whatisstructuredwarrants') }}>
                                    What is Structured Warrants?<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        {/*  class + in  自动打开*/}
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                {/* <Easeoftrade /> */}
                                <div className="row" >
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/EGOqyMR5U2s?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/FckzWofqlVs?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/9nhq8bqBVJA?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => { manageUrl('whytradewarrants') }}>
                                    Why Trade Warrants?<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                {/* <Easeoftrade /> */}
                                <div className="row" >
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/QtHCsGBWvNk?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/qIQ3SfSIcSc?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/BA1uJjhYlsg?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/4zTqC_mYcpQ?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/WPU57JgVl2Y?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => { manageUrl('howtoselectwarrants') }}>
                                    How to Select Warrants?<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                {/* <Easeoftrade /> */}
                                <div className="row" >
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/QvpMxoU9CB4?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/FqYVayJngjQ?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/oy3b6Ryuhi8?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/9YIAOgfNYp4?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                            <h4 class="panel-title">
                                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => { manageUrl('frequentlyaskedquestions') }}>
                                    Frequently Asked Questions<span class="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div class="panel-body">
                                {/* <Easeoftrade /> */}
                                <div className="row" >
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/9ihTThvZTi4?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/L6z1CLdQf6U?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/eZ-zdgFfL5g?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/C_PTJ4htpxQ?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/xjLgMBv55rI?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/n2OhxvTlZS4?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/rvsq7xbWa4Y?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/rCLzvGZ5Z7Y?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/pyPz8ckYktY?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/38whcqbGTjc?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="videoplayer-container" style={{ height: '100%' }}>
                                            <iframe
                                                title="Market View"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                height="430px"
                                                width="100%"
                                                src="https://www.youtube.com/embed/kSZnFQ0MAGA?autoplay=0&index=0&rel=0&wmode=opaque"
                                            // src={link}
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
