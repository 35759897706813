
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class takeovers extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>
                                                Following a takeover announcement, there is a risk that warrant holders can lose their total time premium. For example, say an investor bought a call warrant with an exercise price of $10 when the shares were trading at $9, the warrant is out of the money
                                                and therefore all of the price is represented by time value <a href="/InvestorAcademy/timedecay" style={{ color: 'red' }}>click here</a> for more info on time value.<br /><br />
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/takeovers.png" style={{ maxWidth: '313px' }} />
                                            </div>
                                            <p>
                                                In the event of a takeover at $9.50, the warrant expiry would be brought forward to the current day and as the takeover price is below the exercise price, the warrant would expire worthless.<br /><br /> In order for the warrant
                                                to have value in a takeover situation, the takeover price will need to be above the exercise price.
                                            </p>
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips</h2>
                                                    <ul>
                                                        <li>
                                                            <p>Gearing is a double edged sword, while it is one of the main advantages of warrants, it is also one of the greatest risks. The geared exposure provided by warrants can magnify your percentage returns, but it
                                                                can also increase your percentage losses. </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}