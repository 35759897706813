
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class liquidityvstradedvolume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>The role of a designated market maker is to provide continuous buy and sell quotes in their warrants. In doing so, the market makers provide liquidity in the warrants so that investors can easily enter and exit their trades.
                                                The quality of the market maker is often a big factor for investors choosing a warrant.
                                            </p>
                                            <p>
                                                Typically, a market maker will use a semi-automated trading system to enter their quotes, however this normally requires a lot of human interaction and control. The trading system will enter bid and offer quotes in the warrants based on the price in the
                                                underlying share. As the price in the underlying moves, the market maker’s quotes will also adjust. Some market making systems will also change their bid and offer volume to follow the volume in the underlying share.<br /><br />                                In Singapore, there are a few exemptions to the continuous quoting requirements, these can be found in the issuers’ listing documents. The most common being, when the warrant value falls below the minimum bid of $0.001,
                                                in this case the warrant is considered worthless and the market maker is no longer required to provide a bid price.<br /><br /> A common misconception regarding liquidity is that only warrants with high traded volume are liquid.
                                                In the below example, we demonstrate that this is often incorrect.
                                            </p>
                                            <div className="imgholder">
                                                <h2>Warrant A</h2>
                                                <img src="/img/education/liqudityvsvolume1.png" alt="A look at Warrant Liquidity" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/liqudityvsvolume1.png')}>View table</div>
                                            </div>
                                            <div className="imgholder">
                                                <h2>Warrant B</h2>
                                                <img src="/img/education/liqudityvsvolume2.png" alt="A look at Warrant Liquidity" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/liqudityvsvolume2.png')}>View table</div>
                                            </div>
                                            <p>
                                                The above tables show similar warrants A and B listed over the same underlying share. Warrant A is an actively traded warrant, with 5 million in volume for the day, while Warrant B has not traded today. Some investors may assume that Warrant B is illiquid
                                                and avoid trading this warrant.<br /><br /> In actual fact, the liquidity for both warrants is very similar as both have the same number of warrants on the bid and offer for investors to enter
                                                and exit their trade. The liquidity of a warrant is more a function of the volume provided on the bid and offer by the market maker, rather than the number of times a warrant has actually traded.<br /><br /> Investors choosing
                                                which warrant to buy should first consider our <a href="/InvestorAcademy/fourviewonunderlyingshareorindex" style={{ color: 'red' }}>suggested selection
                                                    process</a>. Do not choose a warrant based only on it’s turnover as this may not be the right warrant for you. The quality and reputation of the market maker is also very important.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}