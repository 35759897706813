import MQSGUtil from '../../../components/mqsgUtil';
import ReactTooltip from "react-tooltip";

function getData(fieldName, data) {
    if (fieldName in data) {
        return data[fieldName];
    }
    return "noData";
}
const lowTimeDecayTooltip = (
    <div>
      <p>
        <strong>Low time decay:</strong> Warrants with this indicator have relatively lower time decay, meaning lower holding costs for investors. Generally, all else constant, longer dated Warrants have lower time decay than shorter dated Warrants and vice versa.
      </p>
    </div>
  );
  const soldOutTooltip = (
    <div>
      <p>
        <strong>Inventory sold-out:</strong> Warrants with this indicator are either sold out or close to being sold out, meaning the market maker may no longer provide an offer price or have widened spreads by raising the offer price. Existing holders are not disadvantaged as the bid price is still maintained at its fair price. However, please exercise caution if you are looking to buy this SW as the market price may be inflated by other investors queuing in the market. Do check the Live Matrix for the warrant’s fair bid price.
      </p>
    </div>
  );
const hiTooltip = (
    <div style={{fontFamily: "'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif"}}>
      <p>
        <strong style={{fontWeight:600}}>Highest liquidity:</strong> Highlights the Macquarie warrant for each underlying with the largest volumes on the current bid and offer.
      </p>
    </div>
  );
  const hotTooltip = (
    <div  style={{fontFamily: "'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif"}}>
      <p>
        <strong  style={{fontWeight:600}}>Trending warrants:</strong> The Trending warrants are chosen based on the warrants’ gearing, liquidity, sensitivity and are on tighter spreads
      </p>
    </div>
  );
  const reTooltip = (
    <div   style={{fontFamily: "'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif"}}>
      <p>
        <strong   style={{fontWeight:600}}>Responsive:</strong> Indicates the Macquarie warrant that is most sensitive to the underlying price movements for each underlying. This is usually the one with the smallest number in the “Sensitivity” field.
      </p>
    </div>
  );
  const neTooltip = (
    <div   style={{fontFamily: "'HelveticaNeueLight','HelveticaNeue','Roboto', Arial, sans-serif"}}>
      <p>
        <strong   style={{fontWeight:600}}>Near expiry:</strong>Macquarie warrants with less than 1 month to expiry. These tend to be very high risk, so please trade with caution.
      </p>
    </div>
  );
function getUnderlyingLabel(underlyingTicker) {
    const underlying = underlyingData.find((data)=> {
        return underlyingTicker === data.underlying_ticker;
    });
    if (typeof underlying === "undefined") {
        return underlyingTicker;
    }
    return underlying.label;
}

function getClassName(size, rowType, subType="") {
    if (rowType === "result-sort") {
        if (size === "lg") {
            if (subType === "down") {
                return "icon-sort glyphicon glyphicon-triangle-bottom result-sort-down";
            } else {
                return "icon-sort glyphicon glyphicon-triangle-top result-sort-up";
            }
        } else if (size === "sm" || size === "xs") {
            if (subType === "down") {
                return "icon-sort-xs glyphicon glyphicon-triangle-bottom";
            } else {
                return "icon-sort-xs glyphicon glyphicon-triangle-top";
            }
            // return "icon-sort-xs glyphicon glyphicon-triangle-top";
        }
        return "";
    }
}

const tableConfig = {
    columnSortJsx: function(size, field, sortingFunc) {
        if ("sortby" in field) {
            const sortby = field.sortby;
            return (<th key={sortby}>
                <span className={getClassName(size, "result-sort", "down")} sortorder="desc" sortby={sortby} 
                                onClick={()=> {sortingFunc(field, 'desc')}}></span>
                <span className={getClassName(size, "result-sort", "up")} sortorder="asc" sortby={sortby} 
                                onClick={()=> {sortingFunc(field, 'asc')}}></span>
            </th>);
            // if (size === "lg") {                
            //     return (<th key={sortby}>
            //         <span className={getClassName(size, "result-sort", "down")} sortorder="desc" sortby={sortby} 
            //                         onClick={()=> {sortingFunc(field, false)}}></span>
            //         <span className={getClassName(size, "result-sort", "up")} sortorder="asc" sortby={sortby} 
            //                         onClick={()=> {sortingFunc(field, true)}}></span>
            //     </th>);
            // } else if (size === "sm") {
            //     return (<th key={sortby}>
            //         <span className={getClassName(size, "result-sort")} sortorder="asc" sortby={sortby}
            //                         onClick={()=> {sortingFunc(field, true)}}></span>
            //     </th>);
            // } else if (size === "xs") {
            //     return (<th key={sortby}>
            //         <span className={getClassName(size, "result-sort")} sortorder="asc" sortby={sortby}
            //                         onClick={()=> {sortingFunc(field, true)}}></span>
            //     </th>);
            // }
        }
        return (<th key={"not-support" + field["dataHasTip"]}></th>);
    },
    columnHeader2Jsx: function(size, field, visible) {
        const groupMap = {GENERAL: "04", MARKET: "05", WARRANT: "06"}
        let header = field.headerJsx(size);
        let style = null;   
        let className = ""
        if (size === "lg") {   
            if ("width" in field && "group" in field) {
                style = {width: field.width}
                className = "bgcolor-" + groupMap[field.group] + (header === null ? " noborder": "")
                if (visible === false) {
                    header = null;
                }
                return (<th key={"header-" + size + "-" + field["name"]} className={className} style={style}>{header}</th>);                
            }
        } else if (size === "sm") {
            if ("group" in field) {                
                if (header === null) {
                    className += "bgcolor-" + groupMap[field.group] + (header === null ? " noborder": "")
                } else {
                    className = "col-clicka ble bgcolor-" + groupMap[field.group];
                } 
                return (<th key={"header-" + size + "-" + field["name"]} className={className} 
                            sortby={"sortby" in field ? field.sortby : ""}>
                            {header}
                        </th>);                               
            }
        } else if (size === "xs") {
            className = "col-clickable"
            return (<th key={"header-" + size + "-" + field["name"]} className={className} 
                        sortby={"sortby" in field ? field.sortby : ""}>
                        {header}
                    </th>);
        }
        return (<th key={"not-support" + field["dataHasTip"]}></th>);
    },
    columnShow: function(size, field) {
        if (size === "xs") {
            const xsColumn = ["dwSymbol","exercisePrice", "ltDate", "bidPrice", "tradeVolume",'underlying','type']
            if (xsColumn.indexOf(field.name) > -1) {
                return true;
            }
        } else if (size === "sm") {
            const smNotUseColumn = ["underlying", "delta", "sensitivity", "avg_decay"]
            if (smNotUseColumn.indexOf(field.name) === -1) {
                return true;
            }            
        } else if (size === "lg") {
            return true;
        }
        return false;
    },
    fields: [{
        name: "dwSymbol",
        dataHasTip: "0",
        group: "GENERAL",
        sortby: "wcode",
        datatype: "string",
        width: "5.15%",        
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="wcode">Warrant<br/>code</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-Warrant-tooltip"
                    >
                         <span className="ttip" data-target="wcode">Warrant<br/>code</span>
                         <ReactTooltip
                                id="indicator-Warrant-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Warrant code: </strong> 
                                Each warrant has an unique 4-digit identification code,
                                <a href="/InvestorAcademy/howarewarrantsnamed"  target="_blank"> click here </a>
                                for a more detailed explanation.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }
            return (<>Warrant<br/>code</>);
        },
        columnData: function(data) {
            return getData(this.name, data);
        },
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            const href = "/tools/livematrix/" + value
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>
                        <a href={href}>{value}</a>
                    </td>);
        }             
        },{
        name: "indicator",
        dataHasTip: "1",
        group: "GENERAL",
        width: "4%",
        headerJsx: function(size) {
            return null;
        },
        columnJsx: function(data, index) {                    
            const highlqdy = getData("HIGHLQDY", data);
            const appchexry = getData("APPCHEXPRY", data);
            const highresp = getData("HIGHRESP", data);
            const hotlist = getData("HOTLIST", data);
            const lowtimedecay = getData("LongestDated", data);
            const soldOut = getData("SoldOut", data);
            
            const span = []
            if (highlqdy === true) {
                span.push(<span className="indicator-item-icon ttip icon-liquidity" data-target="highestliquidity" data-tip
                data-for="li-tooltip"
                            data-hasqtip="107" key={"icon-liquidity-" + index}>
                                <ReactTooltip
                                    id="li-tooltip"
                                    className="search-tooltip"
                                    delayHide={100}
                                    place="right"
                                    type="warning"
                                    effect="solid"
                                >
                                    {hiTooltip}
                                </ReactTooltip>
                            </span>) 
            }
            if (lowtimedecay === true) {
                span.push(<span className="indicator-item-icon ttip icon-lowtimedecay" data-target="lowtimedecay" data-tip
                data-for="lowtimedecay-tooltip"
                            data-hasqtip="107" key={"icon-lowtimedecay-" + index}>
                                <ReactTooltip
                                    id="lowtimedecay-tooltip"
                                    className="search-tooltip"
                                    delayHide={100}
                                    place="right"
                                    type="warning"
                                    effect="solid"
                                >
                                    {lowTimeDecayTooltip}
                                </ReactTooltip>
                            </span>) 
            }
            if (soldOut === true) {
                span.push(<span className="indicator-item-icon ttip icon-soldOut" data-target="soldOut" data-tip
                data-for="soldOut-tooltip"
                            data-hasqtip="107" key={"icon-soldOut-" + index}>
                                <ReactTooltip
                                    id="soldOut-tooltip"
                                    className="search-tooltip"
                                    delayHide={100}
                                    place="right"
                                    type="warning"
                                    effect="solid"
                                >
                                    {soldOutTooltip}
                                </ReactTooltip>
                            </span>) 
            }
            if (appchexry === true) {
                span.push(<span className="indicator-item-icon ttip icon-expiry" data-target="nearexpiry"  data-tip
                data-for="ne-tooltip"
                            data-hasqtip="" key={"icon-expiry-" + index}>
                                <ReactTooltip
                                    id="ne-tooltip"
                                    className="search-tooltip"
                                    delayHide={100}
                                    place="right"
                                    type="warning"
                                    effect="solid"
                                >
                                    {neTooltip}
                                </ReactTooltip>
                            </span>) 
            }
            if (highresp === true) {
                span.push(<span className="indicator-item-icon ttip icon-responsive" data-target="responsive"   data-tip
                data-for="re-tooltip"
                            data-hasqtip="" key={"icon-responsive-" + index}>
                                <ReactTooltip
                                    id="re-tooltip"
                                    className="search-tooltip"
                                    delayHide={100}
                                    place="right"
                                    type="warning"
                                    effect="solid"
                                >
                                    {reTooltip}
                                </ReactTooltip>
                            </span>) 
            }
            if (hotlist === true) {
                span.push(<span className="indicator-item-icon ttip icon-hotlist" data-target="hotdw" data-tip
                data-for="hot-tooltip"
                            data-hasqtip="40" key={"icon-hotlists-" + index}>
                                <ReactTooltip
                                    id="hot-tooltip"
                                    className="search-tooltip"
                                    delayHide={100}
                                    place="right"
                                    type="warning"
                                    effect="solid"
                                >
                                    {hotTooltip}
                                </ReactTooltip>
                            </span>) 
            }                                    
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{span}</td>);
        }
        },{
        name: "underlying",
        dataHasTip: "2",
        group: "GENERAL",
        sortby: "ucode",
        datatype: "string",
        width: "11%",
        headerJsx: function(size) {
            
            // return (<span className="ttip" data-target="underlying">Underlying</span>);
            return (
                <div
                    data-tip
                    data-for="indicator-underlying-tooltip"
                    >
                         {/* <span className="ttip" data-target="underlying">{size=='xs'?'Underlying name':'Underlying'}</span> */}
                         <span className="ttip" data-target="underlying">Underlying</span>
                         <ReactTooltip
                                id="indicator-underlying-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Underlying: </strong> 
                                The stock or index over which the Warrant is listed.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
            );
            
        },
        columnData: function(data) {
            const underlyingSymbol = getData("underlyingSymbol", data);
            // getting "label" from underlyingData using underlyingSymbol
            return MQSGUtil.getUnderlyingTickerDisplayName(underlyingSymbol);
        },      
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            const underlyingSymbol = getData("underlyingSymbol", data);
            const href = "/tools/underlying/" + underlyingSymbol;
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}><a href={href}>{value}</a></td>);
        }
        },{
        name: "type",
        dataHasTip: "3",
        group: "GENERAL",
        sortby: "type",
        datatype: "string",
        width: "3%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="type">Type</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-type-tooltip"
                    >
                         <span className="ttip" data-target="type">Type</span>
                         <ReactTooltip
                                id="indicator-type-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Type: </strong> 
                                Calls increase in value as share price rises, Puts increase in value as share price falls.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }
            return "Type";
        },
        columnData: function(data) {
            return getData(this.name, data);
        },        
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "exercisePrice",
        dataHasTip: "4",
        group: "GENERAL",
        sortby: "ep",
        width: "6.1%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="exerciseprice">Exercise<br/>price</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-exercisePrice-tooltip"
                    >
                         <span className="ttip" data-target="exerciseprice">Exercise<br/>price<br/>(Strike)</span>
                         <ReactTooltip
                                id="indicator-exercisePrice-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Exercise price: </strong> 
                                The price at which you can buy (calls) or sell (puts) the underlying
                                <br />
                                share or index at expiry.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
                
            }
            if (size === "xs") {
                // return (<span className="ttip" data-target="exerciseprice">Exercise<br/>price</span>);
                return (<>Strike</>)
            }           
            return (<>Ex.<br/>price</>);
        },
        columnData: function(data) {
            if(data.underlying_name=='TENCENT'){
                return getData('exercise_price_cal', data);
            }else{
                return getData(this.name, data);
            }
        },        
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "dwps",
        dataHasTip: "5",
        group: "GENERAL",
        sortby: "dwps",
        width: "4.1%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="wps">WPS</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-dwps-tooltip"
                    >
                         <span className="ttip" data-target="wps">WPS</span>
                         <ReactTooltip
                                id="indicator-dwps-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>WPS: </strong> 
                                The number of warrants that entitles you to one unit of the underlying share or index at expiry.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }
            return (<>Ex.<br/>ratio</>);
        },
        columnData: function(data) {
            if(data.underlying_name=='TENCENT'){
                return getData('conv_ratio_cal', data);
            }else{
                return getData(this.name, data);
            }
        },         
        columnJsx: function(data, index) {
            var value = this.columnData(data);
            if(data.underlying_name=='TENCENT'){
                value=value.toFixed(3)
            }
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        } 
        },{
        name: "ltDate",
        dataHasTip: "6",
        group: "GENERAL",
        sortby: "ldate",
        datatype: "date",
        width: "6.1%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="lasttradingdate">Last<br/>trading<br/>date</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-date-tooltip"
                    >
                         <span className="ttip" data-target="lasttradingdate">Last<br/>trading<br/>date</span>
                         <ReactTooltip
                                id="indicator-date-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Last trading date: </strong> 
                                The last day to buy or sell the warrant.  This date is always the 5th trading day prior to the expiry date.  For Hong Kong underlyings, the last trading date will be brought forward if the 5th trading day prior to expiry falls on a Hong Kong holiday.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }   
            return (<>Last<br/>trading<br/>date</>);
        },
        columnData: function(data) {
            return getData(this.name, data);
        },        
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "issuer",
        dataHasTip: "7",
        group: "GENERAL",
        sortby: "isr",
        datatype: "string",
        width: "6.45%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="issuer">Issuer</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-isr-tooltip"
                    >
                         <span className="ttip" data-target="issuer">Issuer</span>
                         <ReactTooltip
                                id="indicator-isr-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Issuer: </strong> 
                                The Bank or Securities company that issues the Warrant.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }
            return "Issuer";
        },
        columnData: function(data) {
            return getData(this.name, data);
        },        
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            return (<td className={this.className + " bordered"} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "bidVolume",
        dataHasTip: "8",
        group: "MARKET",
        sortby: "bv",
        width: "6.1%",
        headerJsx: function(size) {
            if (size === "log") {
                return (<span className="ttip" data-target="bidvolume">Bid<br/>volume<br/>('000)</span>);
                // return (

                //     <div
                //     data-tip
                //     data-for="indicator-MARKET-tooltip"
                //     >
                //          <span className="ttip" data-target="bidvolume">Bid<br/>volume<br/>('000)111</span>
                //          <ReactTooltip
                //                 id="indicator-MARKET-tooltip"
                //                 className="tools-search-tooltip"
                //                 delayHide={100}
                //                 place="right"
                //                 type="warning"
                //                 effect="solid"
                //             >
                //             <div>
                //                 <p>
                //                 <strong>Bid volume:</strong> 
                //                 The volume provided at the current highest bid price for the Warrant.
                //                 </p>
                //             </div>
                //         </ReactTooltip>
                //     </div>
                // );
            }
            // return (<>Bid<br/>volume<br/>('000)</>);
            return (
                <div
                    data-tip
                    data-for="indicator-MARKET-tooltip"
                    >
                         <span className="ttip" data-target="bidvolume">Bid<br/>volume<br/>('000)</span>
                         <ReactTooltip
                                id="indicator-MARKET-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Bid volume: </strong> 
                                The volume provided at the current highest bid price for the Warrant.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
            );
        },
        columnData: function(data) {
            return getData(this.name + "_f", data);
        },        
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "bidPrice",
        dataHasTip: "9",
        group: "MARKET",
        sortby: "bp",
        width: "5.1%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="bid">Bid<br/>(SGD)</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-bp-tooltip"
                    >
                         <span className="ttip" data-target="bid">Bid<br/>(SGD)</span>
                         <ReactTooltip
                                id="indicator-bp-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Bid: </strong> 
                                The highest current buy price of the Warrant. This is the value that most accurately reflects the 'value' of the Warrant at any point in time.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            } else if (size === "sm") {
                return (<>Bid<br/>(SGD)</>);
            }
            return (<>Bid</>);      
            
        },
        columnData: function(data) {
            return getData(this.name + "_f", data);
        },         
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "askPrice",
        dataHasTip: "10",
        group: "MARKET",
        sortby: "ap",
        width: "6.1%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="ask">Ask<br/>(SGD)</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-ap-tooltip"
                    >
                         <span className="ttip" data-target="ask">Ask<br/>(SGD)</span>
                         <ReactTooltip
                                id="indicator-ap-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Ask: </strong> 
                                The lowest current sell price for the Warrant.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }
            return (<>Ask<br/>(SGD)</>);    
        },
        columnData: function(data) {
            return getData(this.name + "_f", data);
        },         
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "askVolume",
        dataHasTip: "11",
        group: "MARKET",
        sortby: "av",
        width: "5.1%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="askvolume">Ask<br/>volume<br/>('000)</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-av-tooltip"
                    >
                         <span className="ttip" data-target="askvolume">Ask<br/>volume<br/>('000)</span>
                         <ReactTooltip
                                id="indicator-av-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Ask volume: </strong> 
                                The volume provided at the current lowest sell price for the Warrant.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }
            return (<>Ask<br/>volume<br/>('000)</>);
        },
        columnData: function(data) {
            return getData(this.name + "_f", data);
        },         
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }        
        },{
        name: "tradeVolume",
        dataHasTip: "12",
        group: "MARKET",
        sortby: "tv",
        width: "6.1%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="tradedvolume">Traded volume<br/>('000)</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-tv-tooltip"
                    >
                         <span className="ttip" data-target="tradedvolume">Traded volume<br/>('000)</span>
                         <ReactTooltip
                                id="indicator-tv-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Traded volume: </strong> 
                                Total volume traded in the Warrant for the current day. A Warrant that has not traded for the day does not necessarily mean that it is not liquid. 
                                <a href="/InvestorAcademy/liquidityvstradedvolume"  target="_blank"> Click here </a>
                                for more info about Warrant and liquidity.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }     
            return (<>Traded<br/>volume<br/>('000)</>);        
        },
        columnData: function(data) {
            return getData(this.name + "_f", data);
        },         
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "priceChange",
        dataHasTip: "13",
        group: "MARKET",
        sortby: "pc",        
        width: "5%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="pricechange">Price change<br/>(%)</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-pc-tooltip"
                    >
                         <span className="ttip" data-target="pricechange">Price change<br/>(%)</span>
                         <ReactTooltip
                                id="indicator-pc-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p  style={{textAlign:'left'}}>
                                <strong>Price change: </strong> 
                                Current bid price for the Warrant compared to the Prior (previous days close). The bid price of the Warrant 1 minute before close of trading at 5.00pm, will be used for the Prior. For more info 
                                <a href="/InvestorAcademy/howarewarrantspriced"  target="_blank" style={{textAlign:'left'}}> click here </a>
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }
            return (<>Price change<br/>(%)</>);  
        },
        columnData: function(data) {
            return getData(this.name + "_f", data);
        },         
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            let upDown = " val ";
            if (value.indexOf("-") > -1) {
                upDown += "down ";
            } else if (value.indexOf("+") > -1) {
                upDown += "up ";
            }               
            return (<td className={this.className  + upDown + " bordered"} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "delta",
        dataHasTip: "14",
        group: "WARRANT",
        sortby: "dlt",        
        width: "5.1%",
        headerJsx: function(size) {
            // return (<span className="ttip" data-target="delta">Delta<br/>(%)</span>);
            return (
                <div
                    data-tip
                    data-for="indicator-dlt-tooltip"
                    >
                         <span className="ttip" data-target="delta">Delta<br/>(%)</span>
                         <ReactTooltip
                                id="indicator-dlt-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Delta: </strong> 
                                Estimates how a warrant moves in relation to the underlying stock/index. Puts have negative deltas because their prices increase as the underlying stock/index falls. Note, if your warrant has a WPS that this will need to be taken into account.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
            );
        },
        columnData: function(data) {
            return getData(this.name, data)
        },         
        columnJsx: function(data, index) {
            const value = this.columnData(data);
            let upDown = " val ";
            if (value.indexOf("-") > -1) {
                upDown += "down ";
            } else if (value.indexOf("+") > -1) {
                upDown += "up ";
            }               
            return (<td className={this.className  + upDown} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "sensitivity",
        dataHasTip: "15",
        group: "WARRANT",
        sortby: "ss",
        width: "6.0%",
        headerJsx: function(size) {
            // return (<span className="ttip" data-target="sensitivity">Sensitivity</span>);
            return (
                <div
                    data-tip
                    data-for="indicator-ss-tooltip"
                    >
                         <span className="ttip" data-target="sensitivity">Sensitivity</span>
                         <ReactTooltip
                                id="indicator-ss-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Sensitivity: </strong> 
                                Shows you how much the underlying stock or index must move for your warrant to move one "tick" or "Spread". For warrants that cost less than $0.200, one tick is $0.001.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
            );
        },
        columnData: function(data) {
            return getData(this.name, data);
        },         
        columnJsx: function(data, index) {     
            const value = this.columnData(data);       
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "effectiveGearing",
        dataHasTip: "16",
        group: "WARRANT",
        sortby: "eg",        
        width: "4.6%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="effectivegearing">Eff. gearing<br/>(x)</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-eg-tooltip"
                    >
                         <span className="ttip" data-target="effectivegearing">Eff. gearing<br/>(x)</span>
                         <ReactTooltip
                                id="indicator-eg-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p style={{textAlign:'left'}}>
                                <strong>Effective gearing: </strong> 
                                Indicates your leveraged exposure. An effective gearing of 5X tells you a 1% move in the underlying stock/index results in an approximate 5% move in your Warrant price. <a href="/InvestorAcademy/effectivegearing" target="_blank">Click here </a>to learn more about effective gearing.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }
            return (<>Eff. gearing<br/>(x)</>)
        },
        columnData: function(data) {
            return getData(this.name, data);
        },         
        columnJsx: function(data, index) {             
            const value = this.columnData(data);       
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }
        },{
        name: "impliedVolalitiy",
        dataHasTip: "17",
        group: "WARRANT",
        sortby: "iv",          
        width: "6.0%",
        headerJsx: function(size) {
            if (size === "lg") {
                // return (<span className="ttip" data-target="impliedvolatility">Volatility<br/>(%)</span>);
                return (
                    <div
                    data-tip
                    data-for="indicator-iv-tooltip"
                    >
                         <span className="ttip" data-target="impliedvolatility">Volatility<br/>(%)</span>
                         <ReactTooltip
                                id="indicator-iv-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p style={{textAlign:'left'}}> 
                                <strong>Volatility: </strong> 
                                the higher the price of the Warrant. Relates to the volatility and risk of the underlying share/Index.<a href="/InvestorAcademy/impliedvolatility" target="_blank"> Click here </a>to learn more about implied volatility.
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
                );
            }
            return (<>Volatility<br/>(%)</>);
        },
        columnData: function(data) {
            return getData(this.name, data);
        },         
        columnJsx: function(data, index) {             
            const value = this.columnData(data);       
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }  
        },{
        name: "avg_decay",
        dataHasTip: "18",
        group: "WARRANT",
        width: "6.0%",
        headerJsx: function(size) {
            if (size === "log") {
                return (<span className="ttip" data-target="estimateddecay">Estimated decay</span>);
                // return (
                //     <div
                //     data-tip
                //     data-for="indicator-WARRANT-tooltip"
                //     >
                //          <span className="ttip" data-target="estimateddecay">Estimated decay</span>
                //          <ReactTooltip
                //                 id="indicator-WARRANT-tooltip"
                //                 className="tools-search-tooltip"
                //                 delayHide={100}
                //                 place="right"
                //                 type="warning"
                //                 effect="solid"
                //             >
                //             <div>
                //                 <p>
                //                 <strong>Estimated decay:</strong> 
                //                 The estimated average time decay for the warrant over the next 5 days.
                //                 </p>
                //             </div>
                //         </ReactTooltip>
                //     </div>
                // );
            }
            // return "Estimated decay";
            return (
                <div
                    data-tip
                    data-for="indicator-WARRANT-tooltip"
                    >
                         <span className="ttip" data-target="estimateddecay">Estimated decay</span>
                         <ReactTooltip
                                id="indicator-WARRANT-tooltip"
                                className="tools-search-tooltip"
                                delayHide={100}
                                place="right"
                                type="warning"
                                effect="solid"
                            >
                            <div>
                                <p>
                                <strong>Estimated decay: </strong> 
                                {/* The estimated average time decay for the warrant over the next 5 days. */}
                                Estimated drop in warrant value per day
                                </p>
                            </div>
                        </ReactTooltip>
                    </div>
            );
        },
        columnData: function(data) {
            return getData(this.name, data);
        },         
        columnJsx: function(data, index) {             
            var value = this.columnData(data);       
            value= value>0?'-'+value:value
            return (<td className={"col-" + this.name} key={this.dataHasTip + "-" + index}>{value}</td>);
        }                                                                                                     
    }]
}

export default tableConfig;