import React, { Component, useState } from "react";
import AmCharts from "@amcharts/amcharts3-react";
import classNames from "classnames";
import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import AdvertBox from "../../../components/advert/advert";
import Select from "react-select";
import { components } from "react-select";
import "./selector.css";
import { Link } from "react-router-dom";
import highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

const MenuList = (props) => {
  const { onRicChange, onMenuClose } = props.selectProps;
  const getValue = (i) => {
    onRicChange(i);
    onMenuClose();
  };
  const currency = (symbol) => {
    const symbolSname = symbol.symbol;
    if (
      symbolSname === "BABA" ||
      symbolSname === "JD" ||
      symbolSname === "NETEASE" ||
      symbolSname === "TENCENT" ||
      symbolSname === "XIAOMI" ||
      symbolSname === "BYD" ||
      (symbol.ric && symbol.ric.endsWith(".HK"))
    ) {
      return "HK$";
    } else if (
      symbolSname === "HSI" ||
      symbolSname === "N225" ||
      symbolSname === "S&P500" ||
      symbolSname === "STI" ||
      symbolSname === 'SiMSCI' ||
      symbolSname === 'SEA' ||
      symbolSname === 'NDX' ||
      symbolSname === 'DJI'
    ) {
      return "";
    }
    // return "S$";
    return "S$";
  };
  return (

    <components.MenuList {...props}>
      {props.options.map((i) => {
        return (
          <div
            key={i.symbol}
            className="selectSption"
            style={{
              textAlign: "center",
              padding: "5px 10px",
              cursor: "pointer",
            }}
            onClick={() => {
              getValue(i);
            }}
          >
            <span style={{ color: "#0675C2" }}>{i.symbol} </span>
            <span style={{ display: 'none' }}>{i.label}</span>
            <span>
              {currency(i)}
              {i.TRDPRC_1}{" "}
            </span>
            <span
              style={{
                color: i["PCTCHNG"] > 0 ? "#6CC263" : "red",
              }}
            >
              (
              {i.PCTCHNG > 0 ? "+" + i.PCTCHNG : i.PCTCHNG}%)
            </span>
          </div>
        );
      })}
    </components.MenuList>
  );
};
export default class WarrantSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {
        period: "Y",
        ric: null,
        symbol: null,
        tprices: null,
        pricein: null,
        weeks: 4,
        weeksin: null,
        targets: 0.1,
        type: "CALL",
        first: true,
        lastPrice: null,
        lastPriceModel: null
      },
      symbolsdata: null,
      chartdata: null,
      warrantdata: null,
      isLoadingSymbol: true,
      isLoadingChart: true,
      isLoadingWarrant: false,
      type: '',
      putOrCall: null,
      isShowTable: false
    };
    this.logic = new SelectorLogic();
  }
  componentDidMount() {
    this.onFetchSymbol();
    if (!this.isHome()) {
      document.title = "Warrant Selector | Singapore Warrants | Macquarie"
    }
  }

  onFetchStaticSymbol() {
    const isLoadingSymbol = false;
    const staticData = MQSGUtil.getStaticData("WarrantSelector");
    this.setState({ symbolsdata: staticData.symbols, isLoadingSymbol });
    // simulate user selection
    const symbol = {
      PCTCHNG: 1.95,
      TRDPRC_1: 30723,
      dsply_name: "HANG SENG FEB1",
      name: "HSI",
      ric: "HSIc1",
      sname: "HSI",
      symbol: "HSI",
    };
    this.onRicChange(symbol);
  }

  onFetchSymbol() {
    if (MQSGUtil.isUsingStaticData("WarrantSelector")) {
      this.onFetchStaticSymbol();
      return;
    }
    const isLoadingSymbol = false;
    let symbolsdata = null;
    const url = MQSGUtil.getAPIBasePath() + "/SymbolsJSON?type=s";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {

          if ("symbols" in result && Array.isArray(result.symbols)) {
            symbolsdata = result;
            if (this.isHome()) {
              const initSymbol = this.initSymbol(symbolsdata);
              this.onRicChange(initSymbol);
            }
            if (this.props.location && this.props.location.state && this.props.location.state.selected) {
              const initSymbol = this.initSymbol(symbolsdata);
              this.onRicChange(initSymbol);
              this.setCallOrPut('CALL')
            }
          }
          this.setState({ symbolsdata, isLoadingSymbol });
        },
        (error) => {
          this.setState({ symbolsdata, isLoadingSymbol });
        }
      );
  }

  onFetchStaticChartData(ric, period = "D") {
    const isLoadingChart = false;
    const staticData = MQSGUtil.getStaticData("WarrantSelector");
    let chartdata = null;
    if (ric in staticData) {
      const selectordataAll = staticData[ric];
      if (period in selectordataAll) {
        chartdata = selectordataAll[period];
      }
    }
    this.setState({ chartdata, isLoadingChart });
  }

  onFetchChartData(ric, period = "D") {
    if (this.isHome()) {
      period = 'Y'
    }
    if (MQSGUtil.isUsingStaticData("WarrantSelector")) {
      setTimeout(() => {
        this.onFetchStaticChartData(ric, period);
      }, 100); // pretend fetch
      return;
    }
    const isLoadingChart = false;
    let chartdata = null;
    const url =
      MQSGUtil.getAPIBasePath() +
      "/alert?TYPE=JSON&RIC=" +
      `${ric == 'SSGc2' ? 'SSGc1' : ric}` +
      "&PERIODICITY=" +
      period +
      "&d=" +
      Date.now() +
      "&u=1";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          chartdata = result;
          this.setState({ chartdata, isLoadingChart });
        },
        (error) => {
          this.setState({ chartdata, isLoadingChart });
        }
      );
  }

  onFetchStaticWarrantData(ric, type, trendwarrantdata = null) {
    const isLoadingWarrant = false;
    const staticData = MQSGUtil.getStaticData("WarrantSelector");
    let warrantdata = null;
    let selectedNew = null;
    if (trendwarrantdata !== null) {
      selectedNew = { ...this.state.selected };
      const keys = Object.keys(trendwarrantdata);
      keys.forEach((key) => {
        selectedNew[key] = trendwarrantdata[key];
      });
    }
    if (ric in staticData) {
      const selectordataAll = staticData[ric];
      if (type in selectordataAll) {
        warrantdata = selectordataAll[type];
      }
    }
    if (selectedNew === null) {
      this.setState({ warrantdata, isLoadingWarrant });
    } else {
      this.setState({ warrantdata, isLoadingWarrant, selected: selectedNew });
    }
  }

  onFetchWarrantData(ric, type, trendwarrantdata = null) {

    if (MQSGUtil.isUsingStaticData("WarrantSelector")) {
      setTimeout(() => {
        this.onFetchStaticWarrantData(ric, type, trendwarrantdata);
      }, 100); // pretend fetch
      return;
    }
    this.setState({ type });
    if (this.isHome()) {
      type = "CALL"
    }
    const isLoadingWarrant = false;
    let warrantdata = null;
    let selectedNew = null;
    if (trendwarrantdata !== null) {
      selectedNew = { ...this.state.selected };
      const keys = Object.keys(trendwarrantdata);
      keys.forEach((key) => {
        selectedNew[key] = trendwarrantdata[key];
      });
    }
    var selectS = { ...this.state.selected };
    selectS.tprices = null;
    selectS.pricein = null;
    selectS.weeks = 0;
    selectS.weeksin = null;
    selectS.targets = 0.1;
    selectS.first = true;
    if (this.props.location && this.props.location.state && this.props.location.state.page) {
      selectS.tprices = this.props.location.state.selected.tprices;
      selectS.weeks = this.props.location.state.selected.weeks;
      selectS.targets = this.props.location.state.selected.targets;
      this.props.history.replace({ page: null, selected: null }, "/tools/selector")
    }
    const url =
      MQSGUtil.getAPIBasePath() +
      "/MarketDataJSON?type=picker&ric=" +
      ric +
      "&type2=" +
      type;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          warrantdata = result;
          // var BIDS = [];
          // for (var i = 0; i < warrantdata.data.length; i++) {
          //   BIDS.push(warrantdata.data[i].BID);
          // }
          // selectS.BIDS = BIDS;
          const logic = new SelectorLogic();
          var BIDS = [];
          warrantdata.data.forEach((rowdata, index) => {
            const caldata = logic.calCData(rowdata, selectS);
            BIDS.push(caldata.tp)
          })
          selectS.BIDS = BIDS;
          if (selectedNew === null) {
            this.setState({ warrantdata, isLoadingWarrant, selected: selectS, isShowTable: true });
          } else {
            this.setState({
              warrantdata,
              isLoadingWarrant,
              selected: selectedNew,
              isShowTable: true
            });
          }
        },
        (error) => {
          if (selectedNew === null) {
            this.setState({ warrantdata, isLoadingWarrant });
          } else {
            this.setState({
              warrantdata,
              isLoadingWarrant,
              selected: selectedNew,
              isShowTable: true
            });
          }
        }
      );
  }

  initSymbol(symbolsdata) {
    if (this.state.selected.ric === null) {
      const symbols = symbolsdata.symbols;
      if (location.pathname.indexOf('SiMSCI') != -1) {
        if (symbols.length > 0) {
          for (var i = 0; i < symbols.length; i++) {
            if (symbols[i].symbol == 'SiMSCI') {
              return symbols[i];
            }
          }
        }
      } else {
        if (symbols.length > 0) {
          if (this.props.location && this.props.location.state && this.props.location.state.selected) {
            var values = symbols.filter((el) => {
              return el.name == this.props.location.state.selected.symbol.name
            })
            return values[0];
          }
          return symbols[0];
        }
      }
    }
    return null;
  }

  initTprices(symbol) {
    if (symbol !== null) {
      if ("TRDPRC_1" in symbol) {
        const lastprice = Number(symbol.TRDPRC_1).toFixed(2);
        const targets = 0.1;
        return lastprice * (1.0 + targets);
      }
    }
    return 0;
  }

  onRicChange(symbol) {
    var dom = document.querySelector('.css-1pahdxg-control')
    if (dom) {
      dom.className = 'css-yk16xz-control'
    }
    this.setState({
      putOrCall: null,
      warrantdata: undefined
    })
    if ("ric" in symbol && symbol.ric !== this.state.selected.ric) {
      const ric = symbol.ric;
      const type = '';
      this.onFetchChartData(ric, this.state.selected.period);
      const selectedNew = { ...this.state.selected };
      selectedNew["ric"] = ric;
      selectedNew["weeks"] = 0;
      selectedNew["symbol"] = symbol;
      selectedNew["type"] = type;
      selectedNew["tprices"] = null;
      selectedNew["targets"] = 0.1;
      selectedNew["targets"] = 0.1;
      selectedNew["lastPriceModel"] = symbol.TRDPRC_1;
      this.setState({ selected: selectedNew, type: '' });
      // , isLoadingWarrant: true
      const trendwarrantdata = {
        weeks: this.isHome() ? 4 : 0,
        tprices: this.initTprices(symbol),
        pricein: null,
        weeksin: null,
        targets: 0.1,
        ric,
        symbol,
        type,
      };
      // if (type === "call") {
      //   return;
      // }
      if (this.isHome()) {
        this.onFetchWarrantData(ric, 'CALL', trendwarrantdata);
      }
    }
  }

  onTargetPricePercentChange(step) {
    const selectedNew = { ...this.state.selected };
    if (this.state.selected.tprices == null) {
      if (step < 0) {
        var newValue = -this.state.selected.targets + step;
      } else {
        var newValue = this.state.selected.targets + step;
      }
    } else {
      var newValue = this.state.selected.targets + step;
    }
    var isSubtract = null;
    if (step == 0.005) {
      isSubtract = 1
    } else {
      isSubtract = 2
    }
    const result = this.logic.calculateTargetPrice(
      this.state.selected,
      newValue,
      this.getPriceIn(),
      false,
      isSubtract
    );
    if (result.targets !== null && result.tprices) {
      selectedNew["targets"] = Number(result.targets.toFixed(3));
      selectedNew["tprices"] = result.tprices;
    }
    selectedNew["pricein"] = null;
    this.setState({ selected: selectedNew });
  }

  onTargetPriceInputChange(pricein) {
    const selectedNew = { ...this.state.selected };
    selectedNew["pricein"] = pricein;
    this.setState({ selected: selectedNew });
  }
  onLastPriceInputChange(pricein) {
    const selectedNew = { ...this.state.selected };
    selectedNew["lastPrice"] = pricein;
    this.setState({ selected: selectedNew });
  }

  onTargetPriceKeyDownEnter() {
    const selectedNew = { ...this.state.selected };
    if (this.getPriceIn() !== null) {
      const result = this.logic.calculateTargetPrice(
        this.state.selected,
        this.state.selected.targets,
        this.getPriceIn()
      );
      if (result.targets !== null && result.tprices) {
        selectedNew["targets"] = Number(result.targets.toFixed(3));
        selectedNew["tprices"] = result.tprices;
      }
    }
    selectedNew["pricein"] = null;
    this.setState({ selected: selectedNew });
  }
  onLastPriceKeyDownEnter() {
    const selectedNew = { ...this.state.selected };
    if (this.getLastPriceIn() !== null) {
      const result = this.logic.calculateTargetPrice(
        this.state.selected,
        null,
        null,
        true
      );
      if (result.targets !== null && result.tprices) {
        selectedNew["targets"] = Number(result.targets.toFixed(3));
        // selectedNew["tprices"] = result.tprices;
      }
      if (selectedNew.BIDS != undefined) {
        const logic = new SelectorLogic();
        var tps = []
        this.state.warrantdata.data.forEach((rowdata, index) => {
          const caldata = logic.calCData(rowdata, selectedNew, this.getLastPriceIn());
          tps.push(caldata.tp)
        })
      }
      selectedNew["lastPriceModel"] = this.getLastPriceIn();
      selectedNew["BIDS"] = tps
    }
    selectedNew["lastPrice"] = null;
    // selectedNew["weeks"] = 0;
    // selectedNew["tprices"] = null;
    // selectedNew["targets"] = 0.1;

    this.setState({ selected: selectedNew });
  }

  onPeriodWeekChange(step) {
    const selectedNew = { ...this.state.selected };
    let newValue = this.state.selected.weeks + step;
    if (newValue >= 13) {
      newValue = 12;
    } else if (newValue <= 1) {
      newValue = 2;
    }
    selectedNew["weeks"] = newValue;
    selectedNew["weeksin"] = null;
    this.setState({ selected: selectedNew });
  }

  onPeriodWeekInputChange(weeksin) {
    const selectedNew = { ...this.state.selected };
    selectedNew["weeksin"] = weeksin;
    this.setState({ selected: selectedNew });
  }

  onPeriodWeekInputKeyDownEnter() {
    const selectedNew = { ...this.state.selected };
    let weeksin = Number(this.state.selected.weeksin);
    if (!isNaN(weeksin)) {
      if (weeksin >= 13) {
        weeksin = 12;
      } else if (weeksin <= 1) {
        weeksin = 2;
      }
      selectedNew["weeks"] = weeksin;
    }
    selectedNew["weeksin"] = null;
    this.setState({ selected: selectedNew });
  }

  onPutCallIconClick() {
    this.setState({ isLoadingWarrant: true });
    let trendwarrantdata = null;
    let type = "CALL";
    if (this.state.selected.type === type) {
      type = "PUT";
    }
    let newValue = this.state.selected.targets * -1.0;
    const result = this.logic.calculateTargetPrice(
      this.state.selected,
      newValue
    );
    if (result.targets !== null && result.tprices) {
      trendwarrantdata = {};
      trendwarrantdata["targets"] = Number(result.targets.toFixed(3));
      trendwarrantdata["tprices"] = result.tprices;
      trendwarrantdata["type"] = type;
    }
    this.onFetchWarrantData(this.state.selected.ric, type, trendwarrantdata);
  }

  onPeriodChange(period) {
    const ric = this.state.selected.ric;
    const selectedNew = { ...this.state.selected };
    selectedNew["period"] = period;
    this.setState({ selected: selectedNew, chartdata: null });
    this.onFetchChartData(ric, period);
  }

  getChartTitle() {
    let dsplyName = "";
    let symbol = "";
    let value = '';
    let special = '';
    if (this.state.selected.symbol !== null) {
      if ("dsply_name" in this.state.selected.symbol) {
        dsplyName = this.state.selected.symbol.dsply_name;
      }
      if ("symbol" in this.state.selected.symbol) {
        symbol = this.state.selected.symbol.symbol;
      }
      if ("value" in this.state.selected.symbol) {
        value = this.state.selected.symbol.value;
      }
      if ("special" in this.state.selected.symbol) {
        special = this.state.selected.symbol.special;
      }
    }
    if (
      symbol === "HSI" ||
      symbol === "N225" ||
      symbol === "S&P500" ||
      symbol === "STI" ||
      symbol === "HSTECH" ||
      symbol === 'SiMSCI' ||
      symbol === 'SEA' ||
      symbol === 'NDX' ||
      symbol === 'DJI'

    ) {
      return (
        <h2>
          <span className="dsply_name_ticker">{symbol + " " + dsplyName.slice(dsplyName.lastIndexOf(' ')).replace('TIMES', '')}</span> (
          <span className="chart_ticker">{symbol}</span>)
        </h2>
      );
      // }
      //  else if (
      //   symbol === 'Nadag 100' ||
      //   symbol === 'DOW JONES'
      // ) {
      //   return (
      //     <h2>
      //       <span className="dsply_name_ticker">{symbol + " " + dsplyName.slice(dsplyName.lastIndexOf(' ')).replace('TIMES', '')}</span> (
      //       <span className="chart_ticker">{symbol == 'Nadag 100' ? 'NDX' : 'DJI'}</span>)
      //     </h2>
      //   );
    } else {
      return (
        <h2>
          <span className="dsply_name_ticker">{symbol}</span> (
          <span className="chart_ticker">{value.slice(0, value.indexOf('.'))}</span>)
        </h2>
      );
    }
  }
  getHomeTitle() {
    let dsplyName = "";
    let symbol = "";
    let value = '';
    if (this.state.selected.symbol !== null) {
      if ("symbol" in this.state.selected.symbol) {
        symbol = this.state.selected.symbol.symbol;
      }
    }
    if (location.pathname.indexOf('SiMSCI') != -1) {
      return (
        <h2>
          <span className="dsply_name_ticker">SiMSCI </span>
        </h2>
      );
    } else {
      return (
        <h2>
          <span className="dsply_name_ticker">Hang Seng Index </span> (
          <span className="chart_ticker">{symbol}</span>)
        </h2>
      );
    }

  }
  getLastPriceIn() {
    if (this.state.selected !== null) {
      if ("lastPrice" in this.state.selected) {
        if (this.state.selected.lastPrice !== null) {
          var value = this.state.selected.lastPrice;
          if (value.indexOf(',') != -1) {
            var newValue = value.replace(/,/gi, '');
            if (newValue.indexOf('.00') != -1) {
              newValue = newValue.replace('.00', '');
            }
            return newValue
          }
          if (value.indexOf('$') != -1) {
            var newValue = value.slice(value.indexOf('$') + 1);
            if (newValue.indexOf('.00') != -1) {
              newValue = newValue.replace('.00', '');
            }
            return newValue
          }
          if (!isNaN(value)) {
            return value;
          }
        }
      }
    }
    return null;
  }
  getPriceIn() {
    if (this.state.selected !== null) {
      if ("pricein" in this.state.selected) {
        if (this.state.selected.pricein !== null) {
          var value = this.state.selected.pricein;
          if (value.indexOf(',') != -1) {
            var newValue = value.replace(/,/gi, '');
            if (newValue.indexOf('.00') != -1) {
              newValue = newValue.replace('.00', '');
            }
            return newValue
          }
          if (value.indexOf('$') != -1) {
            var newValue = value.slice(value.indexOf('$') + 1);
            if (newValue.indexOf('.00') != -1) {
              newValue = newValue.replace('.00', '');
            }
            return newValue
          }
          if (!isNaN(value)) {
            return value;
          }
        }
      }
    }
    return null;
  }

  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }
  currency(symbol) {
    const symbolSname = symbol.name;
    if (
      symbolSname === "BABA" ||
      symbolSname === "JD" ||
      symbolSname === "NETEASE" ||
      symbolSname === "TENCENT" ||
      symbolSname === "XIAOMI" ||
      symbolSname === "BYD" ||
      (symbol.ric && symbol.ric.endsWith(".HK"))
    ) {
      return "HK$";
    } else if (
      symbolSname === "HSI" ||
      symbolSname === "N225" ||
      symbolSname === "S&P500" ||
      symbolSname === "STI" ||
      symbolSname === 'HANG SENG TECH INDEX' ||
      symbolSname === 'SiMSCI' ||
      symbolSname === 'SEA' ||
      symbolSname === 'NDX' ||
      symbolSname === 'DJI'
    ) {
      return "";
    }
    // return "S$";
    return "S$";
  }
  getSymbolsValue() {
    if (this.state.symbolsdata !== null) {
      if (this.state.symbolsdata["symbols"].length > 0)
        var symbols = this.state.symbolsdata["symbols"];
      var index = [];
      var list = [];
      var symbs = []
      symbols.forEach((element) => {
        var diplayname = MQSGUtil.getUnderlyingTickerDisplayName(
          element.ric,
          element.symbol,
          false
        );
        if (element["symbol"] == 'NDX' || element["symbol"] == 'DJI') {

        } else {
          element["symbol"] = diplayname;
        }
        element.label =
          (element["symbol"] == 'BABA-SW' ? 'ALIBABA' : element["symbol"]) + ' ' +
          this.currency(element) +
          element["TRDPRC_1"] +
          " (" +
          (element["PCTCHNG"] < 0
            ? element["PCTCHNG"]
            : "+" + element["PCTCHNG"]) +
          "%)";
        element.value = element.ric;
        if (
          element.symbol == "HSI" ||
          element.symbol == "N225" ||
          element.symbol == "S&P500" ||
          element.symbol == "STI" ||
          element.symbol == 'HSTECH' ||
          element.symbol === 'SiMSCI'
        ) {
          index.push(element)
        } else {
          list.push(element)
        }
      });
      list.sort((a, b) => a.symbol.localeCompare(b.symbol))
      symbs = [...index, ...list]
    }
    return symbs;
  }
  reset() {
    const selectedNew = { ...this.state.selected };
    selectedNew["lastPrice"] = selectedNew.symbol['TRDPRC_1'] + '';
    this.setState({ selected: selectedNew }, () => {
      this.onLastPriceKeyDownEnter()
    });

  }
  renderHome() {
    return (
      <div id="dwselector">
        <div className="page-header">
          <h2 className="small">Warrant selector</h2>
        </div>
        {/* <div style={{position: "relative"}}>
                        <div id="underlying_list" className="underlying_list_x_scroll" >
                            <Symbol symbols={this.state.symbolsdata} page={this.props.page}
                                    onRicChange={this.onRicChange.bind(this)}/>
                        </div>                        
                        <div>
                            <div className="swiper-botton hidden-xs">
                                <span className="gallery-nav left"  onClick={(e)=>{this.previous(e)}}></span>
                                <span className="gallery-nav right" onClick={(e)=>{this.next(e)}}></span>
                            </div>
                        </div>
                    </div> */}
        <div>
          See how the warrant performs with different holding periods based on
          your target price of the selected underlying
          <br />
          <br />
          Click on “<strong>View More</strong>” below for more underlyings
        </div>
        <div>
          {/* {this.getChartTitle()} */}
          {this.getHomeTitle()}
          {/* <table id="periodtab" className="ctab">
            <tbody>
              <tr>
                <td
                  className={classNames(
                    { active: this.state.selected.period === "D" },
                    "ctab-item"
                  )}
                  onClick={() => this.onPeriodChange("D")}
                >
                  1D
                </td>
                <td className="ctab-item-space">&nbsp;</td>
                <td
                  className={classNames(
                    { active: this.state.selected.period === "W" },
                    "ctab-item"
                  )}
                  onClick={() => this.onPeriodChange("W")}
                >
                  1W
                </td>
                <td className="ctab-item-space">&nbsp;</td>
                <td
                  className={classNames(
                    { active: this.state.selected.period === "M" },
                    "ctab-item"
                  )}
                  onClick={() => this.onPeriodChange("M")}
                >
                  1M
                </td>
                <td className="ctab-item-space">&nbsp;</td>
                <td
                  className={classNames(
                    { active: this.state.selected.period === "Y" },
                    "ctab-item"
                  )}
                  onClick={() => this.onPeriodChange("Y")}
                >
                  MAX
                </td>
              </tr>
            </tbody>
          </table> */}
          <div id="chartbox" style={{ width: "100%", height: "370px" }}>
            <Chart
              ric={this.state.selected.ric}
              period={this.state.selected.period}
              data={this.state.chartdata}
            />
          </div>
        </div>
        <PriceArea
          page="home"
          selected={this.state.selected}
          onPeriodWeekChange={this.onPeriodWeekChange.bind(this)}
          onPutCallIconClick={this.onPutCallIconClick.bind(this)}
          onTargetPricePercentChange={this.onTargetPricePercentChange.bind(
            this
          )}
          reset={() => this.reset()}
          onTargetPriceInputChange={(pricein) =>
            this.onTargetPriceInputChange(pricein)
          }
          onLastPriceInputChange={(pricein) =>
            this.onLastPriceInputChange(pricein)
          }
          onTargetPriceKeyDownEnter={() => this.onTargetPriceKeyDownEnter()}
          onLastPriceKeyDownEnter={() => this.onLastPriceKeyDownEnter()}
          onPeriodWeekInputChange={(weeksin) =>
            this.onPeriodWeekInputChange(weeksin)
          }
          onPeriodWeekInputKeyDownEnter={() =>
            this.onPeriodWeekInputKeyDownEnter()
          }
          warrantdata={this.state.warrantdata}
          isLoading={this.state.isLoadingWarrant}
        />
        <div className="section-btn-wapper">
          <Link
            to={{ pathname: "/tools/selector", state: { page: 'fromHomePage', selected: this.state.selected } }}
            type="button"
            className="btn btn-primary btn_viewmore"
          >
            View more
          </Link>
        </div>
        {/* <TrendWarrant
          selected={this.state.selected}
          isLoading={this.state.isLoadingWarrant}
          warrantdata={this.state.warrantdata}
        /> */}
      </div>
    );
  }
  setCallOrPut(val) {
    window.scrollTo(0, 550)
    this.setState({ putOrCall: val, isLoadingWarrant: true });
    this.onFetchWarrantData(this.state.selected.ric, val);
  }
  getCurrency(selected) {
    let currency = "";
    if ("symbol" in selected && selected.symbol !== null) {
      const logic = new SelectorLogic();
      return logic.currency(selected.symbol);
    }
    return currency;
  }
  getLastPrice(selected, currency) {
    if (selected !== null) {
      if ("symbol" in selected && selected.symbol !== null) {
        if ("TRDPRC_1" in selected.symbol) {
          return currency + Number(selected.symbol.TRDPRC_1).toFixed(2);
        }
      }
    }
    return "-";
  }
  childMethod() {
    if (this.refs.child) {
      this.refs.child.onClickTradeWarrantsHere()
    }
  }
  renderPage(props) {
    const selected = this.state.selected;
    const currency = this.getCurrency(selected);
    const lastprice = this.getLastPrice(selected, currency);
    return (
      <div id="dwselector" className="pageobj" onClick={() => this.childMethod()}>
        <BreadcrumbBar
          link="/home"
          group="Warrant tools"
          title="Warrant selector"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <PageHeader title="Warrant selector" ref="child" />
                <p>
                  Use the Warrant Selector to view the current trending warrant! Also see how the warrant perform with different holding periods based on your target price of the selected underlying.
                </p>

                <div>
                  {/* <h1>Input field</h1>
                  <ol>
                    <li>Select underlying</li>
                    <li>
                      Either manually enter the price and holding period you
                      expect. or use the +/- sign
                    </li>
                  </ol> */}
                </div>

                <div className="row main_row">
                  <div className="col-sm-12 col-xs-12 " style={{ padding: 0 }}>
                    {/* <div className="underlying_list" id="underlying_list"> */}
                    {/* <Symbol
                        symbols={this.state.symbolsdata}
                        onRicChange={this.onRicChange.bind(this)}
                      /> */}
                    {/* </div> */}
                    {/* <div className="visible-xs">
                                            <div className="swiper_left glyphicon glyphicon-chevron-left " style={{display: 'none'}}></div>
                                            <div className="swiper_right glyphicon glyphicon-chevron-right"></div>
                                        </div>
                                        <div className="visible-sm">
                                            <div className="swiper_up glyphicon glyphicon-chevron-up" style={{display: 'none'}}></div>
                                            <div className="swiper_down glyphicon glyphicon-chevron-down"></div>
                                        </div> */}
                    {/* isDisabled={this.props.disabled}
                    <Select
                      placeholder="Select underlying"
                      options={this.getSymbolsValue()}
                      onChange={this.onRicChange.bind(this)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          textAlign: "center",
                        }),
                      }}
                      
                    /> */}


                    <Select
                      placeholder="Select underlying"
                      onChange={this.onRicChange.bind(this)}
                      options={this.getSymbolsValue()}
                      className="center"
                      formatOptionLabel={(data) => {
                        return (
                          <div
                            key={data.symbol}
                            className="selectSption"
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              padding: '5px 0px'
                            }}
                          >
                            <span style={{ color: "#0675C2" }}>{data.symbol} </span>
                            <span>
                              {this.currency(data)}
                              {data.TRDPRC_1}{" "}
                            </span>
                            <span
                              style={{
                                color: data["PCTCHNG"] > 0 ? "#6CC263" : "red",
                              }}
                            >
                              (
                              {data.PCTCHNG > 0 ? "+" + data.PCTCHNG : data.PCTCHNG}%)
                            </span>
                          </div>
                        );
                      }}
                      isSearchable={true}
                      // onRicChange={this.onRicChange.bind(this)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          // textAlign: "center",
                          cursor: 'pointer',
                          backgroundColor: '#FFFFFF',
                          padding: '0',
                          color: '#000',
                          // fontSize: '14px',
                          // ':hover': {
                          //   // backgroundColor: '#1A73E8',
                          // },
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          justifyContent: 'center',
                          marginLeft: '35px'
                        })
                      }}
                    />
                  </div>
                  <div
                    className="instruction1"
                    style={{
                      textAlign: "center",
                      display:
                        this.state.selected.symbol == null ? "block" : "none",
                    }}
                  >
                    Start by <strong>selecting an underlying</strong> to view
                    <br />
                    its trending warrants and their estimated
                    <br />
                    gross returns.

                  </div>
                  {/* ------- */}
                  <div
                    className="select_area"
                    style={{
                      marginTop: "48px",
                      display:
                        this.state.selected.ric == null ? "none" : "block",
                    }}
                  >
                    <div
                      style={{
                        width: "105px",
                        textAlign: "center",
                        display: this.isHome() ? "none" : "block",
                      }}
                    >
                      Select
                    </div>
                    <div className="select_img_row">
                      <div
                        className="select_img"
                        style={{ display: this.isHome() ? "none" : "flex" }}
                      >
                        <img
                          className="typeSelect call_type"
                          src="/img/home/call.png"
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                            display: this.state.putOrCall == 'CALL' || this.state.putOrCall == null ? 'block' : 'none'
                          }}
                          onClick={() => {
                            this.setCallOrPut("CALL");
                          }}
                        />
                        <img
                          className="typeSelect call_type"
                          src="/img/home/call_grey.png"
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                            display: this.state.putOrCall == 'PUT' && this.state.putOrCall != null ? 'block' : 'none'
                          }}
                          onClick={() => {
                            this.setCallOrPut("CALL");
                          }}
                        />
                        <img
                          className="typeSelect call_type"
                          src="/img/home/put_grey.png"
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            marginLeft: "5px",
                            cursor: "pointer",
                            display: this.state.putOrCall == 'CALL' && this.state.putOrCall != null ? 'block' : 'none'
                          }}
                          onClick={() => {
                            this.setCallOrPut("PUT");
                          }}
                        />
                        <img
                          src="/img/home/put.png"
                          alt=""
                          className="typeSelect put_type"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginLeft: "5px",
                            cursor: "pointer",
                            display: this.state.putOrCall == 'PUT' || this.state.putOrCall == null ? 'block' : 'none'
                          }}
                          onClick={() => {
                            this.setCallOrPut("PUT");
                          }}
                        />
                      </div>

                      <div
                        className="instruction2"
                        style={{
                          display:
                            this.state.putOrCall == null ? "block" : "none",
                        }}
                      >
                        Choose between a <strong>call</strong> or{" "}
                        <strong>put</strong>
                      </div>
                      <div
                        className="select_img_des instruction3"
                        style={{
                          display:
                            this.state.putOrCall == null ? "none" : "block",
                        }}
                      >
                        Type in or adjust the target price and holding period
                        with the
                        <img
                          src="/img/home/plicon.png"
                          alt=""
                          style={{ width: "13px", margin: " 0px 5px" }}
                        />
                        icons below
                      </div>
                      {/* <div
              className="select_img_des instruction3"
              style={{ display: this.isHome() ? "none" : "block" }}
            >
              Type in or adjust the target price and holding period with the
              <img
                src="/img/home/plicon.png"
                alt=""
                style={{ width: "13px", margin: " 0px 5px" }}
              />
              icons below
            </div> */}
                    </div>
                  </div>
                  {/* ------ */}
                  <div
                    className="col-xs-12 col-sm-12"
                    style={{
                      display:
                        this.state.selected.symbol == null ? "none" : "block",
                    }}
                  >
                    {this.getChartTitle()}
                    <table id="periodtab" className="ctab">
                      <tbody>
                        <tr>
                          <td
                            className={classNames(
                              { active: this.state.selected.period === "D" },
                              "ctab-item"
                            )}
                            onClick={() => this.onPeriodChange("D")}
                          >
                            1D
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              { active: this.state.selected.period === "W" },
                              "ctab-item"
                            )}
                            onClick={() => this.onPeriodChange("W")}
                          >
                            1W
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              { active: this.state.selected.period === "M" },
                              "ctab-item"
                            )}
                            onClick={() => this.onPeriodChange("M")}
                          >
                            1M
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              { active: this.state.selected.period === "Y" },
                              "ctab-item"
                            )}
                            onClick={() => this.onPeriodChange("Y")}
                          >
                            MAX
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      id="chartbox"
                      style={{ width: "100%", height: "370px" }}
                    >
                      <Chart
                        ric={this.state.selected.ric}
                        period={this.state.selected.period}
                        data={this.state.chartdata}
                      />
                    </div>
                    {/* <div style={{}} className="price">
                      Last price
                      <span> {lastprice}</span>
                    </div> */}
                    <PriceArea
                      type={this.state.type}
                      isShowTable={this.state.isShowTable}
                      selected={this.state.selected}
                      onPeriodWeekChange={this.onPeriodWeekChange.bind(this)}
                      onPutCallIconClick={this.onPutCallIconClick.bind(this)}
                      onTargetPricePercentChange={this.onTargetPricePercentChange.bind(
                        this
                      )}
                      reset={() => this.reset()}
                      isLoading={this.state.isLoadingWarrant}
                      onTargetPriceInputChange={(pricein) =>
                        this.onTargetPriceInputChange(pricein)
                      }
                      onLastPriceInputChange={(pricein) =>
                        this.onLastPriceInputChange(pricein)
                      }
                      onTargetPriceKeyDownEnter={() =>
                        this.onTargetPriceKeyDownEnter()
                      }
                      onLastPriceKeyDownEnter={() =>
                        this.onLastPriceKeyDownEnter()
                      }
                      onPeriodWeekInputChange={(weeksin) =>
                        this.onPeriodWeekInputChange(weeksin)
                      }
                      onPeriodWeekInputKeyDownEnter={() =>
                        this.onPeriodWeekInputKeyDownEnter()
                      }
                      getTableData={(ric, type, trendwarrantdata = null) => {
                        this.onFetchWarrantData(
                          ric,
                          type,
                          (trendwarrantdata = null)
                        );
                      }}
                      warrantdata={this.state.warrantdata}
                    />
                  </div>
                </div>
                {/* <TrendWarrant
                  selected={this.state.selected}
                  isLoading={this.state.isLoadingWarrant}
                  warrantdata={this.state.warrantdata}
                /> */}
              </div>
            </div>
            <div className="col-md-4 hidden-xs hidden-sm">
              <AdvertBox />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.isHome()) {
      return this.renderHome();
    } else {
      return this.renderPage();
    }
  }
}

class SelectorLogic {
  getData(data, fieldName, toNumber = false) {
    if (fieldName in data) {
      const value = data[fieldName];
      if (toNumber) {
        if (typeof value === "string") {
          return Number(value.replace(/,/g, ""));
        }
      }
      return value;
    }
    return null;
  }

  currency(symbol) {
    const symbolSname = symbol.name;
    if (
      symbolSname === "BABA" ||
      symbolSname === "JD" ||
      symbolSname === "NETEASE" ||
      symbolSname === "TENCENT" ||
      symbolSname === "XIAOMI" ||
      symbolSname === "BYD" ||
      symbol.ric.endsWith(".HK")
    ) {
      return "HK$";
    } else if (
      symbolSname === "HSI" ||
      symbolSname === "N225" ||
      symbolSname === "S&P500" ||
      symbolSname === "STI" ||
      symbolSname === 'HANG SENG TECH INDEX' ||
      symbolSname === 'SiMSCI' ||
      symbolSname === 'SEA' ||
      symbolSname === 'NASDAQ-100' ||
      symbolSname === 'DOW JONES'
    ) {
      return "";
    }
    // return "S$";
    return "$";

  }

  removeLeadingZeroValue(dataarr) {
    let firstNonZeroValue = "0.00";
    let firstNonZeroIndex = 0;
    if (dataarr.length > 0) {
      for (let i = 0; i < dataarr.length; i++) {
        let item = dataarr[i].split(";");
        let value = item[1];
        if (value && value.length > 1 && Number(value) !== 0) {
          firstNonZeroIndex = i;
          firstNonZeroValue = value;
          break;
        }
      }
      if (firstNonZeroIndex > 0) {
        for (let j = 0; j < firstNonZeroIndex; j++) {
          var oldItem = dataarr[j].split(";");
          dataarr[j] = oldItem[0] + ";" + firstNonZeroValue;
        }
      }
      return dataarr;
    }
    return null;
  }

  // preprocess(data, period) {
  //     let dataarr = data.trim().split("=");
  //     let datapoints = dataarr[0];
  //     if (dataarr.length > 1) {
  //         datapoints = dataarr[1];
  //     }
  //     return this.removeLeadingZeroValue(datapoints.split("|"));
  // }

  preprocess(data, period) {
    let dataarr = data.trim().split("=");
    if (period !== "D") {
      if (dataarr.length > 0) {
        dataarr = dataarr.slice(1, dataarr.length);
      }
    }
    return dataarr;
  }

  handleDatetimeString(datetimeString, ric, period) {
    var hr = 16;
    var min = 59;
    if (ric.endsWith(".HK")) {
      hr = 15;
      min = 59;
    } else if (ric.startsWith("HSI")) {
      hr = 16;
      min = 29;
    }

    let formattedDatatimeString = datetimeString;
    const d = new Date(datetimeString);
    if (period === "Y") {
      // Y case: 2020-02-17
      formattedDatatimeString =
        String("00" + d.getDate()).slice(-2) +
        "/" +
        String("00" + (d.getMonth() + 1)).slice(-2) +
        "/" +
        String("00" + d.getFullYear()).slice(-2);
    } else if (period !== "D") {
      // M case: 2021-01-18
      formattedDatatimeString =
        String("00" + d.getDate()).slice(-2) +
        "/" +
        String("00" + (d.getMonth() + 1)).slice(-2);
    } else {
      // D case: 09:00
      const time = datetimeString.split(":");
      if (
        parseInt(time[0]) > hr ||
        (parseInt(time[0]) === hr && parseInt(time[1]) > min)
      ) {
        formattedDatatimeString = null;
      }
    }
    return formattedDatatimeString;
  }

  handleMinMaxOpen(minMaxOpen, value) {
    minMaxOpen.gmin =
      minMaxOpen.gmin === 0 ? value : Math.min(minMaxOpen.gmin, value);
    minMaxOpen.gmax =
      minMaxOpen.gmax === 0 ? value : Math.max(minMaxOpen.gmax, value);
    minMaxOpen.gopen = minMaxOpen.gopen === 0 ? value : minMaxOpen.gopen;
  }

  parseAxisData(datapoints, ric, period, index, dataset) {
    const minMaxOpen = { gmin: 0, gmax: 0, gopen: 0 };
    datapoints.forEach((element) => {
      const dt = element.split(";");
      let dp = {};
      if (dt.length === 2) {
        const datetimeString = dt[0];
        const value = dt[1];
        const columnLabel = "y";
        if (datetimeString in dataset) {
          dp = dataset[datetimeString];
        } else {
          // dp["x"] = this.handleDatetimeString(datetimeString, ric, period);
          if (period === "D") {
            var time = "";
            var date = new Date();
            var YY = date.getFullYear() + "-";
            var MM =
              (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
            var DD =
              date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hh =
              (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
              ":";
            var mm =
              (date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes()) + ":";
            var ss =
              date.getSeconds() < 10
                ? "0" + date.getSeconds()
                : date.getSeconds();
            time = YY + MM + DD + " ";
            // dp["x"] = new Date(time + datetimeString).getTime() + 28800000;
            dp["x"] = new Date(time + datetimeString).getTime();
          } else {
            // dp["x"] = new Date(datetimeString).getTime() + 3600000 + 28800000;
            dp["x"] = new Date(datetimeString).getTime();
          }
        }
        dp[columnLabel] = null;
        if (value !== "" && !isNaN(value) && value >= 0) {
          this.handleMinMaxOpen(minMaxOpen, value);
          dp[columnLabel] = Number(value);
        }
        if (dp["x"] !== null && dp[columnLabel] !== null) {
          dataset[datetimeString] = dp;
        }
      }
    });
    minMaxOpen.gmin = minMaxOpen.gmin <= 0 ? 0 : minMaxOpen.gmin;
    const valueAxes = {
      maximum: minMaxOpen.gmax,
      minimum: minMaxOpen.gmin,
      open: minMaxOpen.gopen,
    };
    return valueAxes;
  }

  parseChartData(data, ric, period) {
    let result = null;
    if (data !== null) {
      const datasetorg = this.preprocess(data, period);
      if (datasetorg !== null) {
        result = {
          dataProvider: [],
          valueAxes: [],
        };
        const dataset = {};
        datasetorg.forEach((datasubset, index) => {
          const datapoints = this.removeLeadingZeroValue(datasubset.split("|"));
          const valueAxes = this.parseAxisData(
            datapoints,
            ric,
            period,
            index,
            dataset
          );

          if (valueAxes !== null) {
            result.valueAxes.push(valueAxes);
          }
        });
        const datetime = Object.keys(dataset);
        datetime.sort();
        for (var i = 0; i < datetime.length; i++) {
          result.dataProvider.push(dataset[datetime[i]]);
        }
      }
    }
    return result;
  }
  getLastPriceIn(val) {
    if (val !== null) {
      var value = val;
      if (value.indexOf(',') != -1) {
        var newValue = value.replace(/,/gi, '');
        if (newValue.indexOf('.00') != -1) {
          newValue = newValue.replace('.00', '');
        }
        return newValue
      }
      if (value.indexOf('$') != -1) {
        var newValue = value.slice(value.indexOf('$') + 1);
        if (newValue.indexOf('.00') != -1) {
          newValue = newValue.replace('.00', '');
        }
        return newValue
      }
      if (!isNaN(value)) {
        return value;
      }
    }
    return null;
  }
  calculateTargetPrice(selected, targets, pricein = null, isClick, isSubtract) {
    const result = { tprices: null, targets: null };
    if (selected !== null) {
      let lastprice = null;
      // val_last_price
      if (isClick) {
        if ("lastPrice" in selected && selected.lastPrice != null) {
          if ("lastPrice" in selected) {
            lastprice = this.getLastPriceIn(selected.lastPrice)
          }
        } else {
          if ("lastPriceModel" in selected) {
            lastprice = Number(selected.lastPriceModel).toFixed(2);
          }
        }

      } else {
        if ("lastPriceModel" in selected && selected.lastPriceModel !== null) {
          if ("lastPriceModel" in selected) {
            lastprice = Number(selected.lastPriceModel).toFixed(2);
          }
        }
      }
      if (lastprice !== null) {
        // user input target price
        // need to re-calculate the targets
        if (pricein !== null) {
          result.targets = (pricein - lastprice) / lastprice;
        } else if (targets !== null) {
          result.targets = targets;
        }
        result.tprices = (lastprice * (1.0 + result.targets)).toFixed(2);
      }
      if (isClick) {
        result.targets = (selected.tprices - lastprice) / lastprice;
        result.tprices = (lastprice * (1.0 + result.targets)).toFixed(2);
      } else {
        let rangeCheck = Number(result.targets.toFixed(3));
        if (rangeCheck > 0.35) {
          if (isSubtract == 1) {
            result.targets = (selected.tprices - lastprice) / lastprice;
            result.tprices = selected.tprices;
          } else {
            result.targets = 0.35;
            result.tprices = (lastprice * (1.0 + result.targets)).toFixed(2);
          }
        } else if (rangeCheck < -0.35) {
          if (isSubtract == 1) {
            result.targets = 0.35;
            result.tprices = (lastprice * (1.0 + result.targets)).toFixed(2);
          } else {
            result.targets = (selected.tprices - lastprice) / lastprice;
            result.tprices = selected.tprices;
          }
          // result.targets = 0.35;
          // result.tprices = selected.tprices;
        } else {
          result.tprices = (lastprice * (1.0 + result.targets)).toFixed(2);
        }

      }

    }
    return result;
  }

  caldateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  sign(x) {
    return x ? (x < 0 ? -1 : 1) : 0;
  }

  calCND(x) {
    var a1 = 0.254829592;
    var a2 = 0.284496736;
    var a3 = 1.421413741;
    var a4 = 1.453152027;
    var a5 = 1.061405429;
    var p = 0.3275911;
    var t = 1 / (1 + (p * Math.abs(x)) / Math.sqrt(2));
    var value =
      (1 / 2) *
      (1 +
        this.sign(x) *
        (1 -
          (a1 * t -
            a2 * Math.pow(t, 2) +
            a3 * Math.pow(t, 3) -
            a4 * Math.pow(t, 4) +
            a5 * Math.pow(t, 5)) *
          Math.exp((-x * x) / 2)));
    return value;
  }

  calwp(
    type,
    up,
    sep,
    tddate,
    maturity,
    iv,
    div_yield,
    conv_ratio,
    fx,
    wp,
    tprices
  ) {
    const T = this.caldateDiffInDays(tddate, maturity) / 365;
    const r = 0.015; //that.riskFreeRate;
    let S = (tprices * fx).toFixed(2);
    let X = sep * fx;
    S = Number(S).toPrecision(10); //underlying price
    X = Number(X).toPrecision(10); //exercise price
    const sigma = iv / 100;
    const wps = conv_ratio;
    const F = S * Math.exp((r - div_yield) * T);
    let d1 =
      (Math.log(F / X) + ((sigma * sigma) / 2) * T) / (sigma * Math.sqrt(T));
    d1 = Number(d1).toPrecision(10);
    let d2 = d1 - sigma * Math.sqrt(T);
    d2 = Number(d2).toPrecision(10);
    let c = 0;
    if (type === "CALL") {
      c = Math.exp(-r * T) * (F * this.calCND(d1) - X * this.calCND(d2));
    } else if (type === "PUT") {
      c = Math.exp(-r * T) * (X * this.calCND(-d2) - F * this.calCND(-d1));
    }
    c = Number(c).toPrecision(10);
    return c / wps;
  }
  calCData(data, selected, val) {
    const result = {
      tp: -1,
      tc: -1,
      wp: -1,
      wc: -1,
      hp: -1,
      hc: -1,
    };
    const type = this.getData(data, "type");
    const up = this.getData(data, "underlying_price_cal", true);
    const sep = this.getData(data, "exercise_price_cal", true);
    const div_yield = this.getData(data, "div_yield", true);
    const conv_ratio = this.getData(data, "conv_ratio", true);
    const fx = this.getData(data, "r_hst_close", true);
    const wp = this.getData(data, "warrant_price", true);
    const iv = this.getData(data, "implied_volatility", true);
    const maturityInMS = Date.parse(this.getData(data, "maturity")); // if no data => NaN
    if (val) {
      var tprices = val
    } else {
      var tprices = this.getData(selected, "lastPriceModel", true);
    }

    const weeks = this.getData(selected, "weeks", true);
    if (
      type === null ||
      up === null ||
      sep === null ||
      div_yield === null ||
      conv_ratio === null ||
      fx === null ||
      wp === null ||
      iv === null ||
      isNaN(maturityInMS) ||
      tprices === null ||
      weeks === null
    ) {
      return { tp: "-", tc: "-", wp: "-", wc: "-", hp: "-", hc: "-" };
    }

    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const currency = "S$";
    const tddateInMS = Date.now();
    const maturity = new Date(maturityInMS);
    let wpice = null;
    let TDay = (maturityInMS - tddateInMS) / MS_PER_DAY;
    if (TDay > 0) {
      // wpice = this.calwp(type, up, sep, TDay, iv, div_yield / 100, conv_ratio, fx, wp, tprices);
      const tddate = new Date(tddateInMS);
      wpice = this.calwp(
        type,
        up,
        sep,
        tddate,
        maturity,
        iv,
        div_yield / 100,
        conv_ratio,
        fx,
        wp,
        tprices
      );
      wpice = Number(wpice).toFixed(3);
      const wchange = ((Number(wpice) - Number(wp)) / Number(wp)) * 100;
      wpice = Number(wpice) === 0 ? "-" : wpice;
      result.tp = wpice === "-" ? wpice : wpice;
      result.tc = wpice === "-" ? "-" : wchange.toFixed(1);
    } else {
      result.tp = "-1";
      result.tc = "-1";
    }
    return result;
  }
  calData(data, selected, bid) {
    const result = {
      tp: -1,
      tc: -1,
      wp: -1,
      wc: -1,
      hp: -1,
      hc: -1,
    };
    const type = this.getData(data, "type");
    const up = this.getData(selected, "lastPriceModel", true);
    const sep = this.getData(data, "exercise_price_cal", true);
    const div_yield = this.getData(data, "div_yield", true);
    const conv_ratio = this.getData(data, "conv_ratio", true);
    const fx = this.getData(data, "r_hst_close", true);
    // const wp = this.getData(data, "warrant_price", true);
    const wp = bid;
    const iv = this.getData(data, "implied_volatility", true);
    const maturityInMS = Date.parse(this.getData(data, "maturity")); // if no data => NaN
    const tprices = this.getData(selected, "tprices", true);
    const weeks = this.getData(selected, "weeks", true);
    if (
      type === null ||
      up === null ||
      sep === null ||
      div_yield === null ||
      conv_ratio === null ||
      fx === null ||
      wp === null ||
      iv === null ||
      isNaN(maturityInMS) ||
      tprices === null ||
      weeks === null
    ) {
      return { tp: "-", tc: "-", wp: "-", wc: "-", hp: "-", hc: "-" };
    }

    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const currency = "S$";
    const tddateInMS = Date.now();
    const maturity = new Date(maturityInMS);
    let wpice = null;
    let TDay = (maturityInMS - tddateInMS) / MS_PER_DAY;
    if (TDay > 0) {
      // wpice = this.calwp(type, up, sep, TDay, iv, div_yield / 100, conv_ratio, fx, wp, tprices);
      const tddate = new Date(tddateInMS);
      wpice = this.calwp(
        type,
        up,
        sep,
        tddate,
        maturity,
        iv,
        div_yield / 100,
        conv_ratio,
        fx,
        wp,
        tprices
      );
      wpice = Number(wpice).toFixed(3);
      const wchange = ((Number(wpice) - Number(wp)) / Number(wp)) * 100;
      wpice = Number(wpice) === 0 ? "-" : wpice;
      result.tp = wpice === "-" ? wpice : currency + wpice;
      result.tc = wpice === "-" ? "-" : wchange.toFixed(1);
    } else {
      result.tp = "-1";
      result.tc = "-1";
    }

    // tddate = Date.today();
    // tddate.setDate(tddate.getDate()+7);
    const oneWeekInMS = tddateInMS + MS_PER_DAY * 7;
    TDay = (maturityInMS - oneWeekInMS) / MS_PER_DAY;
    if (TDay > 0) {
      // wpice = this.calwp(type, up, sep, TDay, iv, div_yield / 100, conv_ratio, fx , wp, tprices);
      const tddate = new Date(oneWeekInMS);
      wpice = this.calwp(
        type,
        up,
        sep,
        tddate,
        maturity,
        iv,
        div_yield / 100,
        conv_ratio,
        fx,
        wp,
        tprices
      );
      wpice = Number(wpice).toFixed(3);
      const wchange = ((Number(wpice) - Number(wp)) / Number(wp)) * 100;
      wpice = Number(wpice) === 0 ? "-" : wpice;
      result.wp = wpice === "-" ? wpice : currency + wpice;
      result.wc = wpice === "-" ? "-" : wchange.toFixed(1);
    } else {
      result.wp = "-1";
      result.wc = "-1";
    }

    // tddate = Date.today();
    // tddate.setDate(tddate.getDate()+Number(that.weeks)*7);
    const userInputWeeks = tddateInMS + MS_PER_DAY * (weeks * 7);
    TDay = (maturityInMS - userInputWeeks) / MS_PER_DAY;
    if (TDay > 0) {
      // wpice = this.calwp(type, up, sep, TDay, iv, div_yield / 100, conv_ratio, fx , wp, tprices);
      const tddate = new Date(userInputWeeks);
      wpice = this.calwp(
        type,
        up,
        sep,
        tddate,
        maturity,
        iv,
        div_yield / 100,
        conv_ratio,
        fx,
        wp,
        tprices
      );
      wpice = Number(wpice).toFixed(3);
      const wchange = ((Number(wpice) - Number(wp)) / Number(wp)) * 100;
      wpice = Number(wpice) === 0 ? "-" : wpice;
      result.hp = wpice === "-" ? wpice : currency + wpice;
      result.hc = wpice === "-" ? "-" : wchange.toFixed(1);
    } else {
      result.hp = "-1";
      result.hc = "-1";
    }
    return result;
  }
}

class Symbol extends Component {
  constructor(props) {
    super(props);
    this.sorttedSymbols = null;
  }

  getData(data, fieldName, dp = null) {
    if (fieldName in data) {
      if (dp !== null) {
        return Number(data[fieldName]).toFixed(dp);
      }
      return data[fieldName];
    }
    return "-";
  }

  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }

  handleSymbol(symbols) {
    const symbolMapping = {
      A17U: "AREIT",
      AWX: "AEM",
      "BABA-SW": "ALIBABA",
      BN4: "KEPCORP",
      BVA: "TOPGLOVE",
      C31: "CAPLAND",
      C09: "CITYDEV",
      C52: "COMFORT",
      C6L: "SIA",
      D05: "DBS",
      F34: "WILMAR",
      G13: "GENTING",
      S08: "SINGPOST",
      BS6: "YANGZIJIANG",
      AIY: "IFAST",
      "JD-SW": "JD.COM",
      N2IU: "MAPLETREE",
      "NTES-S": "NETEASE",
      O39: "OCBC",
      S51: "SEMBMAR",
      S68: "SGX",
      TENCENT: "TENCENT",
      U11: "UOB",
      U14: "UOL",
      V03: "VENTURE",
      "XIAOMI-W": "XIAOMI",
      Z74: "SINGTEL",
      "Top Glove": "TOPGLOVE",
      "GEELY AUTO": "GEELY",
      "MEITUAN-W": "MEITUAN",
      "SUNNY OPTICAL": "SUNNY",
      "BYD COMPANY": "BYD",
    };
    symbols.forEach((symbol) => {
      symbol["sname"] = "";
      if ("symbol" in symbol) {
        symbol["sname"] = symbol.symbol;
        if (symbol.symbol in symbolMapping) {
          symbol["sname"] = symbolMapping[symbol.symbol];
        }
      }
    });
    if (symbols.length > 4) {
      const symbolsIndexes = symbols.slice(0, 4);
      const symbolsWithoutIndexes = symbols.slice(4);
      symbolsWithoutIndexes.sort((a, b) => {
        if (a.sname < b.sname) return -1;
        if (a.sname > b.sname) return 1;
        return 0;
      });
      return symbolsIndexes.concat(symbolsWithoutIndexes);
    }
    return symbols(0);
  }

  onRicChange(symbol) {
    this.props.onRicChange(symbol);
  }

  render() {
    const rows = [];
    if (this.props.symbols !== null) {
      if ("symbols" in this.props.symbols) {
        const symbols = this.props.symbols["symbols"];
        if (Array.isArray(symbols)) {
          this.sorttedSymbols = this.handleSymbol(symbols);
          this.sorttedSymbols.forEach((symbol, index) => {
            let className = "";
            if (index % 2 === 0) {
              className = "bgcolor-08";
            }
            const pct = this.getData(symbol, "PCTCHNG", 1);
            const trdprc = this.getData(symbol, "TRDPRC_1", 2);
            let updown = "";
            if (pct !== "-") {
              updown = Number(pct) > 0 ? "up" : "down";
            }
            const logic = new SelectorLogic();
            const currency = logic.currency(symbol);

            const td = (
              <td
                className="underlying_name"
                onClick={() => {
                  this.onRicChange(symbol);
                }}
              >
                {symbol.sname}
                <span className="uprice">
                  <br />
                  {currency + trdprc}
                </span>
                <span className={updown}>
                  <br />
                  {"(" + (updown === "up" ? "+" : "") + pct + "%)"}
                </span>
              </td>
            );
            const tr = (
              <tr
                key={symbol.symbol}
                ric={symbol.ric}
                symbol={symbol.symbol}
                dsply_name={symbol.ric}
                price={symbol.TRDPRC_1}
                className={className}
              >
                {td}
              </tr>
            );
            rows.push(tr);
          });
        }
        let tableID = "underlying_table";
        if (this.isHome()) {
          tableID = "underlying_table_home";
        }

        return (
          <table id={tableID}>
            <tbody>{rows}</tbody>
          </table>
        );
      }
    }
    return null;
  }
}

class PriceArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      lastPrice: ''
    };
  }
  getLastPrice(selected, currency) {
    if (selected !== null) {
      // if ("symbol" in selected && selected.symbol !== null) {
      //   if ("TRDPRC_1" in selected.symbol) {
      //     return currency + this.toThousands(selected.symbol.TRDPRC_1);
      //   }
      // }
      if ("lastPrice" in selected && selected.lastPrice !== null) {
        return selected.lastPrice;
      }
      if ("lastPriceModel" in selected && selected.lastPriceModel !== null) {
        return currency + this.toThousands(selected.lastPriceModel);
      }
      // if ("lastPriceModel" in selected && selected.lastPriceModel != null) {
      //   return currency + this.toThousands(selected.lastPriceModel);
      // }
    }
    return "";
  }
  toThousands(str) {
    var newStr = "";
    var count = 0;
    var str = str + '';
    if (str.indexOf(".") == -1) {
      for (var i = str.length - 1; i >= 0; i--) {
        if (count % 3 == 0 && count != 0) {
          newStr = str.charAt(i) + "," + newStr;
        } else {
          newStr = str.charAt(i) + newStr;
        }
        count++;
      }
      str = newStr + ".00"; //自动补小数点后两位
    }
    else {
      for (var i = str.indexOf(".") - 1; i >= 0; i--) {
        if (count % 3 == 0 && count != 0) {
          newStr = str.charAt(i) + "," + newStr;
        } else {
          newStr = str.charAt(i) + newStr; //逐个字符相接起来
        }
        count++;
      }
      str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
    }
    return str
  }
  getTargetPrice(selected, currency) {
    // target_price_num
    // output.setNumber(that.tprices);
    // output.setPlaces(2, true);
    // $("#target_price_num").val(that.d+output.toFormatted());
    if (selected !== null) {
      if ("pricein" in selected && selected.pricein !== null) {
        return selected.pricein;
      }
      if ("tprices" in selected && selected.tprices !== null) {
        return currency + this.toThousands(selected.tprices);
      }
    }
    return currency + "0.00";
  }

  getTargetPriceChange(selected, targetPrice) {
    // $("#target_price_c").html(
    // "<span class=\""+(that.targets>0?"up":"down")+"\">"+(that.targets>0?"+":"")+(that.targets*100).toFixed(1)+"%</span>");
    let className = "";
    let targetPerchange = "-";
    let sign = "";
    // if (selected !== null && targetPrice.indexOf('0.00') == -1) {
    if (selected !== null) {
      let targets = null;
      if ("targets" in selected) {
        targets = Number(selected.targets);
        if (targets > 0) {
          className = "up";
          sign = "+";
        } else if (targets < 0) {
          className = "down";
        }
        targetPerchange = sign + (targets * 100.0).toFixed(1) + "%";
      }
    }
    if (targetPerchange == '-') {
      targetPerchange = sign + '0.0' + "%";
    }

    return <span className={className}>{targetPerchange}</span>;
  }

  getTargetButtonStyle(selected, isPlus = true) {
    let style = { button: { backgroundColor: "#6CC263" }, icon: {} };
    if (!isPlus) {
      style = {
        button: { backgroundColor: "#7F7F7F" },
        icon: { display: "none" },
      };
    }
    if (selected !== null) {
      if ("targets" in selected) {
        const targets = Number(selected.targets);
        if (targets < 0) {
          style = {
            button: { backgroundColor: "#7F7F7F" },
            icon: { display: "none" },
          };
          if (!isPlus) {
            style = { button: { backgroundColor: "#C61C2D" }, icon: {} };
          }
        }
      }
    }
    return style;
  }

  getCurrency(selected) {
    let currency = "";
    if ("symbol" in selected && selected.symbol !== null) {
      const logic = new SelectorLogic();
      return logic.currency(selected.symbol);
    }
    return currency;
  }

  getPeriod(selected, targetPrice) {
    if (selected !== null && targetPrice !== "$0.00") {
      if ("weeksin" in selected && selected.weeksin !== null) {
        return selected.weeksin;
      }
      if ("weeks" in selected) {
        return selected.weeks;
      }
    }
    return "0";
  }

  onTargetPriceKeyDown(event) {
    if (event.key === "Enter" || event.type == "blur") {
      this.props.onTargetPriceKeyDownEnter();
    }
  }

  onTargetPriceInputChange(event) {
    this.props.onTargetPriceInputChange(event.target.value);
  }
  onLastPriceInputChange(event) {
    this.props.onLastPriceInputChange(event.target.value);
  }
  onLastPriceKeyDown(event) {
    if (event.key === "Enter" || event.type == "blur") {
      this.props.onLastPriceKeyDownEnter();
    }
  }
  onTargetPricePercentChange(step) {
    this.props.onTargetPricePercentChange(step);
  }

  onPeriodWeekInputKeyDown(event) {
    if (event.key === "Enter" || event.type == "blur") {
      this.props.onPeriodWeekInputKeyDownEnter();
    }
  }

  onPeriodWeekInputChange(event) {
    this.props.onPeriodWeekInputChange(event.target.value);
  }

  onPeriodWeekChange(step) {
    this.props.onPeriodWeekChange(step);
  }

  onPutCallIconClick() {
    this.props.onPutCallIconClick();
  }
  //table
  getData(data, fieldName, isReturnEmptyString = false) {
    if (data != null) {
      if (fieldName in data) {
        return data[fieldName];
      }
    }
    if (isReturnEmptyString) {
      return "";
    }
    return null;
  }

  getPeriodWeek(selected) {
    if (selected !== null) {
      if ("weeks" in selected) {
        return selected.weeks + " weeks";
      }
    }
    return "4 weeks";
  }

  getRows(warrantdata, selected, isLoading) {
    const rows = [];
    const data = this.getData(warrantdata, "data");
    var showTableRow = true;
    if (data !== null && Array.isArray(data) && selected !== null) {
      const logic = new SelectorLogic();
      data.forEach((rowdata, index) => {
        const type = this.getData(rowdata, "type", true);
        const ticker = this.getData(rowdata, "ticker", true);
        if (this.isHome()) {
          var currentprice = "$" + this.getData(rowdata, "BID", true);
          var caldata = logic.calData(rowdata, selected, rowdata.BID);
        } else {
          var currentprice = "$" + selected.BIDS[index];
          var caldata = logic.calData(rowdata, selected, selected.BIDS[index]);
        }
        // 
        // 
        const dsplyNmll = this.getData(rowdata, "dsply_nmll", true);
        const maturity = this.getData(rowdata, "maturity", true);

        const imgstyle =
          this.getData(rowdata, "category") === null ? { display: "none" } : {};
        const td1 = (
          <td>
            <span className="row_type"></span>
            {type}(
            <a className="row_ticker" href={"/tools/livematrix/" + ticker}>
              {ticker}
            </a>
            )
            <img src="/img/bullet/hot.fw.png" style={imgstyle} alt="" />
            {/* <br />
            Current price: <span className="row_BID">{currentprice}</span>
            <br />
            <span className="row_dsply_nmll">{dsplyNmll}</span> */}
            <br />
            <strong>
              Expiry: <span className="row_maturity">{maturity}</span>
            </strong>
          </td>
        );
        let td2data = caldata.tp;
        let td2change = "";
        if (td2data === "-1") {
          td2data = "Warrant expired";
        } else {
          let td2changedata = caldata.tc > 0 ? "+" : "";
          td2changedata += caldata.tc != '-' ? caldata.tc + "%" : caldata.tc;
          td2change = (
            <span className={caldata.tc > 0 ? "up" : "down"}>
              ({td2changedata})
            </span>
          );
        }
        const td1_1 = (
          <td
            className="row_BID"
            style={{ borderRight: "2px solid #ffffff", fontWeight: 700 }}
          >
            {currentprice}
          </td>
        );
        const td2 = (
          <td className="row_holding_period" width="22%">
            {td2data}
            <br />
            {td2change}
          </td>
        );

        let td3data = caldata.wp;
        let td3change = "";
        if (td3data === "-1") {
          td3data = "Warrant expired";
        } else {
          let td3changedata = caldata.wc > 0 ? "+" : "";
          td3changedata += caldata.wc != '-' ? caldata.wc + "%" : caldata.wc;
          td3change = (
            <span className={caldata.wc > 0 ? "up" : "down"}>
              ({td3changedata})
            </span>
          );
        }
        const td3 = (
          <td className="row_holding_period" >
            {td3data}
            < br />
            {td3change}
          </td >
        );
        let td4data = caldata.hp;
        let td4change = "";
        if (td4data === "-1") {
          td4data = "Warrant expired";
        } else {
          let td4changedata = caldata.hc > 0 ? "+" : "";
          td4changedata += caldata.hc != '-' ? caldata.hc + "%" : caldata.hc;
          td4change = (
            <span className={caldata.hc > 0 ? "up" : "down"}>
              ({td4changedata})
            </span>
          );
        }
        const td4 = (
          <td className="row_holding_period">
            {td4data}
            <br />
            {td4change}
          </td>
        );
        const noDataPage = (
          <td colSpan="3" rowSpan="99" className="pricezero  hideRow" style={{ verticalAlign: 'top' }}>
            Type in or adjust the target price and holding period above to see the estimated gross returns.
          </td>
        )
        const noDataHome = (
          <td colSpan="3" rowSpan="99" className="pricezero  hideRow" style={{ verticalAlign: 'top' }}>
            Type in or adjust the target price and holding period above to see the estimated gross returns.
          </td>
        )
        if (caldata.tp == '-' && caldata.wp == '-' && caldata.hp == '-' && caldata.hc == '-' && caldata.tc == '-' && caldata.wc == '-' && index == 0 || this.props.selected.weeks == 0) {
          if (this.isHome()) {
            var row = (
              <tr className="tmp_row" key={index} idx={index}>
                {td1}
                {td1_1}
                {noDataHome}
              </tr>
            );
          } else {
            var row = (
              <tr className="tmp_row" key={index} idx={index}>
                {td1}
                {td1_1}
                {noDataPage}
              </tr>
            );
          }
          showTableRow = false;
        } else {
          if (showTableRow) {
            if (this.isHome()) {
              var row = (
                <tr className="tmp_row" key={index} idx={index} style={imgstyle}>
                  {td1}
                  {td1_1}
                  {td3}
                  {td4}
                </tr>
              );
            } else {
              var row = (
                <tr className="tmp_row" key={index} idx={index}>
                  {td1}
                  {td1_1}
                  {td2}
                  {td3}
                  {td4}
                </tr>
              );
            }
          } else {
            var row = (
              <tr className="tmp_row" key={index} idx={index}>
                {td1}
                {td1_1}
              </tr>
            );
          }
        }

        rows.push(row);
      });
    }
    return rows;
  }
  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }
  setCallOrPut(val) {
    this.setState({ type: val });
    this.props.getTableData(this.props.selected.ric, val);
  }
  getDate() {
    var now = new Date();
    var y = now.getFullYear();
    var m = now.getMonth();
    var d = now.getDate();
    var h = now.getHours();
    var mm = now.getMinutes();
    var s = now.getSeconds();
    var str;
    if (h > 12) {
      h -= 12;
      str = " PM";
    } else {
      str = " AM";
    }
    h = h < 10 ? "0" + h : h;
    d = d < 10 ? "0" + d : d;
    m = m < 10 ? "0" + m : m;
    mm = mm < 10 ? "0" + mm : mm;
    s = s < 10 ? "0" + s : s;
    // var xy = y + "/" + m + "/" + d + "," + h + ":" + mm + ":" + s;
    var xy = h + ":" + mm + ":" + s;
    xy += str;
    return xy;
  }
  reset() {
    this.props.reset();
  }
  //table
  render() {
    const selected = this.props.selected;
    const currency = this.getCurrency(selected);
    const lastprice = this.getLastPrice(selected, currency);
    const targetPrice = this.getTargetPrice(selected, currency);
    const targetPriceChange = this.getTargetPriceChange(selected, targetPrice);
    const period = this.getPeriod(selected, targetPrice);

    const targetPriceChangeStep = 0.005;
    const plusStyle = this.getTargetButtonStyle(selected);
    const lessStyle = this.getTargetButtonStyle(selected, false);
    const periodStep = 1;
    const isLoading = this.props.isLoading;
    const periodWeek = this.getPeriodWeek(selected);
    const rows = this.getRows(this.props.warrantdata, selected, isLoading); //tbody data
    let className = "trending_warrant";
    if (isLoading) {
      className += " selector-loadingindicator";
    }
    var isTableShow = false;
    if (this.isHome()) {
      isTableShow = true;
    } else {
      isTableShow = this.props.isShowTable;
    }
    return (
      <div className={`price_area ${this.isHome() ? 'home' : 'page'}`}>
        <div
          className="last_label"
          style={{
            fontWeight: "700",
            display: this.isHome() ? "none" : "block",
            marginTop: "35px",
          }}
        >
          Last price
          {/* <span className="val_last_price"> {lastprice}</span> */}
          <input
            type="text"
            style={{
              marginLeft: '10px',
              width: '150px'
            }}
            value={lastprice}
            onKeyDown={(event) => this.onLastPriceKeyDown(event)}
            onChange={(event) =>
              this.onLastPriceInputChange(event)
            }
            onBlur={
              (event) =>
                this.onLastPriceKeyDown(event)
            }
          />
          <button
            id="resetbtn"
            type="button"
            onClick={() => this.reset()}
            className=" btn btn-primary"
            style={{ minWidth: "auto", marginLeft: '15px', outline: 'none' }}
          >
            reset
          </button>
        </div>
        {/* ******************************** */}
        <div
          className="select_area"
          style={{
            display: this.isHome() ? "block" : "none",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              width: "105px",
              textAlign: "center",
              display: this.isHome() ? "none" : "block",
            }}
          >
            Select
          </div>
          <div className="select_img_row">
            <div
              className="select_img"
              style={{ display: this.isHome() ? "none" : "flex" }}
            >
              <img
                className="typeSelect call_type"
                src="/img/home/call.png"
                alt=""
                style={{ width: "50px", height: "50px", cursor: "pointer" }}
                onClick={() => {
                  this.setCallOrPut("CALL");
                }}
              />
              <img
                src="/img/home/put.png"
                alt=""
                className="typeSelect put_type"
                style={{
                  width: "50px",
                  height: "50px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setCallOrPut("PUT");
                }}
              />
            </div>

            <div
              className="last_label"
              style={{ display: this.isHome() ? "block" : "none" }}
            >
              Last price <br />
              <span className="val_last_price"> {lastprice}</span>

            </div>

            <div
              className="instruction2"
              style={{
                display:
                  this.state.type == null && !this.isHome() ? "block" : "none",
              }}
            >
              Choose between a <strong>call</strong> or <strong>put</strong>
            </div>
            <div
              className="select_img_des instruction3"
              style={{
                display:
                  this.state.type == null && !this.isHome() ? "none" : "block",
              }}
            >
              Type in or adjust the target price and holding period with the
              <img
                src="/img/home/plicon.png"
                alt=""
                style={{ width: "13px", margin: " 0px 5px" }}
              />
              icons below
            </div>
            {/* <div
              className="select_img_des instruction3"
              style={{ display: this.isHome() ? "none" : "block" }}
            >
              Type in or adjust the target price and holding period with the
              <img
                src="/img/home/plicon.png"
                alt=""
                style={{ width: "13px", margin: " 0px 5px" }}
              />
              icons below
            </div> */}
          </div>
        </div>
        <div
          className={className}
          style={{
            position: "relative",
            display: isTableShow == true ? 'block' : 'none'
          }}
        >
          {/* <div className="current_return">
            Current trending warrants estimated gross returns
          </div> */}
          <div className="current_return">
            {/* Featured warrants estimated gross returns */}
            Featured warrants' estimated gross returns
          </div>
          <table id="resulttable">
            <thead style={{ display: this.isHome() ? 'none' : 'revert' }}>
              <tr>
                <th rowSpan="3" style={{ width: "25%" }}>
                  Featured warrants
                </th>
                <th rowSpan="3" style={{ width: "20%" }}>
                  Current price
                </th>
                {/* <th colSpan="3" style={{ paddingLeft: "30px" }}> */}
                <th colSpan="3">
                  Projected returns
                </th>
              </tr>
              <tr>
                <th style={{ width: "20%" }}>Select Target Price:</th>
                <th style={{ width: "10%" }}></th>
                <th style={{ width: "35%" }}>
                  <div className="trending_warrant_input">
                    <div className="target_price_input trending_warrant_input_box">
                      <input
                        type="text"
                        id="target_price_num"
                        style={{ width: "100%" }}
                        className={targetPriceChange.props.className}
                        value={targetPrice}
                        onKeyDown={(event) => this.onTargetPriceKeyDown(event)}
                        onChange={(event) =>
                          this.onTargetPriceInputChange(event)
                        }
                        onBlur={
                          (event) =>
                            this.onTargetPriceKeyDown(event)
                        }
                      />
                      <div className="plus2">
                        <img
                          src="/img/home/plus.png"
                          alt=""
                          onClick={() =>
                            this.onTargetPricePercentChange(
                              targetPriceChangeStep
                            )
                          }
                        />
                      </div>
                      <div className="plus2" style={{ display: this.props.selected.tprices == null && this.props.type == 'PUT' ? 'block' : 'none' }}>
                        <img
                          src="/img/home/buttonup_grey.png"
                          alt=""
                        />
                      </div>
                      <div className="less2">
                        <img
                          src="/img/home/less.png"
                          alt=""
                          onClick={() =>
                            this.onTargetPricePercentChange(
                              targetPriceChangeStep * -1
                            )
                          }
                        />
                      </div>
                      <div className="less2" style={{ display: this.props.selected.tprices == null && this.props.type == 'CALL' ? 'block' : 'none' }}>
                        <img
                          src="/img/home/buttondown_grey.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="target_price_c"
                      style={{ visibility: "visible" }}
                    >
                      <span>{targetPriceChange}</span>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th>Today</th>
                <th className="visible-xs">1 <br /> week</th>
                <th className="hidden-xs">1 week</th>
                <th>
                  <div
                    className="trending_warrant_input"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="holding_period_input trending_warrant_input_box">
                      <input
                        type="text"
                        id="holding_period_num"
                        style={{ width: "100%" }}
                        value={period}
                        onKeyDown={(event) =>
                          this.onPeriodWeekInputKeyDown(event)
                        }
                        onBlur={
                          (event) =>
                            this.onPeriodWeekInputKeyDown(event)
                        }
                        onChange={(event) =>
                          this.onPeriodWeekInputChange(event)
                        }
                      />
                      <div className="plus2">
                        <img
                          src="/img/home/plus.png"
                          alt=""
                          onClick={() => this.onPeriodWeekChange(periodStep)}
                        />
                      </div>
                      <div className="less2">
                        <img
                          src="/img/home/less.png"
                          alt=""
                          onClick={() =>
                            this.onPeriodWeekChange(periodStep * -1)
                          }
                        />
                      </div>
                    </div>
                    <div> weeks</div>
                  </div>
                </th>
              </tr>
            </thead>
            <thead style={{ display: this.isHome() ? 'revert' : 'none' }}>
              <tr>
                <th rowSpan="3" style={{ width: "25%" }}>
                  Featured warrants
                </th>
                <th rowSpan="3" style={{ width: "20%" }}>
                  Current price
                </th>
                {/* <th colSpan="3" style={{ paddingLeft: "30px" }}> */}
                <th colSpan="3" style={{ paddingLeft: '30px' }}>
                  Projected returns
                </th>
              </tr>
              <tr>
                <th style={{ width: "20%" }}>Select Target Price:</th>
                <th style={{ width: "35%" }}>
                  <div className="trending_warrant_input">
                    <div className="target_price_input trending_warrant_input_box">
                      <input
                        type="text"
                        id="target_price_num"
                        style={{ width: "100%" }}
                        className={targetPriceChange.props.className}
                        value={targetPrice}
                        onKeyDown={(event) => this.onTargetPriceKeyDown(event)}
                        onChange={(event) =>
                          this.onTargetPriceInputChange(event)
                        }
                      />
                      <div className="plus2">
                        <img
                          src="/img/home/plus.png"
                          alt=""
                          onClick={() =>
                            this.onTargetPricePercentChange(
                              targetPriceChangeStep
                            )
                          }
                        />
                      </div>
                      <div className="plus2" style={{ display: this.props.type == 'PUT' && this.props.selected.tprices == null ? 'block' : 'none' }}>
                        <img
                          src="/img/home/buttonup_grey.png"
                          alt=""
                        />
                      </div>
                      <div className="less2">
                        <img
                          src="/img/home/less.png"
                          alt=""
                          onClick={() =>
                            this.onTargetPricePercentChange(
                              targetPriceChangeStep * -1
                            )
                          }
                        />
                      </div>
                      <div className="less2" style={{ display: this.props.type == 'CALL' && this.props.selected.tprices == null ? 'block' : 'none' }}>
                        <img
                          src="/img/home/buttondown_grey.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="target_price_c"
                      style={{ visibility: "visible" }}
                    >
                      <span>{targetPriceChange}</span>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th>Today</th>
                {/* <th></th> */}
                <th>
                  <div
                    className="trending_warrant_input"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="holding_period_input trending_warrant_input_box">
                      <input
                        type="text"
                        id="holding_period_num"
                        style={{ width: "100%" }}
                        value={period}
                        onKeyDown={(event) =>
                          this.onPeriodWeekInputKeyDown(event)
                        }
                        onChange={(event) =>
                          this.onPeriodWeekInputChange(event)
                        }
                      />
                      <div className="plus2">
                        <img
                          src="/img/home/plus.png"
                          alt=""
                          onClick={() => this.onPeriodWeekChange(periodStep)}
                        />
                      </div>
                      <div className="less2">
                        <img
                          src="/img/home/less.png"
                          alt=""
                          onClick={() =>
                            this.onPeriodWeekChange(periodStep * -1)
                          }
                        />
                      </div>
                    </div>
                    <div> weeks</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody >{rows}</tbody>
            <tbody
              style={{
                display: this.props.warrantdata === null ? "revert" : "none",
              }}
            >
              <tr>
                <td colSpan="5" style={{ padding: "21px 0px" }}>
                  No warrants available
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ fontStyle: 'italic' }}>
            Estimated gross returns calculated as of{" "}
            <span>{this.getDate()}</span>
            <br />
            The featured warrants in the table above refer to all warrants quoted on tight spreads (i.e. less than 3 warrant ticks between the bid and offer prices)
            <br />
            Note: The estimated returns are only valid on the date of usage, and may change from day to day
          </p>
        </div>
      </div>
    );
  }
}

class TrendWarrant extends Component {
  getData(data, fieldName, isReturnEmptyString = false) {
    if (data != null) {
      if (fieldName in data) {
        return data[fieldName];
      }
    }
    if (isReturnEmptyString) {
      return "";
    }
    return null;
  }

  getPeriodWeek(selected) {
    if (selected !== null) {
      if ("weeks" in selected) {
        return selected.weeks + " weeks";
      }
    }
    return "4 weeks";
  }

  getRows(warrantdata, selected, isLoading) {
    const rows = [];
    const data = this.getData(warrantdata, "data");
    if (data !== null && Array.isArray(data) && selected !== null) {
      const logic = new SelectorLogic();
      data.forEach((rowdata, index) => {
        const type = this.getData(selected, "type", true);
        const ticker = this.getData(rowdata, "ticker", true);
        const currentprice = "$" + this.getData(rowdata, "BID", true);
        const dsplyNmll = this.getData(rowdata, "dsply_nmll", true);
        const maturity = this.getData(rowdata, "maturity", true);
        const caldata = logic.calData(rowdata, selected, selected.BIDS[index]);
        const imgstyle =
          this.getData(rowdata, "category") === null ? { display: "none" } : {};
        const td1 = (
          <td>
            <span className="row_type"></span>
            {type}(
            <a className="row_ticker" href={"/tools/livematrix/" + ticker}>
              {ticker}
            </a>
            )
            <img src="/img/bullet/hot.fw.png" style={imgstyle} alt="" />
            <br />
            Current price: <span className="row_BID">{currentprice}</span>
            <br />
            <span className="row_dsply_nmll">{dsplyNmll}</span>
            <br />
            Expiry: <span className="row_maturity">{maturity}</span>
          </td>
        );
        let td2data = caldata.tp;
        let td2change = "";
        if (td2data === "-1") {
          td2data = "Warrant expired";
        } else {
          let td2changedata = caldata.tc > 0 ? "+" : "";
          td2changedata += caldata.tc + "%";
          td2change = (
            <span className={caldata.tc > 0 ? "up" : "down"}>
              ({td2changedata})
            </span>
          );
        }
        const td2 = (
          <td className="row_holding_period">
            {td2data}
            <br />
            {td2change}
          </td>
        );

        let td3data = caldata.wp;
        let td3change = "";
        if (td3data === "-1") {
          td3data = "Warrant expired";
        } else {
          let td3changedata = caldata.wc > 0 ? "+" : "";
          td3changedata += caldata.wc + "%";
          td3change = (
            <span className={caldata.wc > 0 ? "up" : "down"}>
              ({td3changedata})
            </span>
          );
        }
        const td3 = (
          <td className="row_holding_period">
            {td3data}
            <br />
            {td3change}
          </td>
        );

        let td4data = caldata.hp;
        let td4change = "";
        if (td4data === "-1") {
          td4data = "Warrant expired";
        } else {
          let td4changedata = caldata.hc > 0 ? "+" : "";
          td4changedata += caldata.hc + "%";
          td4change = (
            <span className={caldata.hc > 0 ? "up" : "down"}>
              ({td4changedata})
            </span>
          );
        }
        const td4 = (
          <td className="row_holding_period">
            {td4data}
            <br />
            {td4change}
          </td>
        );

        const row = (
          <tr className="tmp_row" key={index} idx={index}>
            {td1}
            {td2}
            {td3}
            {td4}
          </tr>
        );
        rows.push(row);
      });
    }
    return rows;
  }

  render() {
    const selected = this.props.selected;
    const isLoading = this.props.isLoading;
    const periodWeek = this.getPeriodWeek(selected);
    const rows = this.getRows(this.props.warrantdata, selected, isLoading);
    let className = "trending_warrant";
    if (isLoading) {
      className += " selector-loadingindicator";
    }

    return (
      <div className={className}>
        <div className="current_return">
          Current trending warrants projected returns
        </div>
        <table id="resulttable">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Trending warrants</th>
              <th style={{ width: "20%" }}>Today</th>
              <th style={{ width: "20%" }}>1 week</th>
              <th style={{ width: "20%" }} className="val_holding_period">
                {periodWeek}
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

class Chart extends Component {
  getChartsJsonData(data) {
    if (data !== null) {
      var chartData = {
        data: [],
        min: 0,
        max: 0,
      };
      var yAxis = [];
      for (var i = 0; i < data.data.length; i++) {
        // chartData.data.push({
        //   x: new Date(data.data[i]["time"]).getTime(),
        //   y: Number(data.data[i]["close"]),
        // });
        // + 28800000
        chartData.data.push([
          new Date(data.data[i]["time"]).getTime() + 28800000,
          Number(data.data[i]["close"]),
        ]);
        yAxis.push(Number(data.data[i]["close"]));
      }
      chartData.min = Math.min.apply(Math, yAxis);
      chartData.max = Math.max.apply(Math, yAxis);
      return chartData;
    } else {
      return null;
    }
  }
  floatMultiply(arg1, arg2) {
    if (arg1 == null || arg2 == null) {
      return null;
    }
    var n1, n2;
    var r1, r2; // 小数位数
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    n1 = Number(arg1.toString().replace(".", ""));
    n2 = Number(arg2.toString().replace(".", ""));
    return n1 * n2 / Math.pow(10, r1 + r2);
  }
  render() {
    var chartdata = this.getChartsJsonData(this.props.data);
    // const logic = new SelectorLogic();
    // const chartdata = logic.parseChartData(
    //   this.props.data,
    //   this.props.ric,
    //   this.props.period
    var that = this;
    var data = [];
    var min = 0;
    var max = 0;
    if (chartdata === null) {
      data = [];
    } else {
      data = chartdata.data;
      min = chartdata.min;
      max = chartdata.max;
    }
    const chartOption = {
      chart: {
        type: "area",
        zoomType: "x",
        events: {
          selection: function (event) {
            // var std=event.xAxis[0].min+','+event.xAxis[0].max
            // var pgpd3=event.yAxis[0].min+','+event.yAxis[0].max
            // this.$emit("sendData", std,pgpd3);
            if (event.xAxis == undefined) {
              this.title.update({ text: '' });
              return;
            }
            var min = null;
            var max = null;
            var mum1 = event.xAxis[0].min + "";
            var mum2 = event.xAxis[0].max + "";
            if (mum1.indexOf('.') != -1) {
              mum1 = mum1.slice(0, mum1.indexOf('.'))
            }
            if (mum2.indexOf('.') != -1) {
              mum2 = mum2.slice(0, mum2.indexOf('.'))
            }
            mum1 = Number(mum1)
            mum2 = Number(mum2)

            if (that.props.period == 'D') {
              for (var i = 0; i < data.length; i++) {
                if (new Date(data[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
                  new Date(data[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
                  new Date(data[i][0]).getDate() == new Date(mum1).getDate() &&
                  new Date(data[i][0]).getHours() == new Date(mum1).getHours() &&
                  new Date(data[i][0]).getMinutes() == new Date(mum1).getMinutes()

                ) {
                  min = data[i][1]
                }
                if (new Date(data[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
                  new Date(data[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
                  new Date(data[i][0]).getDate() == new Date(mum2).getDate() &&
                  new Date(data[i][0]).getHours() == new Date(mum2).getHours() &&
                  new Date(data[i][0]).getMinutes() == new Date(mum2).getMinutes()

                ) {
                  max = data[i][1]
                }
              }
            } else {
              for (var i = 0; i < data.length; i++) {
                if (new Date(data[i][0]).getFullYear() == new Date(mum1).getFullYear() && new Date(data[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 && new Date(data[i][0]).getDate() == new Date(mum1).getDate()) {
                  min = data[i][1]
                }
                if (new Date(data[i][0]).getFullYear() == new Date(mum2).getFullYear() && new Date(data[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 && new Date(data[i][0]).getDate() == new Date(mum2).getDate()) {
                  max = data[i][1]
                }
              }
            }

            // min = data[0][0];
            // min = data[data.length - 1][0];
            if (((max - min) / min).toFixed(2) * 100 == Infinity || isNaN(((max - min) / min).toFixed(2) * 100)) {
              this.title.update({ text: '' });
            } else {
              this.title.update({ text: 'Underlying Percentage Change: ' + that.floatMultiply(((max - min) / min).toFixed(3), 100) + '%' });
            }
          }
        },
      },
      // global: {
      //   useUTC: false,
      // },
      navigator: {
        enabled: false
      },
      rangeSelector: {

      },
      // rangeSelector: {
      //   labelStyle: {
      //     enabled: false,
      //     color: "#fff",
      //     bottom: 0,
      //     right: 0
      //   },
      //   inputEnabled: false
      // },
      colors: ['#CEE5F5'],
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      credits: {
        text: "",
      },
      xAxis: {
        // allowDecimals: false,
        gridLineColor: "#FAFAFA",
        crosshair: {
          width: 1,
          color: 'red'
        },
        gridLineWidth: 1,
        type: "datetime",
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            var date = '';
            if (that.props.period == 'D') {
              date = Highcharts.dateFormat('%H:%M', this.value)
            } else if (that.props.period == 'Y') {
              date = Highcharts.dateFormat('%d/%m/%y', this.value)
            } else {
              date = Highcharts.dateFormat('%d/%m', this.value)
            }
            return date
          }
        },
        dateTimeLabelFormats: {
          day: '%d/%m/%y',
        }
      },
      yAxis: {
        // crosshair: true,
        opposite: true,
        floor: min,
        ceiling: max,
        labels: {
          formatter: function () {
            return this.value;
          },
        },
        title: {
          text: "",
        },
        lineWidth: 1,
        tickWidth: 1,
        gridLineColor: "#FAFAFA",
      },

      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        // formatter: function () {
        //   return "Underlying Price :" + this.y;
        // },
        // pointFormat: "Underlying Price : <b>{point.y:,.0f}</b>",
        borderColor: '#097CCF',
        borderWidth: 2,
        style: {
          fontWeight: 800
        },
        pointFormat: "Underlying Price : <b>{point.y}</b>",
        split: true,
        xDateFormat: '%d/%m/%y'
      },
      plotOptions: {
        area: {
          lineWidth: 1,
          lineColor: '#097CCF',
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 0,
            states: {
              hover: {
                enabled: false,
                lineWidth: 0,
                lineWidthPlus: 0,
                radius: 0,
                radiusPlus: 0
              },
            },
          },
          series: {
            stacking: "normal",
          },
        },
        // series: {
        //   pointInterval: 24 * 3600 * 1000 // one day
        // }
      },
      series: [
        {
          data: data,
        },
      ],
    };
    const style = {
      width: "100%",
      height: "370px",
    };
    // return <div style={style} ref="chartBox"></div>;
    // return <HighchartsReact highcharts={highcharts} options={chartOption} constructorType={'stockChart'} />;
    return <HighchartsReact highcharts={highcharts} options={chartOption} />;
  }
}
// 1. initial selection issue
// 2. change target price > update warrant trend
