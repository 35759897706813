import React, { Component } from "react";
import {
  BrowserRouter,
  Route,
  Router,
  Redirect,
  Switch,
} from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";

import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home/home";
import ShareBtn from "./components/sharebtn";
import LiveMatrix from "./pages/tools/livematrix/livematrix";
import WarrantSelector from "./pages/tools/warrantselector/selector";
import WarrantSearch from "./pages/tools/warrantsearch/search";
import WarrantTerms from "./pages/tools/warrantterm/term";
import WarrantCalculator from "./pages/tools/warrantcalculator/calculator";
import WarrantComparison from "./pages/tools/warrantcomparison/comparison";
import UnderlyingChart from "./pages/tools/underlyingchart/underlyingchart";
import WarrantDocument from "./pages/tools/warrantdocument/document";
import Telegram from "./pages/marketnews/telegram/telegram";
import DailyHigh from "./pages/marketnews/dailyhighlights/dailyhigh";
import OverHight from "./pages/marketnews/overnight/overnight";
import Subscribe from "./pages/marketnews/subscribe/subscribe";
import Gallery from "./pages/marketnews/photogallery/gallery";
import GetStarted from "./pages/education/getstarted/started";
import Brochure from "./pages/education/brochure/brochure";
import SuccessSpeaks from "./pages/education/successspeaks/speaks";
import FAQ from "./pages/education/faq/faq";
import Seminar from "./pages/education/seminar/seminar";
import Hstech from "./pages/education/hstech/hstech";
import TopTraded from "./pages/marketdata/toptradedwarrant/toptraded";
import TopGainer from "./pages/marketdata/topgainer/topgainer";
import DWTurnover from "./pages/marketdata/dwturnover/dwturnover";
import TurnoverDist from "./pages/marketdata/turnoverdist/turnoverdist";
import EducationVideo from "./pages/education/educationvideos/warrantvideos";
import ShortFormVideos from "./pages/education/shortFormVideos/warrantvideos";
import MarketView from "./pages/education/educationvideos/marketview";
import MarketCommentary from "./pages/education/marketcommentary/commentary";
import HighLight from "./pages/marketnews/highlight/highlight";
import IndividualGallery from "./pages/marketnews/individualgallery/individualgallery";
import Whymacquarie from "./pages/education/whymacquarie/whymacquarie";
import Indexwarrants from "./pages/education/indexwarrants/indexwarrants";

import ImportantInformation from "./pages/bottomPage/importantInformation/importantInformation";
import Termsofuse from "./pages/bottomPage/termsOfUse/termsOfUse";
import Contactus from "./pages/bottomPage/contactUs/contactUs";
import Disclaimer from "./pages/bottomPage/disclaimer/disclaimer";
import Disclaimerr from "./pages/bottomPage/disclaimerr/disclaimerr";
import Privacypolicy from "./components/privacypolicy";

import Search from "./pages/home/search/search";

// Investor Academy
import InvestorAcademy from "./pages/investorAcademy/eduhtml";


import Sp500 from "./pages/education/sp500/sp500";
import Sgxtradingfestival from "./components/sgxtradingfestival/sgxtradingfestival";
import UnSubscribe from "./components/UnSubscribe/UnSubscribe";
import SiMSCI from "./components/SiMSCI/SiMSCI";
import Sitemap from "./components/sitemap/sitemap";

import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
// import createHistory from 'history/createBrowserHistory'
import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";
const history = createBrowserHistory();
ReactGA.initialize("G-6VGY7GKL9V");
history.listen((location, action) => {
  ReactGA.send({ page: location.pathname + location.search });
  if (window.location.href.indexOf("answerandwin") == -1) {
    if (document.querySelector("#navbar").style) {
      document.querySelector("#navbar").style.visibility = "visible";
      document.querySelector(".sharebtn").style.display = "block";
      document.querySelector(".footer .sitemaps").style.display = "block";
      document.querySelector(".footer .sitemap-xs").style.visibility =
        "visible";
    }
  }
});
export default class App extends Component {
  componentDidMount() {
    ReactGA.send({ page: location.pathname + location.search });
  }
  render() {
    var isShow = true;
    var isShow = window.location.href.indexOf("vestorAcademy") == -1;
    return (
      <div>
        <Router history={history}>
          <ScrollToTop>
            <div>
              {isShow ? <Header /> : null}
              {/* <Header/>
            <ShareBtn /> */}
              {isShow ? <ShareBtn /> : null}
              <Switch>
                {/* InvestorAcademy */}
                <Route
                  path="/InvestorAcademy/:ticker"
                  render={(props) => <InvestorAcademy {...props} />}
                ></Route>

                <Route
                  path="/InvestorAcademy"
                  component={InvestorAcademy}
                ></Route>
                <Route path="/education/SiMSCI" component={SiMSCI}></Route>
                <Route path="/Sitemap" component={Sitemap}></Route>
                <Route path="/UnSubscribe" component={UnSubscribe}></Route>

                {/* home */}
                <Route path="/home" component={Home}></Route>
                {/* tools */}
                <Route
                  path="/tools/livematrix/:ticker"
                  render={(props) => <LiveMatrix {...props} />}
                ></Route>
                <Route path="/tools/livematrix" component={LiveMatrix}></Route>
                <Route
                  path="/tools/selector"
                  component={WarrantSelector}
                ></Route>
                <Route
                  path="/tools/warrantsearch/:ticker"
                  render={(props) => <WarrantSearch {...props} />}
                ></Route>
                <Route
                  path="/tools/warrantsearch"
                  component={WarrantSearch}
                ></Route>
                <Route
                  path="/tools/warrantterms/:ticker"
                  render={(props) => <WarrantTerms {...props} />}
                ></Route>
                <Route
                  path="/tools/warrantterms"
                  component={WarrantTerms}
                ></Route>
                <Route
                  path="/tools/HistoricalPerformance/:ticker"
                  render={(props) => <WarrantTerms {...props} />}
                ></Route>
                <Route
                  path="/tools/HistoricalPerformance"
                  component={WarrantTerms}
                ></Route>
                <Route
                  path="/tools/warrantcalculator/:ticker"
                  render={(props) => <WarrantCalculator {...props} />}
                ></Route>
                <Route
                  path="/tools/warrantcalculator"
                  component={WarrantCalculator}
                ></Route>

                <Route
                  path="/tools/underlying/:ticker"
                  render={(props) => <UnderlyingChart {...props} />}
                ></Route>
                <Route
                  path="/tools/underlying"
                  component={UnderlyingChart}
                ></Route>
                <Route
                  path="/tools/warrantcomparison"
                  component={WarrantComparison}
                ></Route>
                <Route
                  path="/tools/document"
                  component={WarrantDocument}
                ></Route>
                {/* marketnews */}
                <Route path="/telegram" component={Telegram}></Route>
                <Route
                  path="/marketnews/highlight/:cat/:id"
                  render={(props) => <HighLight {...props} />}
                ></Route>
                <Route
                  path="/marketnews/dailyhighlights"
                  component={DailyHigh}
                ></Route>
                <Route
                  path="/marketnews/overnightmarkets"
                  component={OverHight}
                ></Route>
                <Route
                  path="/marketnews/subscribe"
                  component={Subscribe}
                ></Route>
                <Route
                  path="/marketnews/photogallery"
                  component={Gallery}
                ></Route>
                <Route
                  path="/marketnews/individualgallery/:id"
                  render={(props) => <IndividualGallery {...props} />}
                ></Route>
                {/* warrant education */}
                <Route
                  path="/education/getstarted"
                  component={GetStarted}
                ></Route>
                <Route
                  path="/education/warrantvideos"
                  component={EducationVideo}
                ></Route>
                <Route
                  path="/education/shortFormVideos"
                  component={ShortFormVideos}
                ></Route>
                <Route
                  path="/education/marketcommentary/:subtitle/:id/:index"
                  render={(props) => <MarketCommentary {...props} />}
                ></Route>
                <Route
                  path="/education/marketcommentary/:subtitle/:id"
                  render={(props) => <MarketCommentary {...props} />}
                ></Route>
                <Route
                  path="/education/marketcommentary"
                  component={MarketCommentary}
                ></Route>
                <Route path="/education/brochure" component={Brochure}></Route>
                <Route path="/SuccessSpeaks" component={SuccessSpeaks}></Route>
                <Route
                  path="/education/marketview"
                  component={MarketView}
                ></Route>
                <Route path="/education/faq" component={FAQ}></Route>
                <Route path="/education/seminar" component={Seminar}></Route>
                <Route path="/education/hstech" component={Hstech}></Route>
                <Route
                  path="/education/whymacquarie"
                  component={Whymacquarie}
                ></Route>
                <Route
                  path="/education/Indexwarrants"
                  component={Indexwarrants}
                ></Route>
                {/* warrant statistics */}
                <Route
                  path="/marketdata/toptradedwarrants"
                  component={TopTraded}
                ></Route>
                <Route
                  path="/marketdata/topgainloss"
                  component={TopGainer}
                ></Route>
                <Route
                  path="/marketdata/turnover"
                  component={DWTurnover}
                ></Route>
                <Route
                  path="/marketdata/turnoverdist"
                  component={TurnoverDist}
                ></Route>

                <Route
                  path="/about/importantInformation"
                  component={ImportantInformation}
                ></Route>

                <Route path="/about/disclaimer" component={Disclaimer}></Route>

                <Route path="/about/contactus" component={Contactus}></Route>

                <Route path="/about/termsofuse" component={Termsofuse}></Route>

                <Route
                  path="/about/disclaimerr"
                  component={Disclaimerr}
                ></Route>
                <Route path="/edu/" component={Disclaimerr}></Route>
                <Route
                  path="/about/privacypolicy"
                  component={Privacypolicy}
                ></Route>

                <Route path="/search" component={Search}></Route>
                {/* 如果找不到页面则会跳转至home */}
                <Redirect to="/home" />
              </Switch>
              {/* <Footer /> */}
              {isShow ? <Footer /> : null}
            </div>
          </ScrollToTop>
        </Router>
      </div>
    );
  }
}
