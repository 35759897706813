import React, { Component } from "react";
import { components } from "react-select";
import { Seminar } from "./warranttools";
import ReactTooltip from "react-tooltip";
import MQSGUtil from '../../../components/mqsgUtil';
import { Link } from "react-router-dom";
export default class WarrantEducation extends Component {
  render() {
    return (
      <div id="dwedu" className="tabcontent">
        <div className="row">
          <div className="col-sm-6">
            {/* 1 */}
            <GetStart />
            <Brochure />
            {/* 2 */}
            <Seminar />
            {/* 3 */}
            <InvestorAcademy />
            {/* 4 */}
            <FAQ />
          </div>
          <div className="col-sm-6">
            {/* 1 */}
            <ShortFormVideos />
            {/* 2 */}
            <EducationVideo />
            {/* 3 */}
            <MarketView />
            {/* 4 */}
            <SuccessSpeak />
          </div>
        </div>
      </div>
    );
  }
}

class GetStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      linkShow: false,
      serialNumber: 0,
      linkArr: [
        "https://www.itradecimb.com.sg/app/home.z",
        "https://www.dbs.com.sg/vickers/en/trade/default.page",
        "https://www.dbs.com.sg/vickers/en/trade/default.page",
        "https://www.kgieworld.sg/securities/",
        "https://www.limtan.com.sg/",
        "http://www.maybank-ke.com.sg/",
        "https://portal.iocbc.com/",
        "https://www.phillip.com.sg/",
        "http://www.rhbinvest.com.sg/",
        "https://www.utrade.com.sg/index.jsp",
        "https://www.moomoo.com/sg/",
        "https://onlineeducation.sgx.com/",
        "http://www.sgx.com/sip"
      ],
    };
  }
  changeShow(i) {
    this.setState({ isShow: i });
  }
  SwitchLinkShow(v, i) {
    this.setState({ linkShow: v });
    this.setState({ serialNumber: i });
  }
  render() {
    const one = (
      <div className="col-xs-12">
        <div className="section_title">
          <span className="section_number">1</span>&nbsp;
          <span>Knowledge</span>
        </div>
        <p className="section_content">
          Before you start trading, the first thing you need to do is improve
          your knowledge in structured warrants! Never buy something you don’t
          understand. Check out some of our learning resources:
        </p>
        <div className="row" style={{ marginRight: "0px", paddingBottom: '15px' }}>
          <div className="col-xs-4">
            <div>
              <a
                href="/education/marketcommentary/Educational%20Videos/PLOkzCdnS95xQ11S019PR9SqW6FokLfiz0/0"
                target="_blank"
              >
                <img
                  src="/img/started/Knowledge3.png"
                  alt=""
                  style={{ width: "100%", maxWidth: "176px" }}
                />
              </a>
            </div>
          </div>
          <div className="col-xs-4">
            <div>
              <a href="/education/seminar" target="_blank">
                <img
                  src="/img/started/Knowledge2.png"
                  alt=""
                  style={{ width: "100%", maxWidth: "176px" }}
                />
              </a>
            </div>
          </div>
          <div className="col-xs-4">
            <div>
              <a href="/InvestorAcademy" target="_blank">
                <img
                  src="/img/started/Knowledge1.png"
                  alt=""
                  style={{ width: "100%", maxWidth: "176px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
    const two = (
      <div className="section2 section_area">
        <div className="row">
          <div className="col-xs-12">
            <div className="section_title">
              <span className="section_number">2</span>&nbsp;
              <span>Brokerage account</span>
            </div>
            <p className="section_content">
              To start trading, you’ll need to have a brokerage account with a
              stockbroker. If you already have a brokerage account, proceed to
              no. 3. Otherwise, here is a list of stockbroking companies in
              Singapore that you could contact:
            </p>
          </div>
        </div>
        <div className="broker-table-holder">
          <table
            className="broker-table table table-striped"
            style={{ marginBottom: "5px" }}
          >
            <thead>
              <tr>
                <th>Broker</th>
                <th>
                  Execution
                  <br />
                  services
                </th>
                <th>
                  Online
                  <br />
                  trading
                </th>
                <th>Contact detail</th>
                <th style={{ paddingRight: "0px" }}>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="https://www.itradecimb.com.sg/app/home.z"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/cimb_securities.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 0);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-contact">
                  <a href="tel:18005389889">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:clientservices.sg@cimb.com">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>

              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="https://www.dbs.com.sg/vickers/en/trade/default.page"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/dbs_securities.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 1);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td></td>
                <td className="broker-td-contact">
                  <a href="tel:+6563272288">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:info-sg@dbsvonline.com">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>

              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="http://www.ifastfinancial.com.sg/"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/iFast_financial.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 2);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-contact">
                  <a href="tel:+6565572853">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:clienthelp@fundsupermart.com">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>

              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="https://www.kgieworld.sg/securities/"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/kgi_securities.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 3);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td></td>
                <td className="broker-td-contact">
                  <a href="tel:+6562021188">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:sgp.cso@kgi.com">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>

              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="http://www.limtan.com.sg/"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/LimTan_securities.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 4);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-contact">
                  <a href="tel:+6565330595">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:online@limtan.com.sg">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>

              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="http://www.maybank-ke.com.sg/"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/maybank.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 5);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-note" style={{ textAlign: "center" }}>
                  <img
                    src="/img/education/broker_tick.png"
                    alt=""
                    style={{ width: "12px" }}
                  />
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>*</span>
                </td>
                <td className="broker-td-contact">
                  <a href="tel:+6564321888">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:helpdesk@maybank-ke.com.sg">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="http://www.utrade.com.sg/index.jsp"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/imagemomo.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 10);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className=""></td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-contact">
                  <a href="tel:+65 6321 8888">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:clientservice@futusg.com">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="http://www.iocbc.com/"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/ocbc_securities.jpg"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 6);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-contact">
                  <a href="tel:+18003388688">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:cs@ocbcsec.com">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>

              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="https://www.phillip.com.sg/"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/philip_securities.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 7);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-contact">
                  <a href="tel:+6565311555">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:talktophillip@phillip.com.sg">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>

              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="https://www.itradecimb.com.sg/app/home.z"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/Macquarie.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 0);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-tick"></td>
                <td className="broker-td-contact">
                  <a href="tel:+65 6538 0500">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:nova@phillip.com.sg">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>

              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="http://www.rhbinvest.com.sg/"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/rhb_securities.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 8);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td></td>
                <td className="broker-td-contact">
                  <a href="tel:+6564388810">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:rhbsec.enquiries@rhbgroup.com">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="broker-td-logo">
                  {/* <a
                    href="http://www.utrade.com.sg/index.jsp"
                    className="broker_img disclaimer"
                  > */}
                  <img
                    src="/img/education/uob_securities.jpg"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.SwitchLinkShow(true, 9);
                    }}
                  />
                  {/* </a> */}
                </td>
                <td className="broker-td-tick"></td>
                <td></td>
                <td className="broker-td-contact">
                  <a href="tel:+65 6536 9338">
                    <span className="broker-phone"></span>
                  </a>
                </td>
                <td className="broker-td-email">
                  <a href="mailto:contact@utrade.com.sg">
                    <span className="broker-email"></span>
                  </a>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <p>* For pre-funded accounts only</p>
      </div>
    );
    const three = (
      <div className="section3 section_area">
        <div className="row">
          <div className="row">
            <div className="col-xs-12">
              <div className="section_title">
                <span className="section_number">3</span>&nbsp;
                <span> Specified Investment Product (SIP) requirements</span>
              </div>
              <p className="section_content">
                Structured warrants are listed SIPs, which means a broker must
                assess if a retail investor has relevant knowledge and
                experience dealing in SIPs before allowing the investor to trade
                in SIPs including structured warrants.
              </p>
              <div className="row" style={{ marginRight: 0 }}>
                <div className="col-xs-12">
                  <ul style={{ paddingLeft: "20px" }}>
                    <li className="section_content">
                      You are deemed eligible if you…
                    </li>
                    <a
                      href="#"
                      className="btn btn-primary eligibility"
                      onClick={() => this.changeShow(true)}
                    >
                      Check eligibility
                    </a>
                    <li className="section_content">
                      Otherwise, you can learn more about SIPs by enrolling in
                      the
                      <span className="content_bold">
                        {" "}
                        SIP learning module{" "}
                      </span>
                      provided by the SGX, and taking the
                      <span className="content_bold"> assessment </span>
                      after. Once you have passed the assessment, forward the
                      results onto your broker so that you can get started on
                      trading warrants!
                    </li>
                    <a
                      href="#"
                      className="btn btn-primary link-btn disclaimer"
                      onClick={() => this.SwitchLinkShow(true, 11)}
                    >
                      Learning module
                    </a>
                    <a
                      href="#"
                      className="btn btn-primary link-btn disclaimer"
                      onClick={() => this.SwitchLinkShow(true, 12)}
                    >
                      Take assessment
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const four = (
      <div className="section4 section_area" style={{ margin: 0, padding: 0 }}>
        <div className="row">
          <div className="col-xs-12">
            <div className="section_title">
              <span className="section_number">4</span>&nbsp;
              <span>Selecting a warrant</span>
            </div>
            <p className="section_content">
              Now that you’re all set up, how do you select a structured warrant
              that is right for you? Check out Episode 3 of our animated videos
              for a short explanation on the selection process, or use our
              Warrant search tool to find warrants that match your investment
              criteria.
              <br />
              <br />
              If you are trading a structured warrant listed by Macquarie, you
              should always refer to the Live matrix to see where Macquarie’s
              bid and offer prices will be in the warrant for various levels in
              the underlying.
            </p>
            <div className="row">
              <div className="col-xs-4">
                <div>
                  <a
                    href="/education/marketcommentary/Educational%20Videos/PLOkzCdnS95xQ11S019PR9SqW6FokLfiz0/0"
                    target="_blank"
                  >
                    <img
                      src="/img/started/Selecting1.png"
                      alt=""
                      style={{ width: "100%", maxWidth: "176px" }}
                    />
                  </a>
                </div>
              </div>

              <div className="col-xs-4">
                <div>
                  <a href="/tools/warrantsearch" target="_blank">
                    <img
                      src="/img/started/Selecting2.png"
                      alt=""
                      style={{ width: "100%", maxWidth: "176px" }}
                    />
                  </a>
                </div>
              </div>

              <div className="col-xs-4">
                <div>
                  <a href="/tools/livematrix" target="_blank">
                    <img
                      src="/img/started/Selecting3.png"
                      alt=""
                      style={{ width: "100%", maxWidth: "176px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <p className="section_content" style={{ margin: 0 }}>
              You may also refer to the Warrant hot list on our homepage.{" "}
            </p>
          </div>
        </div>
      </div>
    );
    const five = (
      <div className="section5 section_area">
        <div className="row">
          <div className="col-xs-12">
            <div className="section_title">
              <span className="section_number">5</span>&nbsp;
              <span>How to buy or sell structured warrants?</span>
            </div>
            <p className="section_content">
              On your broker’s trading platform, you will be able to view all
              structured warrants listed on the SGX, just like shares. Once you
              have used the tools on Macquarie’s website to select the right
              structured warrant for you, you can simply buy or sell in the same
              way that you buy or sell shares. If you are unsure of how to buy
              or sell warrants, please contact your broker.
            </p>
          </div>
        </div>
      </div>
    );
    const six = (
      <div className="section6 section_area" >
        <div className="row">
          <div className="col-xs-12">
            <div className="section_title">
              <span className="section_number">6</span>&nbsp;
              <span>Constant support and updates</span>
            </div>
            <p className="section_content">
              If you need any assistance, please contact us at +65 6601 0289 or
              info@warrants.com.sg.
              <br />
              <br />
              You can also subscribe to our newsletter for daily highlights and
              important updates of the warrants market!
            </p>
          </div>
        </div>
        <div className="row">
          <div style={{ textAlign: "center" }}>
            <a href="/marketnews/subscribe" target="_blank">
              <img
                src="/img/started/Subscribe.png"
                alt=""
                style={{ width: "100%", maxWidth: "176px" }}
              />
            </a>
          </div>
        </div>
      </div>
    );
    const tm = (
      <div
        className="qtip qtip-default qtip-pos-lc qtip-fixed"
        style={{ zIndex: 15009, color: "#000" }}
      >
        <div className="qtip-content" id="qtip-31-content">
          <div
            className="eligibility"
          // style={{ display: "block", visibility: "visible" }}
          >
            <div className="signup-layer">
              <div className="page-header">
                <h3 style={{ color: "#000" }}>
                  Relevant working experience include:
                </h3>
              </div>
              <p className="small"></p>
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  the provision of legal advice or possession of legal expertise
                  on the relevant areas listed below;
                </li>
                <li>
                  the development of, structuring of, management of, sale of,
                  trading of, research on or analysis of investment products;
                </li>
                <li>
                  the provision of training in investment products (as defined
                  in Section 2 of the Financial Advisers Act (Cap. 110)); and
                </li>
                <li>
                  the area of Accountancy, Actuarial Science, Treasury or
                  Financial Risk Management activities.
                </li>
              </ul>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div className="holder-qlink-getstart">
        <div id="qlink-getstart" className="section">
          <div className="page-header">
            <h2 className="small">How to get started</h2>
          </div>
          <p>Walk through the processes by tapping on the steps below.</p>
          <div className="area_top">
            <div className="row hidden-xs">
              <div className="col-sm-2 top-pointer">
                <div
                  className="ttip getstarted1"
                  data-target="getstarted1"
                  data-hasqtip="74"
                >
                  1
                </div>
                <div className="top_line"></div>
              </div>
              <div className="col-sm-2 top-pointer">
                <div
                  className="ttip getstarted2"
                  data-target="getstarted2"
                  data-hasqtip="80"
                >
                  2
                </div>
                <div className="top_line"></div>
              </div>
              <div className="col-sm-2 top-pointer">
                <div
                  className="ttip getstarted3"
                  data-target="getstarted3"
                  data-hasqtip="86"
                >
                  3
                </div>
                <div className="top_line"></div>
              </div>
              <div className="col-sm-2 top-pointer">
                <div
                  className="ttip getstarted4"
                  data-target="getstarted4"
                  data-hasqtip="92"
                >
                  4
                </div>
                <div className="top_line"></div>
              </div>
              <div className="col-sm-2 top-pointer">
                <div
                  className="ttip getstarted5"
                  data-target="getstarted5"
                  data-hasqtip="98"
                >
                  5
                </div>
                <div className="top_line"></div>
              </div>
              <div className="col-sm-2 top-pointer">
                <div
                  className="ttip getstarted6"
                  data-target="getstarted6"
                  data-hasqtip="104"
                >
                  6
                </div>
              </div>
            </div>
            <div className="row hidden-xs">
              <div
                className="col-sm-2 top-img ttip getstarted1"
                data-target="getstarted1"
                data-hasqtip="110"
                data-tip
                data-for="indicator-highestliquidity-tooltip"
              >
                <div>
                  <img src=" /img/started/1.png" alt="" />
                </div>
                <div className="top_img_text">Knowledge</div>
                <ReactTooltip
                  id="indicator-highestliquidity-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="right"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {one}
                </ReactTooltip>
              </div>
              <div
                className="col-sm-2 top-img ttip getstarted2"
                data-target="getstarted2"
                data-hasqtip="116"
                data-tip
                data-for="indicator-nearexpiry-tooltip"
              >
                <div>
                  <img src=" /img/started/2.png" alt="" />
                </div>
                <div className="top_img_text">Brokerage account</div>
                <ReactTooltip
                  id="indicator-nearexpiry-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="right"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {two}
                </ReactTooltip>
              </div>
              <div
                className="col-sm-2 top-img ttip getstarted3"
                data-target="getstarted3"
                data-hasqtip="122"
                data-tip
                data-for="indicator-responsive-tooltip"
              >
                <div>
                  <img src=" /img/started/3.png" alt="" />
                </div>
                <div className="top_img_text">SIP requirements</div>
                <ReactTooltip
                  id="indicator-responsive-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="right"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {three}
                </ReactTooltip>
              </div>
              <div
                className="col-sm-2 top-img ttip getstarted4"
                data-target="getstarted4"
                data-hasqtip="128"
                data-tip
                data-for="indicator-hotdw-tooltip"
              >
                <div>
                  <img src=" /img/started/4.png" alt="" />
                </div>
                <div className="top_img_text">Selecting a warrant</div>
                <ReactTooltip
                  id="indicator-hotdw-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="right"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {four}
                </ReactTooltip>
              </div>
              <div
                className="col-sm-2 top-img ttip getstarted5"
                data-target="getstarted5"
                data-hasqtip="134"
                data-tip
                data-for="five-tooltip"
              >
                <div>
                  <img src=" /img/started/5.png" alt="" />
                </div>
                <div className="top_img_text">How to buy or sell warrants </div>
                <ReactTooltip
                  id="five-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="right"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {five}
                </ReactTooltip>
              </div>
              <div
                className="col-sm-2 top-img ttip getstarted6"
                data-target="getstarted6"
                data-hasqtip="140"
                data-tip
                data-for="six-tooltip"
              >
                <div>
                  <img src=" /img/started/6.png" alt="" />
                </div>
                <div className="top_img_text">Constant support</div>
                <ReactTooltip
                  id="six-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="right"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {six}
                </ReactTooltip>
              </div>
            </div>
            <div className="row visible-xs">
              <div className="col-xs-4 top-pointer">
                <div
                  className="ttip getstarted1"
                  data-target="getstarted1"
                  data-hasqtip="75"
                >
                  1
                </div>
                <div className="top_line"></div>
              </div>
              <div className="col-xs-4 top-pointer">
                <div
                  className="ttip getstarted2"
                  data-target="getstarted2"
                  data-hasqtip="81"
                >
                  2
                </div>
                <div className="top_line"></div>
              </div>
              <div className="col-xs-4 top-pointer">
                <div
                  className="ttip getstarted3"
                  data-target="getstarted3"
                  data-hasqtip="87"
                >
                  3
                </div>
                <div className="top_line"></div>
              </div>
            </div>
            <div className="row visible-xs">
              <div
                className="col-xs-4 top-img ttip getstarted1"
                data-target="getstarted1"
                data-hasqtip="111"
                data-tip
                data-for="indicator-highestliquidity-tooltip"
              >
                <div>
                  <img src=" /img/started/1.png" alt="" />
                </div>
                <div className="top_img_text">Knowledge</div>
                <ReactTooltip
                  id="indicator-highestliquidity-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="bottom"
                  offset={{ top: 0, left: -110 }}
                  arrowColor="#fff"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {one}
                </ReactTooltip>
              </div>
              <div
                className="col-xs-4 top-img ttip getstarted2"
                data-target="getstarted2"
                data-hasqtip="117"
                data-tip
                data-for="indicator-nearexpiry-tooltip"
              >
                <div>
                  <img src=" /img/started/2.png" alt="" />
                </div>
                <div className="top_img_text">Brokerage account</div>
                <ReactTooltip
                  id="indicator-nearexpiry-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="bottom"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {two}
                </ReactTooltip>
              </div>
              <div
                className="col-xs-4 top-img ttip getstarted3"
                data-target="getstarted3"
                data-hasqtip="123"
                data-tip
                data-for="indicator-responsive-tooltip"
              >
                <div>
                  <img src=" /img/started/3.png" alt="" />
                </div>
                <div className="top_img_text">SIP requirements</div>
                <ReactTooltip
                  id="indicator-responsive-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="bottom"
                  offset={{ top: 0, left: 110 }}
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {three}
                </ReactTooltip>
              </div>
            </div>
            <div className="row visible-xs">
              <div className="col-xs-4 top-pointer">
                <div
                  className="ttip getstarted4"
                  data-target="getstarted4"
                  data-hasqtip="93"
                >
                  4
                </div>
                <div className="top_line"></div>
              </div>
              <div className="col-xs-4 top-pointer">
                <div
                  className="ttip getstarted5"
                  data-target="getstarted5"
                  data-hasqtip="99"
                >
                  5
                </div>
                <div className="top_line"></div>
              </div>
              <div className="col-xs-4 top-pointer">
                <div
                  className="ttip getstarted6"
                  data-target="getstarted6"
                  data-hasqtip="105"
                >
                  6
                </div>
              </div>
            </div>
            <div className="row visible-xs">
              <div
                className="col-xs-4 top-img ttip getstarted4"
                data-target="getstarted4"
                data-hasqtip="129"
                data-tip
                data-for="indicator-hotdw-tooltip"
              >
                <div>
                  <img src=" /img/started/4.png" alt="" />
                </div>
                <div className="top_img_text">Selecting a warrant</div>
                <ReactTooltip
                  id="indicator-hotdw-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  offset={{ top: 0, left: -110 }}
                  place="bottom"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {four}
                </ReactTooltip>
              </div>
              <div
                className="col-xs-4 top-img ttip getstarted5"
                data-target="getstarted5"
                data-hasqtip="135"
                data-tip
                data-for="five-tooltip"
              >
                <div>
                  <img src=" /img/started/5.png" alt="" />
                </div>
                <div className="top_img_text">How to buy or sell warrants </div>
                <ReactTooltip
                  id="five-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="bottom"
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {five}
                </ReactTooltip>
              </div>
              <div
                className="col-xs-4 top-img ttip getstarted6"
                data-target="getstarted6"
                data-hasqtip="141"
                data-tip
                data-for="six-tooltip"
              >
                <div>
                  <img src=" /img/started/6.png" alt="" />
                </div>
                <div className="top_img_text">Constant support</div>
                <ReactTooltip
                  id="six-tooltip"
                  className="warran-tooltip"
                  delayHide={100}
                  place="bottom"
                  offset={{ top: 0, left: 110 }}
                  type="warning"
                  effect="solid"
                  backgroundColor="#C7EAF8"
                >
                  {six}
                </ReactTooltip>
              </div>
            </div>
          </div>
          <div className="section-btn-wapper">
            <a
              href="/education/getstarted"
              type="button"
              target="_blank"
              className="btn btn-primary"
            >
              View more
            </a>
          </div>
        </div>
        <div
          className="overlay"
          id="ppboxEligibility"
          style={{ display: this.state.isShow ? "block" : "none" }}
        >
          <div className="overlay-inner">
            <div style={{ position: "relative" }}>
              <div className="popupbox-content">
                <div className="ppc">
                  <div className="signup-layer">
                    <div className="page-header">
                      <h3>You are deemed eligible if you:</h3>
                    </div>
                    <p className="small"></p>
                    <ul style={{ paddingLeft: "20px" }}>
                      <li>
                        have executed 6 trades in the past 3 years in warrants
                        or any listed SIPs such as ETFs, structured notes,
                        futures or certificates; or
                      </li>
                      <li>
                        possess educational/professional finance-related
                        qualifications; or
                      </li>
                      <li>
                        possess a minimum of 3 consecutive years of
                        <span
                          style={{ color: "#00A1E0", fontWeight: "bold" }}
                          className="ttip"
                          data-tip
                          data-for="tm-tooltip"
                        >
                          relevant working experience
                        </span>{" "}
                        <ReactTooltip
                          id="tm-tooltip"
                          className="warran-tooltip"
                          delayHide={100}
                          place="bottom"
                          type="warning"
                          effect="solid"
                          backgroundColor="#FECC0A"
                        >
                          {tm}
                        </ReactTooltip>
                        in the past 10 years.
                      </li>
                    </ul>
                    <p></p>
                    <div style={{ textAlign: "center" }}>
                      <a
                        href=""
                        className="btn btn-primary cancel"
                        onClick={() => this.changeShow(false)}
                      >
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 弹出框 */}
        <div
          id="disclaimer-ppboxHTML"
          className="disclaimer-overlay"
          style={{ display: this.state.linkShow ? "block" : "none" }}
        >
          <div className="overlay-inner">
            <div className="position:relative">
              <div className="popupbox-content">
                <div>
                  <div className="disclaimer-layer">
                    <div>
                      <h3
                        style={{
                          borderBottom: "1px solid #FECC0A",
                          paddingBottom: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        Important notice
                      </h3>
                      <p className="small">
                        You are now entering a third party website which is
                        neither operated nor sponsored by Macquarie. Macquarie
                        is not responsible for the content, cookie collection,
                        personal data collection, trading and services provided
                        on such website. You should review the terms and
                        conditions of third party websites and contact the
                        operators of such websites if you have any queries.
                        <br />
                        <br />
                        Investment in warrants involves risk. These links to
                        third party websites do not constitute an offer, a
                        solicitation of an offer, or any advice or
                        recommendation to participate in any transaction. You
                        should consult your own professional advisors to ensure
                        that any decision made is suitable with regards to your
                        circumstances and financial position. Please note that
                        the list of brokers is not exhaustive and the inclusion
                        of the brokers on the list does not imply that the
                        brokers or their services are endorsed by Macquarie.
                      </p>
                      <div className="" style={{ textAlign: "center" }}>
                        <input
                          className="btn btn-primary disclaimer-continue"
                          value="Continue"
                          type="button"
                          onClick={() => {
                            window.open(
                              this.state.linkArr[this.state.serialNumber]
                            );
                            this.SwitchLinkShow(false);
                          }}
                        />
                        <input
                          className="btn btn-primary disclaimer-cancel"
                          value="Cancel"
                          type="button"
                          onClick={() => {
                            this.SwitchLinkShow(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class Brochure extends Component {
  render() {
    return (
      <div id="qlink-dwvideo" className="holder-qlink-dwvideo section">
        <div className="page-header">
          <h2 className="small">Brochure</h2>
        </div>
        {/* <p>Learn all about warrants here.</p> */}
        <div>
          <img
            src=" /img/home/Infographic-jpeg-vers.jpg"
            style={{ width: "100%" }}
            alt=""
          />
        </div>
        <div className="section-btn-wapper">
          <a
            target="_blank"
            href="/education/brochure"
            type="button"
            className="btn btn-primary"
          >
            View more
          </a>
        </div>
        <div className="section" style={{ padding: '0px', marginTop: '0px' }}>
          <h3 style={{ paddingTop: "20px", fontWeight: 'bold' }}>
            How to select a warrant to trade?
          </h3>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="" style={{ padding: '0px' }}>
              <div className="section1" style={{ display: 'flex', flexDirection: 'column', borderTop: '0', padding: '0px 0px 20px 0px' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '22%', background: '#F0F0F0', paddingTop: '10px' }}>
                    <div style={{
                      background: '#29949E',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50px',
                      textAlign: 'center',
                      lineHeight: '50px',
                      fontSize: '28px',
                      margin: '0 auto',
                    }}>1</div>
                  </div>
                  <div style={{ background: '#F0F0F0', flex: '1' }}>
                    <p style={{
                      lineHeight: '40px',
                      fontSize: '17px',
                      fontWeight: 'bold',
                      paddingTop: '10px'
                    }}>View on the underlying</p>
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', background: '#F0F0F0', justifyContent: 'space-around' }}>
                  <img src="/img/brochure/HSWT_1_Pricetag.png" alt="" style={{ width: '55px', height: "55px" }} />
                  <div style={{ width: '65%' }}>
                    <div style={{ fontSize: '13px' }}>The price at which to</div>
                    <div style={{ fontSize: '13px' }}><b style={{ fontSize: '20px' }}>Enter  </b>and  <b style={{ fontSize: '20px' }}>Exit</b></div>
                    <div style={{ fontSize: '13px' }}>from a warrant is based on</div>
                    <div style={{ fontSize: '13px' }}><b style={{ fontSize: '20px' }}>Entry  </b>and  <b style={{ fontSize: '20px' }}>Exit</b></div>
                    <div style={{ fontSize: '13px' }}>levels in the underlying.</div>
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '20px' }} className="section2">
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '22%', background: '#F0F0F0', paddingTop: '10px' }}>
                    <div style={{
                      background: '#29949E',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50px',
                      textAlign: 'center',
                      lineHeight: '50px',
                      fontSize: '28px',
                      margin: '0 auto'

                    }}>2</div>
                  </div>
                  <div style={{ background: '#F0F0F0', flex: '1' }}>
                    <p className="text_p" style={{
                      lineHeight: '40px',
                      fontSize: '16px',
                      fontWeight: 'bold'
                      , paddingTop: '10px'
                    }}>Decide on your holding period</p>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ paddingRight: '20px', background: '#F0F0F0', width: '100%' }}>
                    <p style={{ fontSize: '10px', paddingLeft: '55px' }}>As with all leveraged products, warrants are not meant asa long-term investment.</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', background: '#F0F0F0', alignItems: 'center', width: '80%', background: '#29949E', marginLeft: '10%', padding: '5px' }}>
                      <img src="/img/brochure/HSWT_2_Hourglass.png" alt="" style={{ width: '55px', height: "55px" }} />
                      <div style={{ fontSize: '10px', color: '#fff', paddingLeft: '5px' }}>
                        The holding costs for warrants is known as <b>time decay</b>, where a warrant’s price erodes with time.
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div className="padding_0" style={{ paddingRight: '8px', background: '#F0F0F0' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', background: '#F0F0F0', marginLeft: '10%', padding: '5px' }}>

                      <div style={{ fontSize: '10px', width: '55%' }}>
                        Use Macquarie’s <b style={{ color: '#29949e' }}>Warrant Selector</b> tool to help you <b style={{ color: '#29949e' }}>see the maximum holding period you should hold onto the warrant</b> based on your target underlying entry and exit levels,  <b style={{ color: '#29949e' }}>before</b> the warrant <b style={{ color: '#29949e' }}>gains are completely eroded by time decay</b>
                      </div>
                      <Link to="/tools/selector" className='WarrantLink' style={{
                        fontSize: '11px', background: '#004559', color: '#fff', height: '23px', lineHeight: '21px',
                        borderRadius: '5px',
                        padding: '5px',
                        marginTop: '50px',
                        padding: '2px 5px 2px 5px',
                        textAlign: 'center'
                      }}>Warrant selector</Link>
                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <div style={{ background: '#004559', width: '100%' }}>
                    <div style={{ display: 'flex', padding: '5px', paddingLeft: '20px', alignItems: 'center' }}>
                      <img src="/img/brochure/HSWT_2_Cross.png" alt="" style={{ width: '30px', height: "30px" }} />
                      <div style={{ fontSize: '10px', color: '#fff', width: '70%', marginLeft: '5px' }}>
                        Generally, investors should avoid warrants expiring in the same month
                      </div>
                    </div>
                    <div style={{ display: 'flex', padding: '5px', paddingLeft: '20px', alignItems: 'center' }}>
                      <img src="/img/brochure/HSWT_2_Tick.png" alt="" style={{ width: '30px', height: "30px" }} />
                      <div style={{ fontSize: '10px', color: '#fff', width: '70%', marginLeft: '5px' }}>
                        Choose an expiry longer than your intended holding period.
                      </div>
                    </div>
                  </div>
                </div>


                <div style={{ display: 'flex' }}>
                  <div className="padding_0" style={{ paddingRight: '8px', background: '#F0F0F0', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', background: '#F0F0F0', marginLeft: '10%', padding: '5px' }}>

                      <div style={{ fontSize: '10px', width: '55%' }}>
                        Macquarie’s Warrant Calculator allows you to estimate the time decay on your intended holding period.
                      </div>
                      <Link to="/tools/warrantcalculator" className="WarrantLink" style={{
                        fontSize: '10px', background: '#004559', color: '#fff', height: '23px', lineHeight: '21px',
                        borderRadius: '5px',
                        padding: '5px',
                        marginTop: '10px',
                        padding: '2px 5px 2px 5px',
                        textAlign: 'center'
                      }}>Warrant calculator</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="" style={{ padding: '0px' }}>
              <div className="section3" style={{ display: 'flex', borderLeft: '0', padding: '0px 0px 20px 0px' }}>
                <div style={{ width: '25%', background: '#F0F0F0', paddingTop: '10px', paddingLeft: '10px' }}>
                  <div style={{
                    background: '#29949E',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50px',
                    textAlign: 'center',
                    lineHeight: '50px',
                    fontSize: '28px',
                    margin: '0 auto',
                  }}>3</div>
                </div>
                <div style={{ background: '#F0F0F0' }}>
                  <p className="text_p" style={{
                    lineHeight: '40px',
                    fontSize: '17px',
                    fontWeight: 'bold'
                    , paddingTop: '10px', paddingLeft: '10px'
                  }}>Select the amount of leverage</p>
                  <p style={{ fontSize: '11px' }}>For example, a warrant with <b>8X effective gearing</b></p>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <div className="font_div" style={{ fontSize: '40px' }}><b>1%  </b>=  <b>8%</b></div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '11px', }}>move in  <b>underlying </b></div>
                        &nbsp;
                        <div style={{ fontSize: '11px', }}> move in  <b>warrant!</b></div>
                      </div>
                    </div>
                    <img src="/img/brochure/HSWT_3_Gearing.png" alt="" style={{ width: '65px', height: "55px", marginRight: '9px' }} />
                  </div>
                  <p style={{ fontSize: '11px', marginTop: '5px' }}>
                    <b style={{ fontSize: '15px' }}>8X more</b> than the underlying percentage move However, the <b style={{ color: '#29949E' }}>higher the effective gearing </b>level in the warrant, the <b style={{ color: '#29949E' }}>riskier the warrant,</b> as gearing works both ways.
                  </p>
                  {/* <div style={{ marginTop: '10px', fontSize: '11px' }}><b style={{ fontSize: '15px' }}>8X more</b> than the underlying percentage move</div>
                <div style={{ fontSize: '11px', marginTop: '2px' }}>However, the <b style={{ color: '#29949E' }}>higher the effective gearing </b>level in the</div>
                <div style={{ fontSize: '11px', marginTop: '2px' }}>warrant, the <b style={{ color: '#29949E' }}>riskier the warrant,</b> as gearing works both way</div> */}
                </div>
              </div>


              <div style={{ background: '#F0F0F0', }} className="section4">
                <div style={{ display: 'flex', padding: '0px 0px 5px 0px' }}>
                  <div style={{ width: '20%', paddingTop: '10px' }}>
                    <div style={{
                      background: '#29949E',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50px',
                      textAlign: 'center',
                      lineHeight: '50px',
                      fontSize: '28px',
                      margin: '0 auto'
                    }}>4</div>
                  </div>
                  <div>
                    <p className="text_p margin_10" style={{
                      lineHeight: '40px',
                      fontSize: '17px',
                      fontWeight: 'bold'
                      , paddingTop: '10px'
                    }}>Avoid warrants on wide spreads</p>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ paddingRight: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '5%', padding: '5px' }}>

                      <div style={{ fontSize: '10px', width: '60%' }}>
                        Investors can <b style={{ color: ' #29949e' }}>see warrants</b> that are trading <b style={{ color: ' #29949e' }}>on tight spreads</b> on Macquarie’s Live Matrix.
                      </div>
                      <Link to="/tools/livematrix" style={{
                        fontSize: '10px', background: '#004559', color: '#fff', height: '23px', lineHeight: '14px',
                        borderRadius: '5px',
                        padding: '5px',
                        marginTop: '10px',
                        padding: '5px 10px 5px 10px'
                      }}>Live Matrix</Link>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', marginLeft: '5%', padding: '5px', background: 'rgb(41, 148, 158)', color: '#fff', width: '60%' }}>
                  <img src="/img/brochure/HSWT_4_Information.png" alt="" style={{ width: '30px', height: "30px" }} />
                  <div style={{
                    fontSize: '10px', flex: '1', lineHeight: '30px', fontWeight: 'bold',
                    paddingLeft: '5px'
                  }}>
                    What is a tight spread?
                  </div>
                </div>
                <div style={{ display: 'flex', padding: '5px', textAlign: 'center', alignItems: 'center', justifyContent: 'space-around' }}>
                  <div>
                    <div style={{ fontSize: '35px', color: '#29949E' }} className="fontDiv">$0.001</div>
                    <div style={{ fontWeight: 'bold' }}>spread</div>
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    Between
                    <br />
                    the <b>bid</b> and <b>
                      <br />
                      offer</b> price
                  </div>
                  <div>
                    <div style={{ fontSize: '11px' }}>For warrants priced</div>
                    <div style={{ fontSize: '35px', color: '#29949E' }} className="fontDiv">&lt;$0.20</div>
                  </div>
                </div>
                <hr style={{
                  height: '2px', background: '#2E96A0', width: '96%', marginTop: '6px',
                  marginBottom: '6px'
                }} />
                <div style={{ display: 'flex', padding: '5px', textAlign: 'center', alignItems: 'center', justifyContent: 'space-around' }}>
                  <div>
                    <div style={{ fontSize: '35px', color: '#29949E' }} className="fontDiv">$0.005</div>
                    <div style={{ fontWeight: 'bold' }}>spread</div>
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    Between
                    <br />
                    the <b>bid</b> and <b>
                      <br />
                      offer</b> price
                  </div>
                  <div>
                    <div style={{ fontSize: '11px' }}>For warrants priced</div>
                    <div style={{ fontSize: '35px', color: '#29949E' }} className="fontDiv">$0.20</div>
                    <div style={{ fontSize: '11px' }}>and above</div>
                  </div>
                </div>
                <div style={{
                  width: '100%', height: '15px',
                }}></div>
                <div style={{
                  width: '100%', height: '15px',
                  background: '#fff'
                }}></div>
              </div>
            </div>
          </div>
          <div className="row"  >
            <div className="col-xs-12" style={{ padding: '0px' }}>
              <div style={{
                background: '#004559', padding: '5px',
                display: 'block',
                overflow: 'hidden'
              }} className="section5_box">
                <div style={{ width: '15%', paddingTop: '10px', float: 'left' }} className='section5_icon'>
                  <div style={{
                    background: '#29949E',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50px',
                    textAlign: 'center',
                    lineHeight: '50px',
                    fontSize: '28px',
                    margin: '0 auto',
                  }}>5</div>
                  {/* <p style={{
                  lineHeight: '40px',
                  fontSize: '17px',
                  fontWeight: 'bold',
                  paddingTop: '10px',
                  color: '#fff'
                }}>Quickest selection process</p> */}
                </div>
                <div style={{ width: '75%', color: '#fff', margin: '0 auto' }} className="section5_text">
                  <p className="section5_text_p" style={{
                    lineHeight: '22px',
                    fontSize: '17px',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                    color: '#fff',
                    marginLeft: '25px'
                  }}>Quickest selection process</p>
                  <p style={{ fontSize: '11px', color: '#fff' }}>For the quickest selection process, visit Macquarie's <b>Warrant search</b> page.</p>
                  <p style={{ fontSize: '12px', color: '#fff' }}><b>Trending warrants</b></p>

                  <div style={{ fontSize: '13px', display: 'flex', alignItems: 'center' }}>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="border_width" style={{
                        background: '#29949E',
                        height: '40px',
                        width: '55px',
                        borderRadius: '50%',
                        textAlign: 'center',
                        lineHeight: '40px',
                        fontSize: '28px',
                        margin: '0 auto',
                      }}>
                        <img src="/img/brochure/HSWT_5_Liquidity.png" alt="" style={{ width: '20px', height: "24px" }} />
                      </div>
                      <span style={{ padding: '5px' }}>High liquidity</span>
                    </div>


                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                      <div className="border_width" style={{
                        background: '#29949E',
                        height: '40px',
                        width: '55px',
                        borderRadius: '50%',
                        textAlign: 'center',
                        lineHeight: '40px',
                        fontSize: '28px',
                        margin: '0 auto',
                      }}>
                        <img src="/img/brochure/HSWT_5_TightSpread.png" alt="" style={{ width: '20px', height: "24px" }} />
                      </div>
                      <span style={{ padding: '5px' }}>Tight spread</span>
                    </div>

                  </div>
                </div>
                <Link className="section5_link" to="/tools/warrantsearch" style={{
                  fontSize: '10px', background: '#A3D4D9', color: '#004559', height: '23px', lineHeight: '16px',
                  borderRadius: '5px',
                  padding: '5px',
                  padding: '5px 10px 5px 10px',
                  width: '34%',
                  textAlign: 'center',
                  float: 'left',
                  margin: '30px auto 0 5px'
                }}>Warrant search</Link>
                <div className="section5_icon1" style={{
                  background: '#F0F0F0', display: 'flex', background: '#29949E', width: '57%', height: '58px',
                  alignItems: 'center',
                  margin: '15px 0 0 15px',
                  float: 'left'
                }}>
                  <img src="/img/brochure/HSWT_5_LookOut.png" alt="" style={{ width: '55px', height: "55px", padding: '5px' }} />
                  <div className="brochure_p" style={{ fontSize: '10px', lineHeight: '12px', color: '#085566', paddingLeft: '5px', wordBreak: 'break-word' }}>
                    <span style={{ fontWeight: 'bold', color: '#004559' }}>Look out </span>
                    <span style={{ color: '#fff' }}>for the warrants with the</span>
                    <span style={{ fontWeight: 'bold', color: '#004559' }}> fire icon </span>
                    <span style={{ color: '#fff' }}>- indicating trending warrants</span>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div >
      </div>
    );
  }
}
class InvestorAcademy extends Component {
  render() {
    return (
      <div id="qlink-dwvideo" className="holder-qlink-dwvideo section">
        <div className="page-header">
          <h2 className="small">Investor academy</h2>
        </div>
        <p>Learn all about warrants here.</p>
        <div>
          <img
            src=" /img/home/InvestorAcademy.png"
            style={{ width: "100%" }}
            alt=""
          />
        </div>
        <div className="section-btn-wapper">
          <a
            target="_blank"
            href="/InvestorAcademy"
            type="button"
            className="btn btn-primary"
          >
            View more
          </a>
        </div>
      </div>
    );
  }
}

class EducationVideo extends Component {
  render() {
    return (
      <div id="qlink-dwvideo" style={{ display: 'flex', flexDirection: 'column' }} className="holder-qlink-dwvideo section">
        <div className="page-header">
          <h2 className="small">Macquarie's School of Warrants</h2>
        </div>
        <p>
          Learn about structured warrants with these bite-sized animated videos!
          In these videos, we will provide an introduction on structured
          warrants and the things to know when trading or investing in warrants.
        </p>
        <div className="videoplayer" style={{ flex: 1 }}>
          <div className="videoplayer-container" style={{ height: '100%' }}>
            <iframe
              title="education video"
              frameBorder="0"
              allowFullScreen=""
              // className="dwvideo-iframe"
              src="https://www.youtube.com/embed/videoseries?list=PLOkzCdnS95xQ11S019PR9SqW6FokLfiz0&amp;autoplay=0&amp;index=5&amp;rel=0&amp;wmode=opaque"
            ></iframe>
          </div>
        </div>
        <div className="section-btn-wapper">
          <a
            href="/education/warrantvideos"
            type="button"
            className="btn btn-primary"
            target="_blank"
          >
            View more
          </a>
        </div>
      </div>
    );
  }
}
class ShortFormVideos extends Component {
  constructor() {
    super();
    this.state = {
      src: ''
    };
  }
  componentDidMount() {
    const url = MQSGUtil.getAPIBasePath() + "/ShortsVideoBannerWarrantsJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState(
            {
              src: result
            })
        },
        (error) => {
        }
      );
  }
  renderImg(data) {
    if (data) {
      return <img src={MQSGUtil.getShortsVideoBannerAPIImgPath() + data.id} style={{ height: "100%", width: "100%" }}></img>
    }
  }
  getIframe(e, playlistid) {
    var dom = document.getElementById(playlistid)
    var imgDom = dom.getElementsByTagName('img')
    imgDom[0].style.display = 'none';
    var iframe = document.createElement("iframe");
    iframe.height = '100%';
    iframe.width = '100%';
    iframe.src = `https://www.youtube.com/embed/${playlistid}?&index=0&rel=0&wmode=opaque&autoplay=1`
    dom.appendChild(iframe)
  }
  render() {
    return (
      <div id="qlink-dwvideo" style={{ display: 'flex', flexDirection: 'column' }} className="holder-qlink-dwvideo section">
        <div className="page-header">
          <h2 className="small">Macquarie Warrants 1-minute videos</h2>
        </div>
        <p>
          Learn more about structured warrants by watching our <br></br>1-minute videos below.
        </p>
        <div className="row shortVideoBox" style={{ height: '550px' }}>
          <div className="" style={{ height: '100%', width: '306px', margin: '0 auto' }}>
            <div className="videoplayer-container" style={{ height: '100%' }} onClick={(e) => { this.getIframe(e, this.state.src[0].playlist) }} id={this.state.src[0] && this.state.src[0].playlist}>
              {this.renderImg(this.state.src[0])}
            </div>
          </div>
          {/* <div className="col-lg-6 visible-lg" style={{ height: '100%' }}>
            <div className="videoplayer-container" style={{ height: '100%' }} onClick={(e) => { this.getIframe(e, this.state.src[1].playlist) }} id={this.state.src[1] && this.state.src[1].playlist}>
              {this.renderImg(this.state.src[1])}
            </div>
          </div> */}
        </div>
        <div className="section-btn-wapper">
          <a
            href="/education/ShortFormVideos"
            type="button"
            className="btn btn-primary"
            target="_blank"
          >
            View more
          </a>
        </div>
      </div>
    );
  }
}

class MarketView extends Component {
  constructor() {
    super();
    this.state = {
      src: ''
    };
  }
  componentDidMount() {
    const url = MQSGUtil.getAPIBasePath() + "/VideoWarrantsJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          let src = ''
          let srcArray = []
          for (var i = 0; i < result.length; i++) {
            srcArray.push(result[i].videos[0])
          }
          srcArray.sort((a, b) => {
            return new Date(b.update_time) - new Date(a.update_time)
          })
          this.setState(
            {
              src: srcArray[0].playlist_id
            })
        },
        (error) => {
        }
      );
  }
  render() {
    const link = `https://www.youtube.com/embed/${this.state.src}?autoplay=0&index=0&rel=0&wmode=opaque`
    return (
      <div id="qlink-dwvideo" style={{ display: 'flex', flexDirection: 'column' }} className="holder-qlink-dwvideo section">
        <div className="page-header">
          <h2 className="small">Market View / Commentary</h2>
        </div>
        <p>
          In these series, hear the views of Singapore's investment strategists
          first-hand, and see how the market has moved in the month! Don't miss
          out as we also cover the upcoming financial events and what to expect
          in the coming weeks.
        </p>
        <div className="videoplayer" style={{ flex: 1 }}>
          <div className="videoplayer-container" style={{ height: '100%' }}>
            <iframe
              title="Market View"
              frameBorder="0"
              allowFullScreen=""
              // className="dwvideo-iframe"
              // src="https://www.youtube.com/embed/zOJbJrOAbO0?autoplay=0&index=0&rel=0&wmode=opaque"
              src={link}
            ></iframe>
          </div>
        </div>
        <div className="section-btn-wapper">
          <a
            href="/education/warrantvideos"
            type="button"
            className="btn btn-primary"
            target="_blank"
          >
            View more
          </a>
        </div>
      </div >
    );
  }
}

class SuccessSpeak extends Component {
  render() {
    return (
      <div id="qlink-dwvideo" className="holder-qlink-dwvideo section">
        <div className="page-header">
          <h2 className="small">Client Testimonials</h2>
        </div>
        <a href="/SuccessSpeaks">
          <img
            src=" /img/education/video_successspeaks.jpg"
            style={{ width: "100%" }}
            alt=""
          />
        </a>
        <div className="section-btn-wapper">
          <a
            href="/SuccessSpeaks"
            type="button"
            className="btn btn-primary"
            target="_blank"
          >
            View more
          </a>
        </div>
      </div>
    );
  }
}
class FAQ extends Component {
  render() {
    return (
      <div className="holder-qlink-faq">
        <div className="section " id="qlink-faq">
          <div className="page-header">
            <h2 className="small">FAQ</h2>
          </div>
          <p>
            Do you have a question about Structured Warrants? The answer may be
            here.
          </p>
          <div id="question-header" className="question-header color-primary">
            Questions you may have
          </div>
          <div className="question-list">
            <div className="faq1">
              <a href="/education/faq#section1" target="_blank">
                <span className="faq-question">
                  What is a structured warrant?
                </span>
                &nbsp;
                <img
                  src="/img/bullet/qlink-achor.png"
                  alt=""
                  style={{ display: "inline-block" }}
                />
              </a>
            </div>

            <div className="faq2">
              <a href="/education/faq#section2" target="_blank">
                <span className="faq-question">
                  Are structured warrants the same as company warrants?
                </span>
                &nbsp;
                <img
                  src="/img/bullet/qlink-achor.png"
                  alt=""
                  style={{ display: "inline-block" }}
                />
              </a>
            </div>

            <div className="faq3">
              <a href="/education/faq#section11" target="_blank">
                <span className="faq-question">
                  What happens if I hold a warrant until expiry?
                </span>
                &nbsp;
                <img
                  src="/img/bullet/qlink-achor.png"
                  alt=""
                  style={{ display: "inline-block" }}
                />
              </a>
            </div>

            <div className="faq4">
              <a
                href="/marketnews/highlight/todayhighlight/6829"
                target="_blank"
              >
                <span className="faq-question">Can I short sell a warrant</span>
                &nbsp;
                <img
                  src="/img/bullet/qlink-achor.png"
                  alt=""
                  style={{ display: "inline-block" }}
                />
              </a>
            </div>

            <div className="faq5">
              <a
                href="/marketnews/highlight/todayhighlight/6818"
                target="_blank"
              >
                <span className="faq-question">
                  Why is it risky to trade warrants approaching expiry
                </span>
                &nbsp;
                <img
                  src="/img/bullet/qlink-achor.png"
                  alt=""
                  style={{ display: "inline-block" }}
                />
              </a>
            </div>

            <div className="faq6">
              <a
                href="/marketnews/highlight/todayhighlight/6705"
                target="_blank"
              >
                <span className="faq-question">
                  Why are warrants on widespreads
                </span>
                &nbsp;
                <img
                  src="/img/bullet/qlink-achor.png"
                  alt=""
                  style={{ display: "inline-block" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
